import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateCredential } from "../../../actions/credentialActions";
import { withRouter } from "react-router-dom";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import { toast } from "react-toastify";
import $ from "jquery";

import "react-toastify/dist/ReactToastify.css";

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

class CredentialsUpdateModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.record.id,
      exchange: "",
      pair_ids: "",
      pairs: "",
      pairs1: "",
      api_key: "",
      secrate_key: "",
      errors: {},
      pairList: [],
      firstCoin: "",
      secondCoin: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    console.log("nextProps.record", nextProps.record);
    if (nextProps.record) {
      this.setState({
        id: nextProps.record.id,
        pair_ids: nextProps.record.pair_ids,
        pairs: nextProps.record.pairs,
        exchange: nextProps.record.exchange,
        api_key: nextProps.record.api_key,
        secrate_key: nextProps.record.secrate_key,
        firstCoin: nextProps.record.name1,
        secondCoin: nextProps.record.name2,
      });

      if (nextProps.pairList) {
        let pairLists = [];
        nextProps.pairList.forEach((e) => {
          pairLists.push({
            label: e.name1 + "/" + e.name2,
            value: e.id,
          });
        });
        this.setState({
          pairList: pairLists,
        });
      }
    }
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
    if (
      nextProps.credentialdata !== undefined &&
      nextProps.credentialdata.credential !== undefined &&
      nextProps.credentialdata.credential.data !== undefined &&
      nextProps.credentialdata.credential.data.message !== undefined
    ) {
      this.setState({ errors: {} });
    }
  }

  onChange = (e) => {
    this.setState({ errors: {} });
    if (e.target.name === "exchange") {
      this.setState({ exchange: e.target.value });
    }
    if (e.target.name === "api_key") {
      this.setState({ api_key: e.target.value });
    }
    if (e.target.name === "secrate_key") {
      this.setState({ secrate_key: e.target.value });
    }
  };

  handleChangeSelect = (e) => {
    console.log("dsadsa", e);
    this.setState({
      pairs1: e,
    });
  };

  onCredentialUpdate = (e) => {
    e.preventDefault();
    const newCredential = {
      id: this.state.id,
      pair_ids: this.state.pairs1,
      exchange: this.state.exchange,
      api_key: this.state.api_key,
      secrate_key: this.state.secrate_key,
      firstCoin: this.state.firstCoin,
      secondCoin: this.state.secondCoin,
    };
    console.log("newCredential", newCredential);
    this.props.updateCredential(newCredential);
  };

  render() {
    const { errors } = this.state;
    return (
      <div>
        <div className="modal fade" id="update-user-modal">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Update Exchange Credential</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <form
                  noValidate
                  onSubmit={this.onCredentialUpdate}
                  id="update-user"
                >
                  <input
                    onChange={this.onChange}
                    value={this.state.id}
                    id="user-update-id"
                    type="text"
                    className="d-none"
                  />
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="box1">Exchange</label>
                    </div>
                    <div className="col-md-9">
                      <select
                        className="form-control"
                        id="exchange"
                        name="exchange"
                        value={this.state.exchange}
                        error={errors.exchange}
                        onChange={this.onChange}
                        disabled
                      >
                        <option>Please select exchange</option>
                        <option value="binance">Binance</option>
                        {/* <option value="binance1">Binance1</option> */}
                      </select>
                      <span className="text-danger">{errors.exchange}</span>
                      <span className="text-danger">{errors.message}</span>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="box1">Pair</label>
                    </div>
                    <div className="col-md-9">
                      <ReactSelect
                        options={this.state.pairList}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{
                          Option,
                        }}
                        onChange={this.handleChangeSelect}
                        allowSelectAll={true}
                        id="pair_ids"
                        name="pair_ids"
                        value={
                          this.state.pairs1
                            ? this.state.pairs1
                            : this.state.pairs != ""
                            ? JSON.parse(this.state.pairs)
                            : ""
                        }
                        error={errors.pair_ids}
                      />
                      <span className="text-danger">{errors.pair_ids}</span>
                      <span className="text-danger">{errors.message}</span>
                    </div>
                  </div>

                  {/* <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="api_key">API Key</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.api_key}
                                                id="api_key"
                                                name="api_key"
                                                type="text"
                                                error={errors.api_key}
                                                className={classnames("form-control", {
                                                    invalid: errors.api_key
                                                })} />
                                            <span className="text-danger">{errors.api_key}</span>
                                        </div>
                                    </div> */}

                  {/* <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="secrate_key">Secrate Key</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.secrate_key}
                                                id="secrate_key"
                                                name="secrate_key"
                                                type="text"
                                                error={errors.secrate_key}
                                                className={classnames("form-control", {
                                                    invalid: errors.secrate_key
                                                })} />
                                            <span className="text-danger">{errors.secrate_key}</span>
                                        </div>
                                    </div> */}
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  form="update-user"
                  type="submit"
                  className="btn btn-primary"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CredentialsUpdateModal.propTypes = {
  updateCredential: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  credentialdata: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  credentialdata: state.credentialdata,
  errors: state.errors,
});

export default connect(mapStateToProps, { updateCredential })(
  withRouter(CredentialsUpdateModal)
);
