import axios from "axios";
import {
    GET_ERRORS,
    USER_ADD,
    USER_UPDATE
} from "./types";
import * as myConstList from "./baseUrl"

const baseUrl = myConstList.baseUrl;

export const addVideo = (CryptoData, history) => dispatch => {
    axios
        .post(baseUrl + "/api/video-add", CryptoData)
        .then(res =>
            dispatch({
                type: 'VIDEO_ADD',
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    );
};


export const updateVideo = (CryptoData) => dispatch => {
    axios
        .post(baseUrl + "/api/video-update", CryptoData)
        .then(res =>
            dispatch({
                type: 'VIDEO_UPDATE',
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    );
};
