const isEmpty = require("is-empty");
const initialState = {
    isAuthenticated: false,
    video: {},
    loading: false
};
export default function(state = initialState, action) {
    switch (action.type) {

        case 'VIDEO_ADD':
        return {
                isAuthenticated: !isEmpty(action.payload),
                video: action.payload
        };

        case 'VIDEO_UPDATE':
            return {
                isAuthenticated: !isEmpty(action.payload),
                video: action.payload,
            };
        case 'SET_CURRENT_VIDEO':
            return {
                ...state,
                isAuthenticated: !isEmpty(action.payload),
                video: action.payload
            };

        case 'VIDEO_LOADING':
            return {
                ...state,
                loading: true
            };
        default:
            return state;
    }
}