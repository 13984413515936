import axios from "axios";
import {
    GET_ERRORS,
    USER_ADD,
    USER_UPDATE
} from "./types";
import * as myConstList from "./baseUrl"

const baseUrl = myConstList.baseUrl;




export const updateHelp = (userData) => dispatch => {
    axios
        .post(baseUrl + "/api/help-data", userData)
        .then(res =>
            dispatch({
                type: 'HELP_UPDATE',
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    );
};
export const replyForAdmin = (userData) => dispatch => {
    axios
        .post(baseUrl + "/api/replyForAdmin-data", userData)
        .then(res =>
            dispatch({
                type: 'HELP_UPDATE',
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    );
};

