import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { Link } from "react-router-dom";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import UserAddModal from "../partials/UserAddModal";
import UserUpdateModal from "../partials/UserUpdateModal";
import { Modal, Button } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import Moment from 'moment';
import * as myConstList from "../../actions/baseUrl";

const baseUrl = myConstList.baseUrl
class InrRequest extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isDepositModelOpen: false,
            isRejectionModelOpen: false,

            tx_id: "",
            coin_amount : ""
        }
        this.columns = [
            {
                key: "_id",
                text: "Transaction_id",
                className: "transaction_id",
                align: "left",
                sortable: true,

                cell: record => {
                    return (
                        <span>
                            {record.tx_id}
                        </span>
                    );
                }
            },

            {
                key: "amount",
                text: "Amount",
                className: "amount",
                align: "left",
                sortable: true,
                cell: record => {
                    return (
                        <span>
                            {record.coin_amount}
                        </span>
                    );
                }
            },
            {
                key: "status",
                text: "Status",
                className: "status",
                align: "left",
                sortable: true
            },
            {
                key: "date",
                text: "Date",
                className: "date",
                align: "left",
                sortable: true,
                cell: record => {
                    return (
                        <Fragment>
                            {Moment(record.date).format('lll')}
                        </Fragment>
                    );
                }
            },
            {
                key: "action",
                text: "Action",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                    return (

                        <Fragment>
                            <p className="mb-0">
                                
                                {record.status == 'pending' ? ( <>
                                {console.log("record.id",record.id)}
                                    <span className="btn btn-primary btn-sm mr-2" onClick={() =>
                                        this.OpenDepositModel(record.id, record.coin_amount)} title="Pending" > <i className="fa fa-check"></i></span>
                                    {/* onClick={() => this.inrTransfer(record.id,record.coin_amount)} */}
                                    <span className="btn btn-primary btn-sm" onClick={() =>
                                        this.OpenRejectModel(record.id)} title="Reject"> <i className="fa fa-close"></i></span>
                                </>

                                ) : record.status == 'completed' ? (<span className="completed_btn">Approved<i className="fa fa-check"></i></span>) : ( <>
                                   <span className="btn btn-primary btn-sm mr-2" title="Reject" > <i className="fa fa-close"></i></span> </>
                                )}
                            </p>
                        </Fragment>

                    );
                }
            }
        ];

        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            filename: "Fund Request List",
            no_data_text: 'No user found!',
            button: {

                print: true,
                csv: true
            },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            records: []
        };

        this.state = {
            currentRecord: {
                id: '',
                name: '',
                email: '',
                password: '',
                password2: '',
            }
        };

        // this.getHistoryData = this.getHistoryData.bind(this);
    }

    handleChange = (event) => {
        let { name, value } = event.target;
        this.setState({ [name]: value, errMsg: "" });
      };

    OpenDepositModel = (getCoinID, amount) => {
        this.setState({
            isDepositModelOpen: true,
            
            coin_id: getCoinID,
            amount: amount
        });

    };

    OpenRejectModel = (getCoinID) => {
        this.setState({
            isRejectionModelOpen : true,
            coin_id: getCoinID,
           
        });

    };

    closeOpenDepositModal = () => {
        this.setState({ isDepositModelOpen: false });
    };

    closeRejectModal = () => {
        this.setState({ isRejectionModelOpen : false });
    };


    componentDidMount() {
        if (this.props.auth.user.fundRequest !== undefined && this.props.auth.user.fundRequest !== 1) {
            this.props.history.push("/dashboard");
        }
        this.getHistoryData();
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.auth.user.fundRequest !== undefined && nextProps.auth.user.fundRequest !== 1) {
            this.props.history.push("/dashboard");
        }
        this.getHistoryData()
        //  this.getStauts()
    }


    FundRejection = async () => {
        let data = {
            id: this.state.coin_id, reason: this.state.reason, type: "2"
        }
        let fundData = await axios.post(baseUrl + "/api/fund_request_rejection", data)
        this.getHistoryData();
        this.setState({ records: fundData.data ,isDepositModelOpen : false})

    }


    inrTransfer = async () => {
        //alert(this.state.coin_id)
        let data = {
            id: this.state.coin_id, amount: this.state.amount, type: "1", tx_id: this.state.tx_id
        }
        let fundData = await axios.post(baseUrl + "/api/InrStatus-update", data)
        this.getHistoryData();
        this.setState({ records: fundData.data ,isDepositModelOpen : false})

    }

    getStauts() {
        axios
            .post(baseUrl + "/api/findById",)
            .then(res => {
                this.setState({ records: res.data })
            })
            .catch()
    }


    getHistoryData() {
        axios
            .get(baseUrl + "/api/userWithdrawal-Histroy")
            .then(res => {
                this.setState({ records: res.data })
            })
            .catch()
    }

    editRecord(record) {
        this.setState({ currentRecord: record });
    }

    deleteRecord(record) {
        axios
            .post(baseUrl + "/api/transaction-delete", { _id: record._id })
            .then(res => {
                if (res.status === 200) {
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            })
            .catch();
        this.getHistoryData();
    }

    pageChange(pageData) {
      
    }
    render() {
       
        return (
            <div>


                <Modal
                    show={this.state.isDepositModelOpen}
                    onHide={this.closeOpenDepositModal}
                >
                    <Modal.Header closeButton>
                        <Modal.Title> Approve Fund Request</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="modal-body p-4">
                            <div className="row mt-4">
                                <div className="col-sm-12 mb-3">
                                    <label> Enter Transaction ID:</label>
                                    <input
                                        className="form-control fs12"
                                        type="text"
                                        onChange={this.handleChange}          
                                        name="tx_id"
                                        value={this.state.tx_id}
                                    />
                                    <span className="btn btn-primary btn-sm mt-3" onClick={() => this.inrTransfer()}> Send</span>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>



                <Modal
                    show={this.state.isRejectionModelOpen}
                    onHide={this.closeRejectModal}
                >
                    <Modal.Header closeButton>
                        <Modal.Title> Rejection Of Fund Request </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="modal-body p-4">
                            <div className="row mt-4">
                                <div className="col-sm-12 mb-3">
                                    <label> Reason of Rejection</label>
                                    <input
                                        className="form-control fs12"
                                        type="text"
                                        onChange={this.handleChange}          
                                        name="reason"
                                        value={this.state.reason}
                                    />
                                    <span className="btn btn-primary btn-sm mt-3" onClick={() => this.FundRejection()}> Send</span>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Navbar />


                <div className="d-flex" id="wrapper">
                    <Sidebar />
                    <UserAddModal />
                    <UserUpdateModal record={this.state.currentRecord} />
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <button className="btn btn-link mt-3" id="menu-toggle"><FontAwesomeIcon icon={faList} /></button>

                            <h3 className="mt-2 man_hadding mb-3">Fund Request List</h3>
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                onPageChange={this.pageChange.bind(this)}
                            />
                        </div>
                    </div>
                    <ToastContainer />
                </div>
            </div>
        );
    }

}

InrRequest.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});

export default connect(
    mapStateToProps
)(InrRequest);
