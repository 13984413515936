import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { addStack } from "../../actions/stackingActions";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import $ from "jquery";

class StackingAdd extends React.Component {
  constructor(props) {
    super();
    this.state = {
      lockedAmount: "",
      estAPR: "",
      duration: 0,
      type: "",
      maxLockedAmount: "",
      hide: "",
      errors: {
        lockedAmount: "",
        estAPR: "",
        duration: "",
        currency: "",
        type: "",
        maxLockedAmount: "",
        hide: "",
      },
      showDuration: false,
      cryptoList: [],
      currency: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    console.log("propssss", nextProps);

    this.setState({
      errors: {},
      lockedAmount: "",
      estAPR: "",
      duration: "",
      currency: "",
      type: "",
      hide: "",
      maxLockedAmount: "",
    });

    if (nextProps.cryptoList) {
      this.setState({
        cryptoList: nextProps.cryptoList,
      });
    }

    if (nextProps.errors) {
      toast.dismiss();
      toast(nextProps.errors.message, {
        position: toast.POSITION.TOP_CENTER,
      });

      // toast.error(nextProps.errors.message);
      // this.setState({
      //   errors: nextProps.errors,
      // });
    } else {
      this.setState({
        errors: {},
        lockedAmount: "",
        estAPR: "",
        duration: "",
        currency: "",
        type: "",
        hide: "",
        maxLockedAmount: "",
      });
    }
  }

  closeModal = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  handleValidation = (event) => {
    if (event.key === "e" || event.key === "E") {
      event.preventDefault();
    }
    if (
      event.key === "-" ||
      event.key === "+" ||
      event.key === "!" ||
      event.key === "@" ||
      event.key === "#" ||
      event.key === "$" ||
      event.key === "%" ||
      event.key === "^" ||
      event.key === "&" ||
      event.key === "*" ||
      event.key === "(" ||
      event.key === ")"
    ) {
      event.preventDefault();
    }
  };

  onChange = (e) => {
    let { id, value } = e.target;

    this.setState({
      errors: {
        lockedAmount: "",
        estAPR: "",
        duration: "",
        currency: "",
        type: "",
        hide: "",
        maxLockedAmount: "",
      },
    });

    if (id === "type") {
      if (value === "" || !value) {
        this.setState({
          errors: {
            type: "Please select stake type",
          },
          showDuration: false,
          type: value,
        });
        return false;
      }

      // if (value === "Days") {
      //   this.setState({
      //     errors: {
      //       type: "",
      //     },
      //     showDuration: true,
      //     type: value,
      //   });
      //   return false;
      // }

      this.setState({
        errors: {
          type: "",
        },
        showDuration: false,
        duration: 0,
        type: value,
      });
    }

    if (id === "currency") {
      if (value === "" || !value) {
        this.setState({
          errors: {
            currency: "Please select crypto currency",
          },
          currency: value,
        });
        return false;
      }

      this.setState({
        errors: {
          currency: "",
        },
        currency: value,
      });
    }

    if (id === "estAPR") {
      if (!value) {
        this.setState({
          errors: {
            estAPR: "Please enter Est APR %",
          },
          estAPR: value,
        });
        return false;
      }

      this.setState({
        errors: {
          estAPR: "",
        },
        estAPR: value,
      });
    }

    if (id === "duration") {
      if (value === "" || !value) {
        this.setState({
          errors: {
            duration: "Please enter duration in days",
          },
          duration: value,
        });
        return false;
      }

      this.setState({
        errors: {
          duration: "",
        },
        duration: value,
      });
    }

    if (id === "lockedAmount") {
      let newValue = value;

      const parts = newValue.split(".");

      if (parts[0].length > 8) {
        parts[0] = parts[0].substring(0, 8);
      }

      if (parts[1] && parts[1].length > 8) {
        parts[1] = parts[1].substring(0, 8);
      }

      newValue = parts.join(".");

      if (value === "" || !value) {
        this.setState({
          errors: {
            lockedAmount: "Please enter min locked amount",
          },
          lockedAmount: newValue,
        });
        return false;
      }

      this.setState({
        errors: {
          lockedAmount: "",
        },
        lockedAmount: newValue,
      });
    }

    if (id === "maxLockedAmount") {
      // if (value === "" || !value) {
      //   this.setState({
      //     errors: {
      //       maxLockedAmount: "Please enter max locked amount",
      //     },
      //     maxLockedAmount: value,
      //   });
      //   return false;
      // }

      // function validateDecimal(input) {
      let newValue = value;

      const parts = newValue.split(".");

      if (parts[0].length > 8) {
        parts[0] = parts[0].substring(0, 8);
      }

      if (parts[1] && parts[1].length > 8) {
        parts[1] = parts[1].substring(0, 8);
      }

      newValue = parts.join(".");

      this.setState({
        maxLockedAmount: newValue,
      });
    }

    if (id === "hide") {
      if (!value || value === "") {
        this.setState({
          errors: {
            hide: "Please select show",
          },
          hide: value,
        });
        return false;
      }

      this.setState({
        errors: {
          hide: "",
        },
        hide: value,
      });
    }
  };

  stakeAdd = (e) => {
    e.preventDefault();

    const positiveIntegerRegex = /^\d+(\.\d+)?$/;

    let isIntegerRegex = /^\d+$/;

    if (this.state.type === "" || !this.state.type) {
      this.setState({
        errors: {
          type: "Please select stake type",
        },
      });
      return false;
    }

    if (this.state.currency === "" || !this.state.currency) {
      this.setState({
        errors: {
          currency: "Please select currency",
        },
      });
      return false;
    }

    if (this.state.estAPR == 0 || !this.state.estAPR) {
      this.setState({
        errors: {
          estAPR: "Please enter Est. APR",
        },
      });
      return false;
    }

    if (!positiveIntegerRegex.test(Number(this.state.estAPR))) {
      this.setState({
        errors: {
          estAPR: "Est. APR should be valid integer",
        },
      });
      return false;
    }

    if (
      this.state.lockedAmount == 0 ||
      !this.state.lockedAmount ||
      this.state.lockedAmount <= 0
    ) {
      this.setState({
        errors: {
          lockedAmount: "Please enter min locked amount",
        },
      });
      return false;
    }

    if (!positiveIntegerRegex.test(Number(this.state.lockedAmount))) {
      this.setState({
        errors: {
          lockedAmount: "Please enter valid amount",
        },
      });
      return false;
    }

    if (
      this.state.maxLockedAmount == 0 ||
      !this.state.maxLockedAmount ||
      this.state.maxLockedAmount <= 0
    ) {
      this.setState({
        errors: {
          maxLockedAmount: "Please enter max locked amount",
        },
      });
      return false;
    }

    if (!positiveIntegerRegex.test(Number(this.state.maxLockedAmount))) {
      this.setState({
        errors: {
          maxLockedAmount: "Please enter valid amount",
        },
      });
      return false;
    }

    if (Number(this.state.lockedAmount) > Number(this.state.maxLockedAmount)) {
      this.setState({
        errors: {
          lockedAmount:
            "Min locked amount should be lower than max locked amount",
        },
      });

      return false;
    }

    if (Number(this.state.lockedAmount) == Number(this.state.maxLockedAmount)) {
      this.setState({
        errors: {
          lockedAmount: "Min locked amount cannot be same as max locked amount",
        },
      });

      return false;
    }

    if (this.state.showDuration === true) {
      if (this.state.duration == 0 || !this.state.duration) {
        this.setState({
          errors: {
            duration: "Please enter duration",
          },
        });
        return false;
      }

      if (!isIntegerRegex.test(Number(this.state.duration))) {
        this.setState({
          errors: {
            duration: "Duration should be valid integer",
          },
        });
        return false;
      }
    }

    if (this.state.hide === "" || !this.state.hide) {
      this.setState({
        errors: {
          hide: "Please select show",
        },
      });
      return false;
    }

    // let convertedLockAmt = Number(this.state.lockedAmount) / 1;

    // // Convert the number to a string and split it by the decimal point
    // const parts = convertedLockAmt.toString().split(".");

    // if (parts[1].length > 8) {
    //   this.setState({
    //     errors: {
    //       lockedAmount:
    //         "Min lock amount decimal point cannot be more than 8 digits",
    //     },
    //   });
    //   return false;
    // }

    // let convertedMaxLockAmt = Number(this.state.maxLockedAmount) / 1;

    // const partsMax = convertedMaxLockAmt.toString().split(".");
    // console.log("partsMax", partsMax);

    // if (partsMax[1].length > 8) {
    //   this.setState({
    //     errors: {
    //       lockedAmount:
    //         "Max lock amount decimal point cannot be more than 8 digits",
    //     },
    //   });
    //   return false;
    // }

    const data = {
      currency: this.state.currency,
      lock_amount: this.state.lockedAmount,
      estimate: this.state.estAPR,
      duration: this.state.duration,
      type: this.state.type,
      showDuration: this.state.showDuration,
      max_lock_amount: this.state.maxLockedAmount,
      show: this.state.hide,
    };

    this.props.addStack(data);
  };

  showHtml = () => {
    const html = [];
    if (this.state.cryptoList.length > 0) {
      this.state.cryptoList.map(function (value, i) {
        html.push(
          <>
            <option value={value.id}>{value.short_name}</option>
          </>
        );
      });
    }
    return html;
  };

  handleClear = () => {
    this.setState({
      errors: {},
      lockedAmount: "",
      maxLockedAmount: "",
      estAPR: "",
      duration: "",
      currency: "",
      type: "",
    });
  };

  render() {
    const { errors } = this.state;
    return (
      <div>
        <div
          className="modal fade"
          id="add-user-modal"
          data-reset="true"
          data-backdrop="static"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Add Stakes</h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  onClick={this.handleClear}
                >
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <form noValidate onSubmit={this.stakeAdd} id="add-user">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="name">Stake Type</label>
                        <select
                          className="form-control"
                          id="type"
                          value={this.state.type}
                          error={errors.type}
                          onChange={this.onChange}
                        >
                          <option value="">Please select type</option>
                          <option value="Days">Fixed</option>
                          <option value="Flexible">Flexible</option>
                        </select>
                        <span className="text-danger">{errors.type}</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="name">Currency Name</label>
                        <select
                          className="form-control"
                          id="currency"
                          value={this.state.currency}
                          error={errors.currency}
                          onChange={this.onChange}
                        >
                          <option value={""}>
                            Please select crypto currency
                          </option>
                          {this.showHtml()}
                        </select>
                        <span className="text-danger">{errors.currency}</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="buyCommission">Est. APR(%)</label>
                        <input
                          onChange={this.onChange}
                          value={this.state.estAPR}
                          id="estAPR"
                          type="number"
                          onKeyDown={this.handleValidation}
                          placeholder="Est APR %"
                          error={errors.estAPR}
                          className={classnames("form-control", {
                            invalid: errors.estAPR,
                          })}
                        />
                        <span className="text-danger">{errors.estAPR}</span>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="minWithdrawal">Min Locked Amount</label>
                        <input
                          onChange={this.onChange}
                          value={this.state.lockedAmount}
                          id="lockedAmount"
                          type="number"
                          onKeyDown={this.handleValidation}
                          placeholder="Min Locked Amount"
                          error={errors.lockedAmount}
                          className={classnames("form-control", {
                            invalid: errors.lockedAmount,
                          })}
                        />

                        <span className="text-danger">
                          {errors.lockedAmount}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="minWithdrawal">Max Locked Amount</label>
                        <input
                          onChange={this.onChange}
                          value={this.state.maxLockedAmount}
                          id="maxLockedAmount"
                          type="number"
                          onKeyDown={this.handleValidation}
                          placeholder="Max Locked Amount"
                          error={errors.maxLockedAmount}
                          className={classnames("form-control", {
                            invalid: errors.maxLockedAmount,
                          })}
                        />

                        <span className="text-danger">
                          {errors.maxLockedAmount}
                        </span>
                      </div>
                    </div>

                    {/* {this.state.showDuration && ( */}
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="sellCommission">
                            Duration (Days)
                          </label>
                          <input
                            onChange={this.onChange}
                            value={this.state.duration}
                            id="duration"
                            type="number"
                            placeholder="Duration"
                            onKeyDown={this.handleValidation}
                            error={errors.duration}
                            className={classnames("form-control", {
                              invalid: errors.duration,
                            })}
                          />
                          <span className="text-danger">{errors.duration}</span>
                        </div>
                      </div>
                    {/* // )} */}

                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="buyCommission">Show Stake</label>{" "}
                        <select
                          className="form-control"
                          id="hide"
                          value={this.state.hide}
                          error={errors.hide}
                          onChange={this.onChange}
                        >
                          <option value={""}>Please select show status</option>
                          <option value={"0"}>Show</option>
                          <option value={"1"}>Hide</option>
                        </select>
                        <span className="text-danger">{errors.hide}</span>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  form="add-user"
                  type="submit"
                  className="btn btn-primary"
                >
                  Save
                </button>
              </div>
            </div>
            {/* <ToastContainer /> */}
          </div>
        </div>
      </div>
    );
  }
}

StackingAdd.propTypes = {
  auth: PropTypes.object.isRequired,
  comdata: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  comdata: state.comdata,
  errors: state.errors,
});

export default connect(mapStateToProps, { addStack })(withRouter(StackingAdd));
