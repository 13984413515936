import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateCountry } from "../../actions/countryActions";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import $ from "jquery";

import Moment from "moment";
import axios from "axios";

import "react-toastify/dist/ReactToastify.css";
import { baseUrl } from "../pages/baseUrl";
class CoinWithdrawalReject extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.record.id,
      reject: this.props.record.reject,
      status: this.props.record.WithdrawlRequest,
      user_id:this.props.record.user_id,
      coin_amount:this.props.coin_amount,
      cryptocoin_id:this.props.cryptocoin_id,
      errors: {},
      withdrawalDisabled: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.record) {
      this.setState({
        id: nextProps.record.id,
        reject: nextProps.record.reject,
        user_id:nextProps.record.user_id,
        coin_amount:nextProps.record.coin_amount,
        cryptocoin_id:nextProps.record.cryptocoin_id,
        status: nextProps.record.WithdrawlRequest,
      });
    }
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
    if (
      nextProps.auth !== undefined &&
      nextProps.auth.user !== undefined &&
      nextProps.auth.user.data !== undefined &&
      nextProps.auth.user.data.messageU !== undefined &&
      nextProps.auth.user.data.success
    ) {
      toast(nextProps.auth.user.data.messageU, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }

  onChange = (e) => {
    if (e.target.id === "user-update-reject") {
      this.setState({ reject: e.target.value });
      if(e.target.value==""){
        this.setState({errors:{reject:"This field is required"}})
        return false
      }
      this.setState({errors:{reject:""}})
    }
  };

  onCountryUpdate = (e) => {
    e.preventDefault();
  
    if(!this.state.reject){
      this.setState({errors:{reject:"This field is required"}})
      return false
    }
    this.onRejectRefund()
    
    this.setState({withdrawalDisabled: true})
    setTimeout(() => this.setState({ withdrawalDisabled: false }), 10000);

    axios
      .post(baseUrl + "/api/coinStatus-update", {
        id: this.state.id,
        reject: this.state.reject,
        type: "2",
      })
      .then((res) => {
        if (res.status === 200) {
          $("#update-user-modal-reject").modal("hide");
          toast(res.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          setTimeout(function () {
            window.location.reload();
          }, 3000);
        }
      })
      .catch();
  };

  onRejectRefund = (e) => {
    if(!this.state.reject){
      this.setState({errors:{reject:"This field is required"}})
      return false
    }
    axios
      .post(baseUrl + "/api/coinRefundOnReject", {
        user_id: this.state.user_id,
        coin_amount: this.state.coin_amount,
        cryptocoin_id:this.state.cryptocoin_id
      })
      .then((res) => {
        if (res.status === 200) {
          $("#update-user-modal-reject").modal("hide");
          toast(res.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          setTimeout(function () {
            window.location.reload();
          }, 3000);
        }
      })
      .catch();
  };

  render() {
    const { errors } = this.state;
    return (
      <div>
        <div className="modal fade" id="update-user-modal-reject" data-backdrop="static">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Withdrawal Request</h4>
                <button type="button" className="close" data-dismiss="modal" onClick={()=>{
                  this.setState({reject:""})
                }}>
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <form
                  noValidate
                  onSubmit={this.onCountryUpdate}
                  id="update-user"
                >
                  <input
                    onChange={this.onChange}
                    value={this.state.id}
                    id="user-update-id"
                    type="text"
                    className="d-none"
                  />

                  <div className="row">
                    <div className="col-md-12">
                      <label htmlFor="name">Withdrawal Request</label>
                      <textarea
                        onChange={this.onChange}
                        value={this.state.reject}
                        id="user-update-reject"
                        type="text"
                        error={errors.reject}
                        className={classnames("form-control", {
                          invalid: errors.reject,
                        })}
                      />
                      <span className="text-danger">{errors.reject}</span>
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={()=>{
                    this.setState({reject:""})
                  }}
                >
                  Close
                </button>
                {this.state.status == "0" && (
                  <button
                    form="update-user"
                    type="submit"
                    className="btn btn-primary"
                    disabled={this.state.withdrawalDisabled}
                  >
                    Update
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CoinWithdrawalReject.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps)(withRouter(CoinWithdrawalReject));
