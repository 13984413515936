import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateCommissions } from "../../actions/WithdrawalCommission";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import $ from "jquery";

import "react-toastify/dist/ReactToastify.css";

class WithdrawalCommissionUpdate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.record.id,
      currency: this.props.record.currency,
      buyCommission: this.props.record.commission,
      minWithdrawal: this.props.record.min_withdrawal,
      type: this.props.record.type,
      errors: {},
      cryptoList: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.cryptoList) {
      this.setState({
        cryptoList: nextProps.cryptoList,
      });
    }
    if (nextProps.record) {
      this.setState({
        id: nextProps.record.id,
        currency: nextProps.record.currency,
        buyCommission: nextProps.record.commission,
        minWithdrawal: nextProps.record.min_withdrawal,
        maxWithdrawal: nextProps.record.max_withdrawal,
        type: nextProps.record.type,
      });
    }
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
    if (
      nextProps.comdata !== undefined &&
      nextProps.comdata.commission !== undefined &&
      nextProps.comdata.commission.data !== undefined &&
      nextProps.comdata.commission.data.message !== undefined &&
      nextProps.comdata.commission.data.success
    ) {
      this.setState({ errors: {} });
    }
  }

  onChange = (e) => {
    this.setState({ errors: {} });
    this.setState({ [e.target.id]: e.target.value });
  };

  onCountryUpdate = (e) => {
    e.preventDefault();
    if(!this.state.currency){
      this.setState({currencyError:"This field is required"})
      return false
    }
    if(!this.state.buyCommission){
      this.setState({buyCommissionError:"This field is required"})
      return false
    }
    if(!this.state.maxWithdrawal){
      this.setState({maxWithdrawalError:"This field is required"})
      return false
    }
    if(!this.state.minWithdrawal){
      this.setState({minWithdrawalError:"This field is required"})
      return false
    }
    if(!this.state.type){
      this.setState({feesTypeError:"This field is required"})
      return false
    }
    if(parseFloat(this.state.minWithdrawal)>parseFloat(this.state.maxWithdrawal)){
      toast.error("Min Withdrawal limit must be less than maximum withdrawal limit")
      return false
    }
    const newCountry = {
      id: this.state.id,
      currency: this.state.currency,
      buyCommission: this.state.buyCommission,
      minWithdrawal: this.state.minWithdrawal,
      maxWithdrawal: this.state.maxWithdrawal,
      type: this.state.type,
    };
    this.props.updateCommissions(newCountry).then(res=>{
         if(res.data.success){
          // toast.success(res.data.message)
          // setTimeout(() => {
            
          // }, 2000);
          // window.location.reload();
         }
         else{
          toast.error(res.data.message)
         }
    }).catch()
  };
  showHtml = () => {
    const html = [];
    this.state.cryptoList.map(function (value, i) {
      html.push(
        <>
          <option value={value.id}>{value.name}</option>
        </>
      );
    });
    return html;
  };
  render() {
    const { errors } = this.state;
    return (
      <div>
        <div className="modal fade" id="update-user-modal" data-backdrop="static">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Update Withdrawal Fees</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <form
                  noValidate
                  onSubmit={this.onCountryUpdate}
                  id="update-user"
                >
                  <input
                    onChange={this.onChange}
                    value={this.state.id}
                    id="user-update-id"
                    type="text"
                    className="d-none"
                  />
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="name">Coin Name</label>
                        <select
                          disabled="true"
                          className="form-control"
                          id="currency"
                          value={this.state.currency}
                          error={errors.currency}
                          onChange={this.onChange}
                        >
                          {this.showHtml()}
                        </select>
                        <span className="text-danger">{this.state.currencyError}</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="buyCommission">Withdrawal Fees</label>
                        <input
                          onChange={this.onChange}
                          value={this.state.buyCommission}
                          id="buyCommission"
                          type="number"
                          onKeyPress={(event) => {
                            if (!/[0-9.]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          error={errors.buyCommission}
                          className={classnames("form-control", {
                            invalid: errors.buyCommission,
                          })}
                        />
                        <span className="text-danger">
                          {this.state.buyCommissionError}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="minWithdrawal">Min Withdrawal</label>
                        <input
                          onChange={this.onChange}
                          value={this.state.minWithdrawal}
                          id="minWithdrawal"
                          type="number"
                          onKeyPress={(event) => {
                            if (!/[0-9.]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          error={errors.minWithdrawal}
                          className={classnames("form-control", {
                            invalid: errors.minWithdrawal,
                          })}
                        />
                        <span className="text-danger">
                          {this.state.minWithdrawalError}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="maxWithdrawal">Max Withdrawal</label>
                        <input
                          onChange={this.onChange}
                          value={this.state.maxWithdrawal}
                          id="maxWithdrawal"
                          type="number"
                          error={errors.maxWithdrawal}
                          onKeyPress={(event) => {
                            if (!/[0-9.]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          className={classnames("form-control", {
                            invalid: errors.maxWithdrawal,
                          })}
                        />
                        <span className="text-danger">
                          {this.state.maxWithdrawalError}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="minWithdrawal">Fees Type</label>
                        <select
                          className="form-control"
                          onChange={this.onChange}
                          value={this.state.type}
                          id="type"
                        >
                          {/* <option>Select</option> */}
                          {/* <option value="flat">Flat</option> */}
                          <option value="percentage">Percentage</option>
                        </select>
                        <span className="text-danger">
                          {this.state.feesTypeError}
                        </span>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  form="update-user"
                  type="submit"
                  className="btn btn-primary"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

WithdrawalCommissionUpdate.propTypes = {
  updateCommission: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  comdata: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  comdata: state.comdata,
  errors: state.errors,
});

export default connect(mapStateToProps, { updateCommissions })(
  withRouter(WithdrawalCommissionUpdate)
);
