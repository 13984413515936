
import React, { useState, useEffect } from 'react'
import { baseUrl } from '../../actions/baseUrl'
import axios from "axios"
import $ from 'jquery';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

function UpdateTradingStepModal(props) {
  console.log("props", props)
  const [id, setId] = useState("")
  const [date, setDate] = useState("")
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [header, setHeader] = useState("")
  const [image, setImage] = useState("")
  const [imageUrl, setImageUrl] = useState("")
  const [dateErr, setDateErr] = useState("")
  const [titleErr, setTitleErr] = useState("")
  const [descriptionErr, setDescriptionErr] = useState("")
  const [headerErr, setHeaderErr] = useState("")
  const [imageErr, setImageErr] = useState("")
  const [type, setType] = useState("web")
  const [status, setStatus] = useState(false)
  const [language, setLanguage] = useState("en")

  useEffect(() => {
    setHeader(props.currentRecord?.header ? props.currentRecord?.header : "")
    setType(props.currentRecord.type)
    setStatus(props.currentRecord.status)
    setId(props.currentRecord.id)
    setImageUrl(props.currentRecord.image)
    setLanguage(props.currentRecord.lang)
  }, [props])



  const handleChange = (e) => {
    const { name, value } = e.target
    if (name === "date") {
      setDate(value)
      if (value === "") {
        setDateErr("This field is required")
        return false
      }
      setDateErr("")
    }
    if (name === "type") {
      setType(value)

    }
    if (name === "status") {
      setStatus(e.target.checked)
    }
    if (name === "lang") {
      setLanguage(value)

    }
    // if (name === "title") {
    //   setTitle(value)
    //   if (value == "") {
    //     setTitleErr("This field is required")
    //     return false
    //   }
    //   setTitleErr("")
    // }
    if (name === "header") {
      setHeader(value)
      if (value === "") {
        setHeaderErr("This field is required")
        return false
      }
      setHeaderErr("")
    }
  }
  const handleImage = (e) => {
    let { value } = e.target;

    const icon = e.target.files[0];

    if (value !== "" || value !== undefined) {
      setImage(e.target.files[0]);
      setImageUrl(URL.createObjectURL(e.target.files[0]));
      setImageErr("");
    } else {
      setImage(e.target.files[0]);
      setImageUrl("");
      setImageErr("This field is required");
    }
    if (!icon.name.match(/\.(jpg|jpeg|png)$/)) {
      setImageErr("Select valid image format");
      return false;
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault()

    // if (title === "") {
    //   setTitleErr("This field is required")
    //   return false
    // }
    if (header === "") {
      setHeaderErr("This field is required")
      return false
    }

    let formdata = new FormData
    // formdata.append("date", date)
    // formdata.append("title", title)
    formdata.append("header", header)
    formdata.append("type", type)
    // formdata.append("status", status)
    formdata.append("image", image)
    formdata.append("lang", language)
    formdata.append("id", id)
    axios.post(baseUrl + "/api/update_pop_up", formdata).then((res) => {
      if (res.data.status) {
        props.setRefresh(!props.refresh)
        $('#update-user-modal').modal('hide');
        setDate("")
        setTitle("")
        setDescription("")
        setImage("")
        setImageUrl("")
      }
    }).catch((err) => {

    })
  }
  return (
    <div>
      <div className="modal fade" id="update-user-modal">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Edit Pop Up</h4>
              <button type="button" className="close" data-dismiss="modal">&times;</button>
            </div>
            <div className="modal-body">
              <form id="update-user1" onSubmit={handleSubmit}>
              <div className='mb-3'>
                  <label className='form-label'>Language:</label>
                  <select onChange={handleChange} value={language} name="lang" className="form-select w-100 form-control">
                    <option value="en">English</option>
                    <option value="ko">Korean</option>
                    <option value="rus">Russian</option>
                    <option value="per">Persian</option>
                    <option value="ar">Arabic</option>
                  </select>

                </div>

                <div className='mb-3'>
                  <label className='form-label'>Title:</label>
                  <textarea name="header" value={header} onChange={handleChange} className='form-control' />
                  <span style={{ color: "red" }}>{headerErr}</span>
                </div>
                
                <div className='mb-3'>
                  <label className='form-label'>Type:</label>
                  <select onChange={handleChange} value={type} name="type" className="form-select w-100 form-control">
                    <option value="web">Web</option>
                    <option value="mobile">Mobile</option>
                  </select>

                </div>
                {/* <label className='form-label'>Show:</label>
                      <input type="checkbox" name="status" checked={status} onChange={handleChange}  className='form-control'/> */}

                <div className='mb-3'>
                  <img
                    style={{ width: "100px" }}
                    src={imageUrl}
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className='mb-3'>
                  <label className='form-label'>Image:</label>
                  <input type="file" name="image" onChange={handleImage} className='form-control' accept="image/png, image/jpeg, image/jpg" />
                  <span style={{ color: "red" }}>{imageErr}</span>
                </div>




              </form>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
              <button
                form="update-user1"
                type="submit"
                className="btn btn-primary">
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UpdateTradingStepModal
