import axios from "axios";
import {
    GET_ERRORS,
    MARGINCALL_ADD,
    MARGINCALL_UPDATE
} from "./types";
import * as myConstList from "./baseUrl"

const baseUrl = myConstList.baseUrl;

export const addMargincall = (MargincallData, history) => dispatch => {
    axios
        .post(baseUrl + "/api/margin-call-add", MargincallData)
        .then(res =>
            dispatch({
                type: MARGINCALL_ADD,
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    );
};


export const updateMargincall = (MargincallData) => dispatch => {
    axios
        .post(baseUrl + "/api/margin-call-update", MargincallData)
        .then(res =>
            dispatch({
                type: MARGINCALL_UPDATE,
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    );
};
