import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@mkikets/react-datatable';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import CryptoAddModal from "../partials/CryptoAddModal";
import CryptoUpdateModal from "../partials/CryptoUpdateModal";
import { toast, ToastContainer } from "react-toastify";
import Moment from 'moment';
import $ from 'jquery';
import { baseUrl } from "../../actions/baseUrl";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import BannerAddModel from "../partials/BannerAddModel";
import BannerUpdateModal from "../partials/BannerUpdateModel"
class Banner extends Component {

    constructor(props) {
        super(props);

        this.columns = [

            {
                key: "id",
                text: "#",
                className: "id",
                align: "left",
                sortable: true,
                cell: (row, index) => index + 1
            },
            {
                key: "banner_name",
                text: "Name",
                className: "name",
                align: "left",
                sortable: true,
            },
            {
                key: "banner_link",
                text: "Link",
                className: "name",
                align: "left",
                sortable: true,
            },
            // {
            //     key: "lang",
            //     text: "Language",
            //     className: "name",
            //     align: "left",
            //     sortable: true,
            // },
            {
                key: "banner_image",
                text: "Banner Image",
                className: "banner_link",
                align: "left",
                sortable: true,
                cell: (record) => {
                    return (
                        <Fragment><img src={baseUrl + "/static/banner/" + record.banner_image} alt="banner" className="img-thumbnail" style={{ height: "50px" }} /></Fragment>
                    )
                }
            },
            {
                key: "created_at",
                text: "Date",
                className: "date",
                align: "left",
                sortable: true,
                cell: record => {
                    return (
                        <Fragment>
                            {Moment(record.created).format('lll')}
                        </Fragment>
                    );
                }
            },
            {
                key: "action",
                text: "Action",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                        <Fragment>
                            <button
                                data-toggle="modal"
                                data-target="#update-user-modal"
                                className="btn btn-primary btn-sm"
                                onClick={() => this.editRecord(record)}
                                style={{ marginRight: '5px' }}>
                                <i className="fa fa-edit"></i>
                            </button>
                            <button
                                className="btn btn-danger btn-sm"
                                onClick={() => this.deleteRecordMgs(record)}>
                                <i className="fa fa-trash"></i>
                            </button>
                        </Fragment>
                    );
                }
            }
        ];

        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            filename: "Crypto Currency List",
            no_data_text: 'No record found!',
            button: {

                print: true,
                csv: true
            },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            records: [],
            cryptoCheckedBoxes: [],
            currentRecord: {
                id: '',
                name: '',
                short_name: '',
                order_sequence: '',
                available_for_loan: '',
                available_for_p2p: '',
                icon: ""
            }
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        if (this.props.auth.user.cryptoCurrency !== undefined && this.props.auth.user.cryptoCurrency !== 1) {
            this.props.history.push("/dashboard");
        }
        this.getData()
    };

    componentWillReceiveProps(nextProps) {
        if (this.props.auth.user.cryptoCurrency !== undefined && this.props.auth.user.cryptoCurrency !== 1) {
            this.props.history.push("/dashboard");
        }
        if (nextProps.banner !== undefined
            && nextProps.banner.banner !== undefined
            && nextProps.banner.banner.data !== undefined
            && nextProps.banner.banner.data.message !== undefined) {

            $('#update-user-modal').modal('hide');
            $('#add-banner-modal').modal('hide');
            toast(nextProps.banner.banner.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            this.setState({ errors: { currencyImage: '' } });
        }
        this.getData();
    }

    getData = () => {        

        axios.get(baseUrl + "/api/banner-data")
            .then(res => {
                console.log(res.data);
                //res.data = res.data.sort((a, b) => (a.created_at < b.created_at ? 1 : -1))


                if (res.status === 200) {
                    this.setState({ records: res.data.data })
                }
            })
            .catch((e) => {
                console.log(e);
            })
    }

    editRecord = (record) => {
        console.log(record);

        this.setState({
            currentRecord: {
                id: record.id,
                banner_image: record.banner_image,
                banner_link: record.banner_link,
                banner_name: record.banner_name,
                lang: record.lang,
            }
        });
    }


    toggleCheckbox = (e, item) => {
        if (e.target.checked) {
            let arr = this.state.cryptoCheckedBoxes;
            arr.push(item.id);
            this.setState = { cryptoCheckedBoxes: arr };
        } else {
            let items = this.state.cryptoCheckedBoxes.splice(this.state.cryptoCheckedBoxes.indexOf(item.id), 1);
            this.setState = {
                cryptoCheckedBoxes: items
            }
        }
        //console.log(this.state.cryptoCheckedBoxes);
    }

    deleteRecordMgs(record) {
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Really want to delete this crypto currency?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => this.deleteRecord(record)
                },
                {
                    label: 'No'

                }
            ]
        });
    }

    addBanner(record) {
        axios.post(baseUrl + "/api/addBanner", { id: record.id })
            .then(res => {
                if (res.status === 200) {
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            })
            .catch();
        this.getData();
    }

    deleteRecord(record) {

        axios.post(baseUrl + "/api/banner-delete", { id: record.id })
            .then(res => {
                if (res.status === 200) {
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                    this.getData();
                }
            })
            .catch();
       
    }

    pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }

    render() {

        return (
            <div>
                <Navbar />
                <div className="d-flex" id="wrapper">

                    <Sidebar />
                    <BannerAddModel />
                    <BannerUpdateModal currentRecord={this.state.currentRecord} />
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <button className="btn btn-link mb-3" id="menu-toggle"><FontAwesomeIcon icon={faList} /></button>
                            <button className="btn btn-primary float-right mb-3 mr-2" data-toggle="modal" data-target="#add-banner-modal"><FontAwesomeIcon icon={faPlus} />Add</button>

                            <h3 className="mt-2 man_hadding mb-3">Banner Management</h3>
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                onPageChange={this.pageChange.bind(this)}
                            />
                        </div>
                    </div>
                    <ToastContainer />
                </div>
            </div>
        );
    }

}

Banner.propTypes = {
    auth: PropTypes.object.isRequired,
    banner: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
    auth: state.auth,
    banner: state.banner,
    records: state.records
});
export default connect(
    mapStateToProps
)(Banner);
