import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@mkikets/react-datatable';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import axios from "axios";
import {Link} from "react-router-dom";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import UserAddModal from "../partials/UserAddModal";
import UserUpdateModal from "../partials/UserUpdateModal";
import { toast, ToastContainer} from "react-toastify";
import Moment from 'moment';
import {baseUrl} from "../../actions/baseUrl";

class FundRequest extends Component {

    constructor(props) {
        super(props);

        this.columns = [

            {
                key: "username",
                text: "User Name",
                className: "username",
                align: "left",
                sortable: true,
                cell: record => {
                    return (
                       <span>
                          {record.firstName}   {record.lastName} 
                          </span>
                    );
                }
            },


            {
                key: "bankName",
                text: "Bank Name",
                className: "bankName",
                align: "left",
                sortable: true,
                cell: record => {
                    return (
                       <span>
                          {record.BankName} 
                          </span>
                    );
                }
            },
            {
                key: "accNum",
                text: "Account Number",
                className: "accNum",
                align: "left",
                sortable: true,
                cell: record => {
                    return (
                       <span>
                          {record.AccNum} 
                          </span>
                    );
                }
            },
            {
                key: "_id",
                text: "Transaction_id",
                className: "transaction_id",
                align: "left",
                sortable: true,
                // cell: (row, index)=>index+1

                cell: record => {
                    return (
                       <span>
                          {record.tx_id}
                          </span>
                    );
                }
            },
           
            {
                key: "amount",
                text: "Amount",
                className: "amount",
                align: "left",
                sortable: true,
                cell: record => {
                    return (
                       <span>
                          {record.coin_amount}
                          </span>
                    );
                }
            },
            {
                key: "status",
                text: "Status",
                className: "status",
                align: "left",
                sortable: true
            },
            {   
                key: "date",
                text: "Date",
                className: "date",
                align: "left",
                sortable: true,
                cell: record => {
                    return (
                        <Fragment>
                          {Moment(record.created).format('lll')}
                        </Fragment>
                    );
                }
            },
           
        ];

        this.config = {
            page_size: 10,
            length_menu: [ 10, 20, 50 ],
            filename: "Fund Request List",
            no_data_text: 'No user found!',
            button: {
                
                print: true,
                csv: true
            },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            records: []
        };

        this.state = {
            currentRecord: {
                id: '',
                name: '',
                email: '',
                password: '',
                password2: '',
            }
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        if (this.props.auth.user.fundRequest !==undefined && this.props.auth.user.fundRequest !==1 ) {
            this.props.history.push("/dashboard");
        }
        this.getData();
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.auth.user.fundRequest !==undefined && nextProps.auth.user.fundRequest !==1 ) {
            this.props.history.push("/dashboard");
        }
        this.getData()
    }

    getData() {
        axios
            .get(baseUrl+"/api/userwithdrawalHistory")
            .then(res => {
                this.setState({ records: res.data})
            })
            .catch()
    }

    editRecord(record) {
        this.setState({ currentRecord: record});
    }

    deleteRecord(record) {
        axios
            .post(baseUrl+"/api/transaction-delete", {_id: record._id})
            .then(res => {
                if (res.status === 200) {
                   toast(res.data.message, {
                       position: toast.POSITION.TOP_CENTER,
                   })
                }
            })
            .catch();
        this.getData();
    }

    pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }
    render() {
        return (
            <div>
                <Navbar/>
                <div className="d-flex" id="wrapper">
                    <Sidebar/>
                    <UserAddModal/>
                    <UserUpdateModal record={this.state.currentRecord}/>
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <button className="btn btn-link mt-3" id="menu-toggle"><FontAwesomeIcon icon={faList}/></button>
                          
						   <h4 className="mt-2 man_hadding mb-3">Fund Request List</h4>
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                onPageChange={this.pageChange.bind(this)}
                            />
                        </div>
                    </div>
                    <ToastContainer/>
                </div>
            </div>
        );
    }

}

FundRequest.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});

export default connect(
    mapStateToProps
)(FundRequest);