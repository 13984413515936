import axios from "axios";
import * as myConstList from "./baseUrl"

const baseUrl = myConstList.baseUrl;

export const addNewRoleData = async (userData) => {
    console.log(userData);
    return new Promise(async (resolve, reject) => {
        axios.post(baseUrl + "/api/role-add", userData)
            .then(res => {
                console.log(res);
                if (res.data.success) {
                    resolve({ status: true, data: res.data, message: "" })
                } else {
                    resolve({ status: false, data: res.data, message: "" })
                }

            }).catch(err => {
                console.log(err);
                resolve({ status: false, data: err.response.data, message: "" })
            })
    })

};

export const addRoleData = (userData) => dispatch => {
    console.log(userData);
    axios
        .post(baseUrl + "/api/role-add", userData)
        .then(res =>
            dispatch({
                type: 'ROLE_ADD',
                payload: res,
            })

        ).catch(err =>
            dispatch({
                type: 'GET_ERRORS',
                payload: err.response.data
            })
        );
};
export const updateRoleData = (userData) => dispatch => {
    axios
        .post(baseUrl + "/api/role-update", userData)
        .then(res =>
            dispatch({
                type: 'ROLE_UPDATE',
                payload: res,
            })
        ).catch(err =>
            dispatch({
                type: 'GET_ERRORS',
                payload: err.response.data
            })
        );
};

export const setCurrentRole = decoded => {
    return {
        type: 'SET_CURRENT_ROLE',
        payload: decoded
    };
};

export const setUserLoading = () => {
    return {
        type: 'USER_LOADING'
    };
};

export const roledata = () => dispatch => {

    dispatch(setCurrentRole({}));
};
