import React, { Component, Fragment } from "react";
import Navbar from "../../partials/Navbar";
import Sidebar from "../../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from "@mkikets/react-datatable";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import CredentialsAddModal from "./CredentialsAddModal";
import CredentialsUpdateModal from "./CredentialsUpdateModal";
import { toast, ToastContainer } from "react-toastify";
import Moment from "moment";
import Switch from "react-switch";
import $ from "jquery";
import { baseUrl } from "../../../actions/baseUrl";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
class Credentials extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);

    this.columns = [
      {
        key: "id",
        text: "#",
        className: "id",
        align: "left",
        sortable: true,
        cell: (row, index) => index + 1,
      },
      {
        key: "exchange",
        text: "Exchange",
        className: "exchange",
        align: "left",
        sortable: true,
        cell: (record) => {
          return (
            <>
              <span>{record.exchange}</span>
            </>
          );
        },
      },
      {
        key: "created",
        text: "Date",
        className: "date",
        align: "left",
        sortable: true,
        cell: (record) => {
          return <Fragment>{Moment(record.created_at).format("lll")}</Fragment>;
        },
      },
      {
        key: "action",
        text: "Action",
        className: "action",
        width: 100,
        align: "left",
        sortable: false,
        cell: (record) => {
          return (
            <Fragment>
              <button
                data-toggle="modal"
                data-target="#update-user-modal"
                className="btn btn-primary btn-sm"
                onClick={() => this.editRecord(record)}
                style={{ marginRight: "5px" }}
              >
                <i className="fa fa-edit"></i>
              </button>
            </Fragment>
          );
        },
      },
    ];

    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      filename: "Credentials",
      no_data_text: "No credentials found!",
      button: {
        print: true,
        csv: true,
      },
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: true,
      show_filter: true,
      show_pagination: true,
      show_info: true,
    };

    this.state = {
      records: [],
      pairList: [],
      checked: false,
    };

    this.state = {
      currentRecord: {
        id: "",
        exchange: "",
        pair_ids: "",
        pairs: "",
        api_key: "",
        secrate_key: "",
      },
      switchchecked: false,
    };

    this.getData = this.getData.bind(this);
    console.log("this.getData", this.getData);
  }

  handleChange(record) {
    if (record.status == 1) {
      this.setState({ switchchecked: true });
    }
    if (record.status == 0) {
      this.setState({ switchchecked: false });
    }

    axios
      .post(baseUrl + "/api/exchange-credential-status-change", {
        id: record.id,
        status: this.state.switchchecked,
      })
      .then((res) => {
        if (res.status === 200) {
          toast(res.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          this.getData();
        }
      })
      .catch();
    this.getData();
  }

  componentDidMount() {
    if (
      this.props.auth.user.credentialManagement !== undefined &&
      this.props.auth.user.credentialManagement !== 1
    ) {
      this.props.history.push("/dashboard");
    }
    this.getData();
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.auth.user.credentialManagement !== undefined &&
      nextProps.auth.user.credentialManagement !== 1
    ) {
      this.props.history.push("/dashboard");
    }
    
    if (
      nextProps.credentialdata !== undefined &&
      nextProps.credentialdata.credential !== undefined &&
      nextProps.credentialdata.credential.data !== undefined &&
      nextProps.credentialdata.credential.data.message !== undefined
    ) {
      $("#add-user-modal").modal("hide");
      $("#update-user-modal").modal("hide");

      toast(nextProps.credentialdata.credential.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    this.getData();
  }

  getData() {
    axios
      .get(baseUrl + "/api/exchange-credential-data")
      .then((res) => {
        this.setState({ records: res.data });
      })
      .catch();
    axios
      .get(baseUrl + "/api/pair-data")
      .then((res) => {
        this.setState({ pairList: res.data });
      })
      .catch();
  }

  editRecord(record) {
    this.setState({
      currentRecord: record,
      exchange: record.exchange,
      pair_ids: record.pair_ids,
      pairs: record.pairs,
      api_key: record.api_key,
      secrate_key: record.secrate_key,
    });
  }
  deleteRecordMgs(record) {
    confirmAlert({
      title: "Confirm to submit",
      message: "Really want to delete this credentials?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.deleteRecord(record),
        },
        {
          label: "No",
        },
      ],
    });
  }

  deleteRecord(record) {
    axios
      .post(baseUrl + "/api/exchange-credential-delete", { id: record.id })
      .then((res) => {
        if (res.status === 200) {
          toast(res.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch();
    this.getData();
  }

  pageChange(pageData) {
    console.log("OnPageChange", pageData);
  }

  render() {
    return (
      <div>
        <Navbar />
        <div className="d-flex" id="wrapper">
          <Sidebar />
          {/* <CredentialsAddModal
            pairList={this.state.pairList}
            userList={this.state.userList}
          /> */}
          <CredentialsUpdateModal
            record={this.state.currentRecord}
            pairList={this.state.pairList}
            userList={this.state.userList}
          />
          <div id="page-content-wrapper">
            <div className="container-fluid">
              <button className="btn btn-link mt-3" id="menu-toggle">
                <FontAwesomeIcon icon={faList} />
              </button>
              {/* <button
                className="btn btn-outline-primary float-right mb-3 mr-2"
                data-toggle="modal"
                data-target="#add-user-modal"
              >
                <FontAwesomeIcon icon={faPlus} /> Add{" "}
              </button> */}

              <h3 className="mt-2 man_hadding mb-3">
                Binance Pair Management{" "}
              </h3>
              <div className="white_box">

              <ReactDatatable
                config={this.config}
                records={this.state.records}
                columns={this.columns}
                onPageChange={this.pageChange.bind(this)}
              />
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </div>
    );
  }
}

Credentials.propTypes = {
  auth: PropTypes.object.isRequired,
  credentialdata: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  credentialdata: state.credentialdata,
  records: state.records,
});

export default connect(mapStateToProps)(Credentials);
