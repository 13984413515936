import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from "@mkikets/react-datatable";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import CryptoAddModal from "../partials/CryptoAddModal";
import CryptoUpdateModal from "../partials/CryptoUpdateModal";
import { toast, ToastContainer } from "react-toastify";
import Moment from "moment";
import $ from "jquery";
import { baseUrl } from "../../actions/baseUrl";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
class RefrealDepositRequest extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        text: "#",
        className: "id",
        align: "left",
        sortable: true,
        cell: (row, index) => index + 1,
      },
      {
        key: "email",
        text: "Email",
        className: "name",
        align: "left",
        sortable: true,
      },
      {
        key: "plan",
        text: "Plan",
        className: "short_name",
        align: "left",
        sortable: true,
      },
      {
        key: "coin_amount",
        text: "Coin Amount",
        className: "is_deposit",
        align: "left",
        sortable: true,
        cell: (record) => {
          return <Fragment>{record.coin_amount}</Fragment>;
        },
      },
      {
        key: "tx_image",
        text: "Proff Image",
        className: "is_withdrawal",
        align: "left",
        sortable: true,
        cell: (record) => {
          return (
            <Fragment>
              <img
                src={baseUrl + "/static/inr_deposit/" + record.tx_image}
                style={{ width: "100px" }}
                alt=""
              />
            </Fragment>
          );
        },
      },
      {
        key: "utr_id",
        text: "Tx/UTR Id",
        className: "is_trade",
        align: "left",
        sortable: true,
        cell: (record) => {
          return <Fragment>{record.utr_id}</Fragment>;
        },
      },
      {
        text: "Date",
        className: "date",
        align: "left",
        sortable: true,
        cell: (record) => {
          return (
            <Fragment>{Moment(record.created).local().format("lll")}</Fragment>
          );
        },
      },
      {
        key: "action",
        text: "Action",
        className: "action",
        width: 100,
        align: "left",
        sortable: false,
        cell: (record) => {
          return (
            <Fragment>
              <p className="mb-0">
                {/* {alert(record.WithdrawlRequest)}
                 */}
                {record.is_approved == "0" ? (
                  <>
                    <button
                      data-toggle="modal"
                      data-target="#update-user-modal-approve"
                      className="btn btn-primary btn-sm"
                      onClick={() => this.editRecord(record,1)}
                      style={{ marginRight: "5px" }}
                    >
                      <i className="fa fa-check"></i>
                    </button>
                    <button
                      data-toggle="modal"
                      data-target="#update-user-modal-reject"
                      className="btn btn-primary btn-sm"
                      onClick={() => this.editRecord(record,3)}
                      style={{ marginRight: "5px" }}
                    >
                      <i className="fa fa-close"></i>
                    </button>
                  </>
                ) : record.is_approved == "1" ? (
                  <button
                    data-toggle="modal"
                    data-target="#update-user-modal-approve"
                    className="btn btn-primary btn-sm"
                    // onClick={() => this.editRecord(record)}
                    style={{ marginRight: "5px" }}
                  >
                    <span className="completed_btn">
                      Approved <i className="fa fa-check"></i>
                    </span>
                  </button>
                ) : (
                  <>
                    <button
                      data-toggle="modal"
                      data-target="#update-user-modal-reject"
                      className="btn btn-primary btn-sm"
                      // onClick={() => this.editRecord(record)}
                      style={{ marginRight: "5px" }}
                    >
                      Rejected <i className="fa fa-close"></i>
                    </button>{" "}
                  </>
                )}
              </p>
            </Fragment>
          );
        },
      },
    ];

    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      filename: "Crypto Currency List",
      no_data_text: "No record found!",
      button: {
        print: true,
        csv: true,
      },
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: true,
      show_filter: true,
      show_pagination: true,
      show_info: true,
    };

    this.state = {
      records: [],
      cryptoCheckedBoxes: [],
      currentRecord: {
        id: "",
        name: "",
        short_name: "",
        available_for_loan: "",
        available_for_p2p: "",
        icon: "",
        address: "",
      },
    };

    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    if (
      this.props.auth.user.cryptoCurrency !== undefined &&
      this.props.auth.user.cryptoCurrency !== 1
    ) {
      this.props.history.push("/dashboard");
    }
    this.getData();
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.auth.user.cryptoCurrency !== undefined &&
      this.props.auth.user.cryptoCurrency !== 1
    ) {
      this.props.history.push("/dashboard");
    }
    if (
      nextProps.cryptodata !== undefined &&
      nextProps.cryptodata.crypto !== undefined &&
      nextProps.cryptodata.crypto.data !== undefined &&
      nextProps.cryptodata.crypto.data.message !== undefined
    ) {
      $("#update-user-modal").modal("hide");
      $("#add-user-modal").modal("hide");
      toast(nextProps.cryptodata.crypto.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      this.setState({ errors: { currencyImage: "" } });
    }
    this.getData();
  }

  getData = () => {
    axios
      .get(baseUrl + "/api/refrealDeposit-data")
      .then((res) => {
        if (res.status === 200) {
          this.setState({ records: res.data });
        }
      })
      .catch();
  };
  editRecord = (record,is_approved) => {
    let data = {
      id: record.id,
      is_approved: is_approved,
      plan:record.plan,
      amount:record.coin_amount
    };
    axios.post(baseUrl + "/frontapi/request-refreal" , data).then((resp)=>{
      var resp = resp.data
      if(resp.status == true){
        toast.success(resp.message)
        this.getData()
      }
      if(resp.status == false){
        toast.error(resp.message)
        this.getData()
      }
    })
  };

  toggleCheckbox = (e, item) => {
    if (e.target.checked) {
      let arr = this.state.cryptoCheckedBoxes;
      arr.push(item.id);
      this.setState = { cryptoCheckedBoxes: arr };
    } else {
      let items = this.state.cryptoCheckedBoxes.splice(
        this.state.cryptoCheckedBoxes.indexOf(item.id),
        1
      );
      this.setState = {
        cryptoCheckedBoxes: items,
      };
    }
    //console.log(this.state.cryptoCheckedBoxes);
  };
  deleteRecordMgs(record) {
    confirmAlert({
      title: "Confirm to submit",
      message: "Really want to delete this crypto currency?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.deleteRecord(record),
        },
        {
          label: "No",
        },
      ],
    });
  }

  deleteRecord(record) {
    axios
      .post(baseUrl + "/api/refrealDeposit-delete", { id: record.id })
      .then((res) => {
        if (res.status === 200) {
          toast(res.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch();
    this.getData();
  }
  pageChange(pageData) {
    console.log("OnPageChange", pageData);
  }

  render() {
    return (
      <div>
        <Navbar />
        <div className="d-flex" id="wrapper">
          <Sidebar />
          <div id="page-content-wrapper">
            <div className="container-fluid mb-5">
              <button className="btn btn-link mt-3" id="menu-toggle">
                <FontAwesomeIcon icon={faList} />
              </button>
              <button
                className="btn btn-outline-primary float-right mb-3 mr-2"
                data-toggle="modal"
                data-target="#add-user-modal"
              >
                <FontAwesomeIcon icon={faPlus} />
                Add
              </button>
              <h4 className="mt-2 man_hadding">Refreal Program Deposit List</h4>
              <ReactDatatable
                config={this.config}
                records={this.state.records}
                columns={this.columns}
                onPageChange={this.pageChange.bind(this)}
              />
            </div>
          </div>
          <ToastContainer />
        </div>
      </div>
    );
  }
}

RefrealDepositRequest.propTypes = {
  auth: PropTypes.object.isRequired,
  cryptodata: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  cryptodata: state.cryptodata,
  records: state.records,
});
export default connect(mapStateToProps)(RefrealDepositRequest);
