import axios from "axios";
import { GET_ERRORS, USER_ADD, USER_UPDATE } from "./types";
import * as myConstList from "./baseUrl";

const baseUrl = myConstList.baseUrl;

export const addStack = (stackData, history) => (dispatch) => {
  axios
    .post(baseUrl + "/api/stack-add", stackData)
    .then((res) =>
      dispatch({
        type: "STACK_ADD",
        payload: res,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
