const isEmpty = require("is-empty");
const initialState = {
  isAuthenticated: false,
  crypto: {},
  loading: false,
};
export default function (state = initialState, action) {
  console.log("action", action);
  switch (action.type) {
    case "CRYPTO_ADD":
      return {
        isAuthenticated: !isEmpty(action.payload),
        crypto: action.payload,
      };

    case "CRYPTO_UPDATE":
      return {
        isAuthenticated: !isEmpty(action.payload),
        crypto: action.payload,
      };
    case "SET_CURRENT_CRYPTO":
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        crypto: action.payload,
      };
    case "CLEAR_ERRORS":
      return {};
    case "CLEAR_CRYPTO_DATA":
      return {
        ...state,
        crypto: {}, // Reset the crypto data
      };

    case "CRYPTO_LOADING":
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
