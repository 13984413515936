import React, { useEffect, useState } from "react";
import Swal from 'sweetalert2'
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast, ToastContainer } from "react-toastify";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import ReactDatatable from "@mkikets/react-datatable";
import { useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import Moment from "moment";
import axios from "axios";
import classnames from "classnames";
import { baseUrl } from "../pages/baseUrl";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import $ from 'jquery';


const Faq = ({ auth, cryptodata }) => {

  //console.log(auth);

  const history = useHistory();
  const dispatch = useDispatch();

  const [errors, setError] = useState({ email: "", OldPassword: "", NewPassword: "", amount: 0, CPassword: "" })
  const [state, setState] = useState({});
  const [records, setRecords] = useState([]);
  const [selectedFaq, setSelectedFaq] = useState({ header: "", description: "",lang:"en" })
  const [addFaq, setAddFaq] = useState({lang:"en"})

  useEffect(() => {
    if (auth &&
      auth.user.cryptoCurrency !== undefined &&
      auth.user.cryptoCurrency !== 1
    ) {
      history.push("/dashboard");
    }
    getData();
  }, []);

  const getData = () => {
    axios
      .get(baseUrl + "/api/userAllFaq-data")
      .then((res) => {
        if (res.status === 200) {
          setRecords(res.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const updateSubmit = () => {
    //console.log("update");
    let params = selectedFaq;
    axios.post(baseUrl + "/api/updateFaq", params)
      .then((res) => {
        if (res.status === 200) {

          getData()
          $('#update-user-modal').modal('hide');
          setSelectedFaq({ header: "", description: "" })
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }
  const onChange = (e) => {
    setSelectedFaq({ ...selectedFaq, [e.target.name]: e.target.value })
  }

  const handleChange = (e) => {
    setAddFaq({ ...addFaq, [e.target.name]: e.target.value })
  }

  const handleSubmit = () => {

  }

  const addSubmit = () => {
   // console.log("add ", addFaq);
    let params = addFaq;
    axios.post(baseUrl + "/api/addFaq", params)
      .then((res) => {
        if (res.status === 200) {
          getData()
          $('#add-user-modal').modal('hide');
          setAddFaq({ header: "", description: "",lang:"ko" })

        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  const deleteSubmit = (data) => {
   // console.log("delete ", data);
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      // console.log("res ", result);
      let params = { id: data.sno };
      if (result.isConfirmed) {
        axios.post(baseUrl + "/api/deleteFaq", params)
          .then((res) => {
            if (res.status === 200) {
              //setSelectedFaq({ header: "", description: "" })
              getData()
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    })
  }

  const columns = [
    {
      key: "id",
      text: "#",
      className: "id",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "header",
      text: "Header",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "description",
      text: "Description",
      className: "description",
      align: "left",
      sortable: true,
    },
    // {
    //   key: "lang",
    //   text: "Language",
    //   className: "lang",
    //   align: "left",
    //   sortable: true,
    // },


    // {
    //     key: "order_sequence",
    //     text: "Sequence",
    //     className: "order_sequence",
    //     align: "left",
    //     sortable: true,
    // },
    {
      key: "date",
      text: "Date",
      className: "date",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <>{Moment(record.date).format("lll")}</>;
      },
    },
    {
      key: "action",
      text: "Action",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <>
            <button
              data-toggle="modal"

              className="btn btn-primary btn-sm"
              onClick={() => editRecord(record)}
              style={{ marginRight: "5px" }}
            >
              <i className="fa fa-edit"></i>
            </button>

            <button
              className="btn btn-danger btn-sm"
              onClick={() => deleteSubmit(record)}
              disabled={record.is_open === 1 ? true : false}>
              <i className="fa fa-trash"></i>
            </button>

            {/* <button
                            className="btn btn-danger btn-sm"
                            onClick={() => this.deleteRecordMgs(record)}>
                            <i className="fa fa-trash"></i>
                        </button> */}
          </>
        );
      },
    },
  ];

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Crypto Currency List",
    no_data_text: "No record found!",
    button: {
      print: false,
      csv: false,
    },
    language: {
      length_menu: "Show _MENU_ result per page",
      filter: "Filter in records...",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };
  const editRecord = (record) => {
    setSelectedFaq(record)
    $('#update-user-modal').modal('show');
  }
  const addRecord = () => {
    setAddFaq({ header: "", description: "",lang:"en" })
    $('#add-user-modal').modal('show');
  }

  const pageChange = (pageData) => {
   // console.log("OnPageChange", pageData);
  };

  return (
    <div>
      <Navbar />
      <div className="d-flex" id="wrapper">
        <Sidebar />
        <div id="page-content-wrapper">
          <div className="container-fluid">


            <h4 className="mt-2 man_hadding mb-3">FAQ List</h4>
            <div className="white_box">
              <button
                className="btn btn-primary float-right mb-3 mr-2"
                data-toggle="modal"
                onClick={addRecord}
              >
                <FontAwesomeIcon icon={faPlus} />
                Add
              </button>
              <ReactDatatable
                config={config}
                records={records}
                columns={columns}
                onPageChange={pageChange}
              />
            </div>
          </div>
          <ToastContainer />
        </div>

        <div className="modal fade" id="update-user-modal">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Update Faq</h4>
                <button type="button" className="close" data-dismiss="modal">&times;</button>
              </div>
              <div className="modal-body">
                <div className='mb-3'>
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label htmlFor="name">Select Language</label>
                    </div>
                    <div className="col-md-9">
                      {/* <select onChange={onChange} value={selectedFaq.lang} name="lang" className="form-select w-100 form-control">

                        <option value="en">English</option>
                        <option value="ko">Korean</option>
                        <option value="rus">Russian</option>
                        <option value="per">Persian</option>
                        <option value="ar">Arabic</option>


                      </select> */}
                      {/* <span style={{color:"red"}}>{dateErr}</span> */}
                    </div>
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col-md-3">
                    <label htmlFor="name">Header</label>
                  </div>
                  <div className="col-md-9">
                    <input
                      onChange={onChange}
                      value={selectedFaq.header}
                      name="header"
                      type="text"
                      error={errors.name}
                      className={classnames("form-control", {
                        invalid: errors.name,
                      })}
                    />
                    <span className="text-danger">{errors.name}</span>
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col-md-3">
                    <label htmlFor="name">Description</label>
                  </div>
                  <div className="col-md-9">

                    <textarea value={selectedFaq.description} onChange={onChange} name="description" rows="6" className={classnames("form-control", {
                      invalid: errors.name,
                    })}></textarea>
                    <span className="text-danger">{errors.name}</span>
                  </div>
                </div>

                <div className='mb-3'>

                  {/* <span style={{color:"red"}}>{dateErr}</span> */}
                </div>

              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                <button
                  form="update-user1"
                  type="button"
                  className="btn btn-primary" onClick={updateSubmit}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="add-user-modal">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Add Faq</h4>
                <button type="button" className="close" data-dismiss="modal">&times;</button>
              </div>
              <div className="modal-body">
              <div className='mb-3'>
                  <div className="row mt-2">
                    {/* <div className="col-md-3">
                      <label htmlFor="name">Select Language</label>
                    </div> */}
                    <div className="col-md-9">
                      {/* <select onChange={handleChange} value={addFaq.lang} name="lang" className="form-select w-100 form-control">

                        <option value="en">English</option>
                        <option value="ko">Korean</option>
                        <option value="rus">Russian</option>
                        <option value="per">Persian</option>
                        <option value="ar">Arabic</option>


                      </select> */}
                      {/* <span style={{color:"red"}}>{dateErr}</span> */}
                    </div>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-3">
                    <label htmlFor="name">Header</label>
                  </div>
                  <div className="col-md-9">
                    <input
                      value={addFaq.header}
                      onChange={handleChange}
                      id="name"
                      name="header"
                      type="text"
                      error={errors.name}
                      className={classnames("form-control", {
                        invalid: errors.name,
                      })}
                    />
                    <span className="text-danger">{errors.name}</span>
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col-md-3">
                    <label htmlFor="name">Description</label>
                  </div>
                  <div className="col-md-9">

                    <textarea value={addFaq.description} onChange={handleChange} rows="6" name="description" className={classnames("form-control", {
                      invalid: errors.name,
                    })}></textarea>
                    <span className="text-danger">{errors.name}</span>
                  </div>
                </div>

                <div className='mb-3'>

                  {/* <span style={{color:"red"}}>{dateErr}</span> */}
                </div>

              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                <button
                  form="update-user1"
                  type="submit"
                  className="btn btn-primary" onClick={addSubmit}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

}

export default Faq