import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateRefreal } from "../../actions/refrealAction";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import $ from "jquery";

import "react-toastify/dist/ReactToastify.css";
import { baseUrl } from "../../actions/baseUrl";

class ReferralUpdateModal extends React.Component {
  constructor(props) {
    
    super(props);
    this.state = {
      id: this.props.currentRecord.id,
      cryptocurrency:[],
      currency_id: this.props.currentRecord.currency,
      joining_bonus: this.props.currentRecord.join_bonus,
      referral_bonus: this.props.currentRecord.refer_bonus,
      errors: {},
    };
  }

  componentWillReceiveProps(nextProps) {
    console.log("nextProps.componentWillReceivePropsWHYY", nextProps);

    if (nextProps.currentRecord) {
        console.log("nextProps.cryptocurrency",nextProps)
      this.setState({
        id: nextProps.currentRecord.id,
        cryptocurrency:nextProps.cryptoCurrency,
        currency_id: nextProps.currentRecord.currency,
        joining_bonus: nextProps.currentRecord.join_bonus,
        referral_bonus: nextProps.currentRecord.refer_bonus,
      });
      // return false;
    }

    if (nextProps.errors) {
      toast.dismiss();
      toast(nextProps.errors.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      // return false;
      this.setState({
        errors: nextProps.errors,
      });
    }
  }

  handleChange = (event) => {
    let { name, value } = event.target;
    this.setState({ [name]: value, emptyField: false, errMsg: "" });
  };

  onChange = (e) => {
    if (e.target.id === "user-update-joining_bonus") {
      this.setState({ joining_bonus: e.target.value });
    }
    if (e.target.id === "user-update-referral_bonus") {
      this.setState({ referral_bonus: e.target.value });
    }
  };

  onCryptoUpdate = (e) => {
    e.preventDefault();

    const data = {
      id: this.state.id,
      joining_bonus: this.state.joining_bonus,
      referral_bonus: this.state.referral_bonus,
    };

    this.props.updateRefreal(data);
  };

  render() {
    console.log(this.state.is_deposit);

    const { errors } = this.state;
    return (
      <div>
        <div
          className="modal fade"
          id="update-user-modal"
          data-backdrop="static"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Update Currency</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <form
                  noValidate
                  onSubmit={this.onCryptoUpdate}
                  id="update-user"
                >
                  {/* <input
                    onChange={this.onChange}
                    value={this.state.id}
                    id="user-update-id"
                    type="text"
                    className="d-none"
                  /> */}
                  <div className="row">
                    {/* <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="name">Currency Name</label>
                        <input
                          onChange={this.onChange}
                          value={this.state.name}
                          id="user-update-name"
                          type="text"
                          error={errors.name}
                          className={classnames("form-control", {
                            invalid: errors.name,
                          })}
                        />
                        <span className="text-danger">{errors.name}</span>
                      </div>
                    </div> */}
                    
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="chain">Currency</label>
                        {console.log("this.state.currency_id",this.state.currency_id)}
                        <select
                          value={this.state.currency_id}
                          name="chain"
                          onChange={this.handleChange}
                          className="form-control"
                          disabled
                        >
                          {this.state.cryptocurrency.map((list)=>{
                            return (
                                <option value={list.id}>{list.short_name}</option>
                            )
                          })}
                         
                          {/* <option value="BSC">BSC</option>
                          <option value="TRON">TRON</option> */}
                        </select>

                        <span className="text-danger">{errors.chain}</span>
                      </div>
                    </div>


                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="name">Joining Bonus</label>
                        <input
                          onChange={this.onChange}
                          value={this.state.joining_bonus}
                          id="user-update-joining_bonus"
                          type="text"
                          error={errors.joining_bonus}
                          className={classnames("form-control", {
                            invalid: errors.joining_bonus,
                          })}
                        />
                        <span className="text-danger">
                          {errors.joining_bonus}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="name">Referral Bonus</label>
                        <input
                          onChange={this.onChange}
                          value={this.state.referral_bonus}
                          id="user-update-referral_bonus"
                          type="text"
                          error={errors.referral_bonus}
                          className={classnames("form-control", {
                            invalid: errors.referral_bonus,
                          })}
                        />
                        <span className="text-danger">
                          {errors.referral_bonus}
                        </span>
                      </div>
                    </div>

                    {/* <div className="col-md-6">
                      <div className='form-group'>
                        <label htmlFor="name">Order Sequence</label>
                        <input
                          onChange={this.onChange}
                          value={this.state.order_sequence}
                          id="user-update-order_sequence"
                          type="text"
                          error={errors.order_sequence}
                          className={classnames("form-control", {
                            invalid: errors.order_sequence
                          })} />
                        <span className="text-danger">{errors.order_sequence}</span>
                      </div>
                    </div> */}
                  </div>
                  {/* <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="d-block" htmlFor="is_deposit">
                          Deposit
                        </label>
                        <div className="form-check-inline mr-0">
                          <label className="form-check-label">
                            <input
                              checked={this.state.is_deposit == 1}
                              onChange={this.onChange}
                              value="1"
                              name="is_deposit"
                              type="radio"
                              className="form-check-input"
                              id="user-update-is-deposit"
                            />
                            Enabled
                          </label>
                        </div>
                        <div className="form-check-inline mr-0">
                          <label className="form-check-label">
                            <input
                              checked={this.state.is_deposit == 0}
                              onChange={this.onChange}
                              value="0"
                              name="is_deposit"
                              id="user-update-is-deposit"
                              type="radio"
                              className="form-check-input"
                            />
                            Suspended
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="from-group">
                        <label className="d-block" htmlFor="is_withdrawal">
                          Withdrawal
                        </label>
                        <div className="form-check-inline mr-0">
                          <label className="form-check-label">
                            <input
                              checked={this.state.is_withdrawal == 1}
                              onChange={this.onChange}
                              value="1"
                              name="is_withdrawal"
                              type="radio"
                              className="form-check-input"
                              id="user-update-is_withdrawal"
                            />
                            Enabled
                          </label>
                        </div>
                        <div className="form-check-inline mr-0">
                          <label className="form-check-label">
                            <input
                              checked={this.state.is_withdrawal == 0}
                              onChange={this.onChange}
                              value="0"
                              name="is_withdrawal"
                              id="user-update-is_withdrawal"
                              type="radio"
                              className="form-check-input"
                            />
                            Suspended
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="from-group">
                        <label className="d-block" htmlFor="is_trade">
                          Trade
                        </label>
                        <div className="form-check-inline mr-0">
                          <label className="form-check-label">
                            <input
                              checked={this.state.is_trade == 1}
                              onChange={this.onChange}
                              value="1"
                              name="is_trade"
                              type="radio"
                              className="form-check-input"
                              id="user-update-is_trade"
                            />
                            Enabled
                          </label>
                        </div>
                        <div className="form-check-inline mr-0">
                          <label className="form-check-label">
                            <input
                              checked={this.state.is_trade == 0}
                              onChange={this.onChange}
                              value="0"
                              name="is_trade"
                              id="user-update-is_trade"
                              type="radio"
                              className="form-check-input"
                            />
                            Suspended
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="from-group">
                        <label className="d-block" htmlFor="is_show">
                          Is show
                        </label>
                        <div className="form-check-inline mr-0">
                          <label className="form-check-label">
                            <input
                              checked={this.state.is_show == 1}
                              onChange={this.onChange}
                              value="1"
                              name="is_show"
                              type="radio"
                              className="form-check-input"
                              id="user-update-is_show"
                            />
                            Enabled
                          </label>
                        </div>
                        <div className="form-check-inline mr-0">
                          <label className="form-check-label">
                            <input
                              checked={this.state.is_show == 0}
                              onChange={this.onChange}
                              value="0"
                              name="is_show"
                              id="user-update-is_show"
                              type="radio"
                              className="form-check-input"
                            />
                            Suspended
                          </label>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </form>
              </div>
              <div className="modal-footer">
                <button
                  form="update-user"
                  type="submit"
                  className="btn btn-primary"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ReferralUpdateModal.propTypes = {
  updateCrypto: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { updateRefreal })(
  withRouter(ReferralUpdateModal)
);