import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addPair } from "../../actions/pairActions";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import $ from "jquery";

import "react-toastify/dist/ReactToastify.css";

class PairAddModal extends React.Component {
  constructor() {
    super();
    this.state = {
      box1: "",
      box2: "",
      errors: {},
      cryptoList: [],
      secondCryptoList: [],
      isInput: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.cryptoList) {
      this.setState({
        cryptoList: nextProps.cryptoList,
      });
    }
    if (nextProps.secondCryptoList) {
      this.setState({
        secondCryptoList: nextProps.secondCryptoList,
      });
    }
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
    if (
      nextProps.pairdata !== undefined &&
      nextProps.pairdata.pair !== undefined &&
      nextProps.pairdata.pair.data !== undefined &&
      nextProps.pairdata.pair.data.message !== undefined
    ) {
      this.setState({
        errors: {},
        box1: "",
        box2: "",
        message: "",
      });
    }
  }

  onChange = (e) => {
    this.setState({ errors: {} });
    this.setState({ [e.target.id]: e.target.value });
    if (e.target.value == 7) {
      this.setState({ isInput: true });
    }
  };

  onPairAdd = (e) => {
    e.preventDefault();
    if (this.state.box1 === "") {
      this.setState({ errors: { box1: "This filed is required" } });
      return false;
    }
    if (this.state.box2 === "") {
      this.setState({ errors: { box2: "This filed is required" } });
      return false;
    }
    if (this.state.box2 == this.state.box1) {
      this.setState({
        errors: {
          box1: "Both currencies are same.",
          box2: "Both currencies are same.",
        },
      });
      return false;
    }
    let newPair;
    if (this.state.isInput === true) {
      newPair = {
        box1: this.state.box1,
        box2: this.state.box2,
        price: this.state.price,
        isInput: this.state.isInput,
      };
    } else {
      newPair = {
        box1: this.state.box1,
        box2: this.state.box2,
        isInput: this.state.isInput,
      };
    }
    this.props.addPair(newPair, this.props.history);
  };
  showHtml = () => {
    const html = [];
    this.state.cryptoList.map(function (value, i) {
      html.push(
        <>
          <option value={value.id}>{value.short_name}</option>
        </>
      );
    });
    return html;
  };
  showHtml1 = () => {
    const html = [];
    this.state.secondCryptoList.map(function (value, i) {
      html.push(
        <>
          <option value={value.id}>{value.short_name}</option>
        </>
      );
    });
    return html;
  };
  onPriceChange = (e) => {
    const { value } = e.target;
    this.setState({ price: value });
  };

  render() {
    const { errors } = this.state;
    return (
      <div>
        <div
          className="modal fade"
          id="add-user-modal"
          data-reset="true"
          data-backdrop="static"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Add Pair</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <form noValidate onSubmit={this.onPairAdd} id="add-user">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="box1">First Box</label>
                        <select
                          className="form-control"
                          id="box1"
                          value={this.state.box1}
                          error={errors.box1}
                          onChange={this.onChange}
                        >
                          <option value="">
                            Please select crypto currency
                          </option>
                          {this.showHtml()}
                        </select>
                        <span className="text-danger">{errors.box1}</span>
                        <span className="text-danger">{errors.message}</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="box2">Second Box</label>
                        <select
                          className="form-control"
                          id="box2"
                          value={this.state.box2}
                          error={errors.box2}
                          onChange={this.onChange}
                        >
                          <option value="">
                            Please select crypto currency
                          </option>
                          {this.showHtml1()}
                        </select>
                        <span className="text-danger">{errors.box2}</span>
                      </div>
                      {this.state.isInput === true ? (
                        <input
                          type="number"
                          value={this.state.price}
                          className="form-control"
                          placeholder="Enter pair price"
                          onChange={this.onPriceChange}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  form="add-user"
                  type="submit"
                  className="btn btn-primary"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PairAddModal.propTypes = {
  addPair: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  pairdata: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  pairdata: state.pairdata,
  errors: state.errors,
});

export default connect(mapStateToProps, { addPair })(withRouter(PairAddModal));
