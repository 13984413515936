import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Moment from "moment";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { baseUrl } from "../pages/baseUrl";

class UsersStakeDataModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "this.props.user.id",
      stakeData: [],
      dataStatus: false,
    };
    console.log("this.props", this.props);
  }

  componentWillReceiveProps(nextProps) {
    console.log("nextProps.currentRecordsddaad StakeUser", nextProps.stakeInfo);
    if (nextProps.stakeInfo) {
      this.setState({
        stakeData: nextProps.stakeInfo.data,
        dataStatus: nextProps.stakeInfo.status,
      });
    }
    if (nextProps.user) {
      this.setState({
        id: nextProps.user.id,
      });
    }
  }

  tableDataHtml = () => {
    const html = [];
    if (this.state.dataStatus == 200) {
      this.state.stakeData.map((value, i) => {
        console.log("sjhahdsghj", value);

        html.push(
          <tr>
            <td>{i + 1}</td>
            <td>{value.email}</td>
            <td>
              {value.locked_amount} {value.short_name}
            </td>
            <td>
              {value.current_amount} {value.short_name}
            </td>
            <td>{value.start_date}</td>
            <td> {value.end_date ? value.end_date : "-"}</td>
            {/* <td>{Moment(value.created_at).format("lll")}</td> */}
            <td>
              {value.status === "0" ? (
                <span style={{ color: "green" }}> Active </span>
              ) : (
                <span style={{ color: "red" }}> Closed </span>
              )}
            </td>
          </tr>
        );
      });
      return html;
    } else {
      html.push(
        <tr>
          <td style={{ textAlign: "center" }}> No Data </td>
        </tr>
      );
      return html;
    }
  };

  render() {
    const { errors } = this.state;
    return (
      <div>
        <div
          className="modal fade"
          id="update-stake-modal"
          data-reset="true"
          data-backdrop="static"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Stake Info</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <div className="portfolio-popup-outer d-flex flex-wrap justify-content-between align-items-center"></div>
                <table className="table w-100">
                  <thead>
                    <tr>
                      <th>Sr No.</th>
                      <th>User Email</th>
                      <th>Locked Amount</th>
                      <th>Current Amount</th>
                      <th>Start Date & Time</th>
                      <th>End Date & Time</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>{this.tableDataHtml()}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

UsersStakeDataModal.propTypes = {
  // userFundHistory: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps)(withRouter(UsersStakeDataModal));
