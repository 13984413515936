import React, { useState, useEffect, useRef } from 'react'
import { useLocation, useParams } from "react-router-dom";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import ReactDatatable from "@mkikets/react-datatable";
import Moment from "moment";
import axios from "axios"
import { toast, ToastContainer } from "react-toastify";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { baseUrl } from '../../actions/baseUrl';
import AddTermsAndCondition from '../../components/partials/AddTermsAndCondition'
import UpdateTermsAndConditionModal from '../../components/partials/UpdateTermsAndConditionModal'


const TermsCondition = () => {
    const fileref = useRef();
    const [inProgress, setInProgress] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [state, setState] = useState({ order_type: "sell", file: "", searchUser: "" })
    const [errors, setErrors] = useState({})
    const [record, setRecord] = useState([]);
    const [tempRecord, setTempRecord] = useState([]);
    const [selectedRecord, setSelectedRecord] = useState({});
    const [currentRecord, setCurrentRecord] = useState({})
    const [filters, setFilters] = useState({ type: "", language: "" })


    useEffect(() => {
        getPairData();
        //  getData()
        ///getCountry()
    }, [refresh])

    useEffect(() => {
        dataFilter();
        //  getData()
        ///getCountry()
    }, [filters,record])

    const getPairData = () => {
        axios
            .get(baseUrl + "/api/get_term_condition")
            .then(res => {
                console.log(res.data);
                setRecord(res.data.data);
                //setTempRecord(res.data.data);
            })
            .catch();
    }

    const config = {
        page_size: 10,
        length_menu: [10, 20, 50],
        filename: "Buy Order List",
        no_data_text: "No user found!",
        button: {
            print: true,
            csv: true,
        },
        language: {
            length_menu: "Show _MENU_ result per page",
            filter: "Filter in records...",
            info: "Showing _START_ to _END_ of _TOTAL_ records",
            pagination: {
                first: "First",
                previous: "Previous",
                next: "Next",
                last: "Last",
            },
        },
        show_length_menu: true,
        show_filter: true,
        show_pagination: true,
        show_info: true,
    };

    const columns = [
        {
            // key: "id",
            text: "#",
            className: "id",
            align: "left",
            sortable: true,
            cell: (row, index) => index + 1,
        },

        {
            key: "type",
            text: "Type",
            className: "title",
            align: "left",
            sortable: true,
            cell: (row, index) => { return (row.type == 1) ? "Terms & Condition" : (row.type == 2) ? "Privacy & Policy" : (row.type == 3) ? "Risk Statement" : "" },
        },

        {
            key: "title",
            text: "Title",
            className: "title",
            align: "left",
            sortable: true,
        },
        {
            key: "body",
            text: "Body",
            className: "title",
            align: "left",
            sortable: true,
        },
        {
            key: "language",
            text: "Language",
            className: "title",
            align: "left",
            sortable: true,
        },
        {
            key: "token_name",
            text: "Action",
            className: "title",
            align: "left",
            sortable: true,
            cell: record => {
                return (
                    <>

                        <button className="btn btn-primary btn-sm" style={{ marginRight: "5px", marginBottom: "5px" }} onClick={() => editRecord(record)} data-toggle="modal" data-target="#update-user-modal"> <i class="fa fa-edit" aria-hidden="true"></i> </button>
                        <button className="btn btn-danger btn-sm" onClick={() => confirm_delete_terms(record)}> <i class="fa fa-trash" aria-hidden="true"></i> </button>
                    </>
                );
            }
        }

    ];



    const confirm_delete_terms = (record) => {
        //  setSelectedRecord(record)
        console.log(record);
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Really want to Delete this record?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => { delete_terms(record) }
                },
                {
                    label: 'No'

                }
            ]
        });
    }

    const delete_terms = (record) => {

        axios.post(baseUrl + "/api/delete_term_condition", { id: record.sno }).then((res) => {
            console.log(res.data);
            if (res.data.status) {
                getPairData()
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    const editRecord = (record) => {
        setCurrentRecord({ title: record.title, body: record.body, language: record.language, sno: record.sno, type: record.type })
    }

    const handleChange = (e) => {

        const { name, value } = e.target;
        console.log(name);
        console.log(value);
        setFilters({ ...filters, [name]: value })

    }

    const dataFilter = () => {

        //console.log(filters);
        let allRecords = record.filter((val) => {
            // Check if all filters match the current record
            return Object.entries(filters).every(([key, value]) => (value == "") ? true : val[key] == value);
        });

        // for (const [key, value] of Object.entries(filters)) {
        //     console.log(key, value);

        //     // filters.map((ele)=>{
        //     //     console.log(ele);
        //     // })
        //     record.forEach((val) => {
        //         if (val[key] == value) {
        //             //  console.log(val);
        //             allRecords.push(val)
        //         }
        //     })
        // }
        setTempRecord(allRecords)

    }


    return (
        <div>
            <Navbar />
            <div className="" id="wrapper">
                <Sidebar />
                <AddTermsAndCondition setRefresh={setRefresh} refresh={refresh}/>
                <UpdateTermsAndConditionModal setRefresh={setRefresh} refresh={refresh} currentRecord={currentRecord} />
                <div id="page-content-wrapper">
                    <div className="container-fluid">
                        <button className="btn btn-link mt-3" id="menu-toggle">
                            <FontAwesomeIcon icon={faList} />
                        </button>

                        {/* <h4 className="mt-2 man_hadding mb-3">Token Detail</h4> */}
                        <div style={{ marginBottom: "10px" }}>
                            <div className='white_box'>
                                <div className="row" style={{ marginBottom: "10px" }}>
                                    <div className="col-md-12">
                                        <h4 className="mt-2 man_hadding mb-3">Services</h4>
                                    </div>
                                    <div className="col-md-3">
                                        <select onChange={(e) => handleChange(e)} value={filters.type} name="type" className="form-select w-100 form-control">
                                            <option value="">All Services</option>
                                            <option value="1">Terms & Condition</option>
                                            <option value="2">Privacy & Policy</option>
                                            <option value="3">Risk Statement</option>
                                        </select>
                                    </div>
                                    <div className="col-md-3">
                                        <select onChange={(e) => handleChange(e)} value={filters.language} name="language" className="form-select w-100 form-control">
                                            <option value="">Select</option>
                                            <option value="en">English</option>
                                            <option value="ko">Korean</option>
                                            <option value="rus">Russian</option>
                                            <option value="per">Persian</option>
                                            <option value="ar">Arabic</option>


                                        </select>

                                    </div>
                                    <div className="col-md-3">
                                    </div>
                                    <div className="col-md-3">
                                        <button className="btn btn-success mr-2" data-toggle="modal" data-target="#add-terms-modal">
                                            <i className="fa fa-plus" aria-hidden="true"></i> Add New
                                        </button>
                                    </div>
                                </div>
                                <div>
                                    <ReactDatatable
                                        config={config}
                                        records={tempRecord}
                                        columns={columns}

                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <ToastContainer />

                </div>
            </div>
        </div>
    )

}

export default TermsCondition