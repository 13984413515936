import axios from "axios";
import {
    GET_ERRORS,
    LOANTOVALUE_ADD,
    LOANTOVALUE_UPDATE
} from "./types";
import * as myConstList from "./baseUrl"

const baseUrl = myConstList.baseUrl;

export const addLoantovalue = (loantovalueData, history) => dispatch => {
    axios
        .post(baseUrl + "/api/loan-to-value-add", loantovalueData)
        .then(res =>
            dispatch({
                type: LOANTOVALUE_ADD,
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    );
};


export const updateLoantovalue = (loantovalueData) => dispatch => {
    axios
        .post(baseUrl + "/api/loan-to-value-update", loantovalueData)
        .then(res =>
            dispatch({
                type: LOANTOVALUE_UPDATE,
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    );
};
