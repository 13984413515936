const isEmpty = require("is-empty");
const initialState = {
  isAuthenticated: false,
  stacking: {},
  loading: false,
};
export default function (state = initialState, action) {
  switch (action.type) {
    case "STACK_ADD":
      return {
        isAuthenticated: !isEmpty(action.payload),
        stacking: action.payload,
      };

   
    case "GET_ERRORS":
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        role: action.payload,
      };
    
    default:
      return state;
  }
}
