import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { newCommission } from "../../actions/CommissionActions";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import $ from "jquery";

import "react-toastify/dist/ReactToastify.css";
import { Alert } from "bootstrap";
import { clearErrors } from "../../actions/cryptoActions";

class CommissionAddModal extends React.Component {
  constructor() {
    super();
    this.state = {
      currency: "",
      buyCommission: "",
      sellCommission: "",
      errors: {},
      cryptoList: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.cryptoList) {
      this.setState({
        cryptoList: nextProps.cryptoList,
      });
    }
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
    if (
      nextProps.comdata !== undefined &&
      nextProps.comdata.commission !== undefined &&
      nextProps.comdata.commission.data !== undefined &&
      nextProps.comdata.commission.data.message !== undefined &&
      nextProps.comdata.commission.data.success
    ) {
      this.setState({
        errors: {},
        currency: "",
        buyCommission: "",
        sellCommission: "",
      });
    }
  }

  onChange = (e) => {
    // this.setState({
    //   errors: {
    //     currency: "",
    //     buyCommission: "",
    //     sellCommission: "",
    //   },
    // });
    this.setState({ [e.target.id]: e.target.value });
    if (e.target.id === "currency") {
      if (!e.target.value) {
        this.setState({
          errors: {
            currency: "This field is required",
          },
        });
        return false;
      }
      this.setState({
        errors: {
          currency: "",
        },
      });
    }
    if (e.target.id === "buyCommission") {
      if (!e.target.value) {
        this.setState({
          errors: {
            buyCommission: "This field is required",
          },
        });
        return false;
      }
      this.setState({
        errors: {
          buyCommission: "",
        },
      });
    }
    if (e.target.id === "sellCommission") {
      if (!e.target.value) {
        this.setState({
          errors: {
            sellCommission: "This field is required",
          },
        });
        return false;
      }
      this.setState({
        errors: {
          sellCommission: "",
        },
      });
    }
    if (e.target.id === "type") {
      if (!e.target.value) {
        this.setState({
          errors: {
            minWithdrawal: "This field is required",
          },
        });
        return false;
      }
      this.setState({
        errors: {
          minWithdrawal: "",
        },
      });
    }
  };

  onCountryAdd = (e) => {
    e.preventDefault();
    if (!this.state.currency) {
      this.setState({
        errors: {
          currency: "This field is required",
        },
      });
      return false;
    }
    if (!this.state.buyCommission) {
      this.setState({
        errors: {
          buyCommission: "This field is required",
        },
      });
      return false;
    }
    if (!this.state.sellCommission) {
      this.setState({
        errors: {
          sellCommission: "This field is required",
        },
      });
      return false;
    }
    if (!this.state.type) {
      this.setState({
        errors: {
          minWithdrawal: "This field is required",
        },
      });
      return false;
    }
    const newCountry = {
      currency: this.state.currency,
      buyCommission: this.state.buyCommission,
      sellCommission: this.state.sellCommission,
      type: this.state.type,
    };
    this.props.newCommission(newCountry, this.props.history);
  };
  showHtml = () => {
    const html = [];
    this.state.cryptoList.map(function (value, i) {
      html.push(
        <>
          <option value={value.id}>{value.name1 + "/" + value.name2}</option>
        </>
      );
    });
    return html;
  };
  clearProps = () => {
    // Clear errors when the component is about to unmount
    this.props.clearErrors();
  };
  render() {
    const { errors } = this.state;
    return (
      <div>
        <div
          className="modal fade"
          id="add-user-modal"
          data-reset="true"
          data-backdrop="static"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Add Commission</h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  onClick={this.clearProps}
                >
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <form noValidate onSubmit={this.onCountryAdd} id="add-user">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="name">Pair Name</label>
                        <select
                          className="form-control"
                          id="currency"
                          value={this.state.currency}
                          error={errors.currency}
                          onChange={this.onChange}
                        >
                          <option value="">
                            Please select crypto currency
                          </option>
                          {this.showHtml()}
                        </select>
                        <span className="text-danger">{errors.currency}</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="buyCommission">Buy Commission</label>
                        <input
                          onChange={this.onChange}
                          value={this.state.buyCommission}
                          id="buyCommission"
                          type="text"
                          error={errors.buyCommission}
                          onKeyPress={(event) => {
                            if (!/[0-9.]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          className={classnames("form-control", {
                            invalid: errors.buyCommission,
                          })}
                        />
                        <span className="text-danger">
                          {errors.buyCommission}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="sellCommission">Sell Commission</label>
                        <input
                          onChange={this.onChange}
                          value={this.state.sellCommission}
                          id="sellCommission"
                          type="number"
                          onKeyPress={(event) => {
                            if (!/[0-9.]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          error={errors.sellCommission}
                          className={classnames("form-control", {
                            invalid: errors.sellCommission,
                          })}
                        />
                        <span className="text-danger">
                          {errors.sellCommission}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="minWithdrawal">Fees </label>
                        <select
                          className="form-control"
                          onChange={this.onChange}
                          value={this.state.type}
                          id="type"
                        >
                          <option value="">Select</option>
                          {/* <option value="flat">Flat</option> */}
                          <option value="percentage">Percentage</option>
                        </select>
                        <span className="text-danger">
                          {errors.minWithdrawal}
                        </span>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  form="add-user"
                  type="submit"
                  className="btn btn-primary"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CommissionAddModal.propTypes = {
  newCommission: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  comdata: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  comdata: state.comdata,
  errors: state.errors,
});

export default connect(mapStateToProps, { newCommission, clearErrors })(
  withRouter(CommissionAddModal)
);
