import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { verifyStatus } from "../../../actions/userActions";
import RejectButtonFi from "./RejectButtonFi";
import { withRouter } from "react-router-dom";
import axios from "axios";
import $ from "jquery";
import { Link } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import { Alert } from "bootstrap";
import * as myConctList from "../../../actions/baseUrl";

const baseUrl = myConctList.baseUrl;
class VerifyDocument1 extends React.Component {
  constructor(props) {
    super(props);
    const { user } = this.props.auth;
    this.state = {
      records: [],
      id: "",
      i_image: "",
      identity_status: "",
      reject: "",
      errors: {},
    };
  }

  componentWillReceiveProps(nextProps) {
    {
      console.log("propsprops", nextProps.record);
    }
    if (nextProps.record) {
      if (nextProps.record.lastName != 'null') {
        this.setState({
          Name: nextProps.record.firstName + " " + nextProps.record.lastName,
          dob: nextProps.record.dob,
          Address: nextProps.record.line1,
          city: nextProps.record.city,
          state: nextProps.record.states,
          country: nextProps.record.r_country,
          zipcode: nextProps.record.zipcode,
        });
      } else {
        this.setState({
          Name: nextProps.record.firstName,
          dob: nextProps.record.dob,
          Address: nextProps.record.line1,
          city: nextProps.record.city,
          state: nextProps.record.states,
          country: nextProps.record.r_country,
          zipcode: nextProps.record.zipcode,
        });
      }
    }
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
    if (nextProps.userrecords) {
      this.setState({
        tstatus: nextProps.userrecords.status,
      });
    }
  }
  getNodesToRemoveFromElement = (stringContent) => {
    var div = document.createElement("div");
    div.innerHTML = stringContent;
    var text = div.textContent || div.innerText || "";
    return text;
  };

  handleCommentChange = (event, editor) => {
    var data = editor.getData();
    if (data) {
      this.setState({ description: data });
    }
    this.setState({
      showResults: this.state.name === null ? false : true,
    });
  };

  onUpdate = (e) => {
    e.preventDefault();

    const newUser = {
      _id: this.props.match.params.id,
      type: "d1",
    };
    this.props.verifyStatus(newUser);
  };
  onChange = (e) => {
    if (e.target.id === "reject") {
      this.setState({ reject: e.target.value });
    }
  };

  render() {
    const { errors } = this.state;
    const { user } = this.state;
    return (
      <>
        {this.state.identity_status !== undefined && (
          <>
            <div className="in_page">
                <div className="user-personal">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="user-detail-inner-main d-flex align-items-center">
                        <h5 htmlFor="BankName">Name : </h5>
                        <p>{this.state.Name}</p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="user-detail-inner-main d-flex align-items-center">
                        <h5 htmlFor="BranchName">DOB :</h5>
                        <p>{this.state.dob}</p>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="user-detail-inner-main d-flex align-items-center">
                        <h5 htmlFor="AccNum">Address :</h5>
                        <p>{this.state.Address}</p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="user-detail-inner-main d-flex align-items-center">
                        <h5 htmlFor="AccHolder">City :</h5>
                        <p>{this.state.city}</p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="user-detail-inner-main d-flex align-items-center">
                        <h5 htmlFor="AccHolder">State :</h5>
                        <p>{this.state.state}</p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="user-detail-inner-main d-flex align-items-center">
                        <h5 htmlFor="AccHolder">Country :</h5>
                        <p>{this.state.country}</p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="user-detail-inner-main d-flex align-items-center">
                        <h5 htmlFor="AccHolder">Zipcode :</h5>
                        <p>{this.state.zipcode}</p>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

VerifyDocument1.propTypes = {
  verifyStatus: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { verifyStatus })(
  withRouter(VerifyDocument1)
);
