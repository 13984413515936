import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updatePair } from "../../actions/pairActions";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';

import 'react-toastify/dist/ReactToastify.css';

class PairUpdateModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      id: this.props.record.id,
      box1: this.props.record.coin_first_id,
      box2: this.props.record.coin_second_id,
      errors: {},
      cryptoList: [],
      secondCryptoList:[]
    };
  }

  componentWillReceiveProps(nextProps) {

   console.log("nexprops",nextProps)
    if (nextProps.record) {
      this.setState({
        id: nextProps.record.id,
        box1: nextProps.record.coin_first_id,
        box2: nextProps.record.coin_second_id,
        


      })
    }
    if(nextProps.cryptoList){
  this.setState({cryptoList: nextProps.cryptoList,})
    }
    if(nextProps.secondCryptoList){
  this.setState({ secondCryptoList:nextProps.secondCryptoList})
    }
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
    if (nextProps.pairdata !== undefined
      && nextProps.pairdata.pair !== undefined
      && nextProps.pairdata.pair.data !== undefined
      && nextProps.pairdata.pair.data.message !== undefined) {
      this.setState({
        errors: {}
      });
    }

  }

  onChange = e => {
    this.setState({ errors: {} });
    if (e.target.id === 'user-update-box1') {
      this.setState({ box1: e.target.value });
    }
    if (e.target.id === 'user-update-box2') {
      this.setState({ box2: e.target.value });
    }

  };

  onPairUpdate = e => {

    e.preventDefault();
    const newPair = {
      _id: this.state.id,
      box1: this.state.box1,
      box2: this.state.box2
    };
    
    this.props.updatePair(newPair);
  };
  showHtml = () => {
    const html = []
    this.state.cryptoList.map(function (value, i) {
      html.push(
        <>
          <option value={value.id}>{value.short_name}</option>
        </>
      );

    })
    return html;
  }
  render() {
    const { errors } = this.state;
    return (
      <div>
        <div className="modal fade" id="update-user-modal" data-backdrop="static">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Update Pair</h4>
                <button type="button" className="close" data-dismiss="modal">&times;</button>
              </div>
              <div className="modal-body">
                <form noValidate onSubmit={this.onPairUpdate} id="update-user">
                  <input
                    onChange={this.onChange}
                    value={this.state.id}
                    id="user-update-id"
                    type="text"
                    className="d-none" />
                  <div className="row mt-2">
                    <div className="col-md-6">
                      <div className='form-group'>
                        <label htmlFor="box1">First Box</label>
                        <select className="form-control" id="user-update-box1" value={this.state.box1} error={errors.box1} onChange={this.onChange}>

                          {this.showHtml()}
                        </select>

                        <span className="text-danger">{errors.box1}</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className='form-group'>
                        <label htmlFor="box2">Second Box</label>
                        <select className="form-control" id="user-update-box2" value={this.state.box2} error={errors.box2} onChange={this.onChange}>

                          {this.showHtml()}
                        </select>

                        <span className="text-danger">{errors.box2}</span>
                      </div>
                    </div>
                  </div>

                </form>
              </div>
              <div className="modal-footer">
                <button
                  form="update-user"
                  type="submit"
                  className="btn btn-primary">
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

PairUpdateModal.propTypes = {
  updatePair: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  pairdata: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  pairdata: state.pairdata,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { updatePair }
)(withRouter(PairUpdateModal));
