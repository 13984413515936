import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { verifyStatus } from "../../../actions/userActions";
import { withRouter } from "react-router-dom";
import RejectButtonAdd from "./RejectButtonAdd";
import axios from "axios";
import $ from 'jquery';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Modal, Button } from "react-bootstrap";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { baseUrl } from "../../../actions/baseUrl"
class VerifyDocument3 extends React.Component {

    constructor(props) {
        super(props);
        const { user } = this.props.auth;
        this.state = {
            records: [],
            i_image3: '',
            image3_status: '',
            type: 23,
            reject: '',
            errors: {},
            id: props.record._id,
            i_image3: props.record.i_image3,
            image3_status: props.record.image3_status,
            reject: props.record.d3_rejectResion,
            records: props.record,
            pan_number: props.record.pan_number
        };

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.record) {
            this.setState({
                id: nextProps.record._id,
                i_image3: nextProps.record.i_image3,
                image3_status: nextProps.record.image3_status,
                reject: nextProps.record.d3_rejectResion,
                records: nextProps.record,
                pan_number: nextProps.record.pan_number
            });
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.userrecords) {
            this.setState({
                tstatus: nextProps.userrecords.status
            });
        }

        // if (nextProps.auth !== undefined
        //     && nextProps.auth.user !== undefined
        //     && nextProps.auth.user.data !== undefined
        //     && nextProps.auth.user.data.message4 !== undefined
        //     && nextProps.auth.user.data.success) {

        //     toast(nextProps.auth.user.data.message4, {
        //         position: toast.POSITION.TOP_CENTER
        //     });
        // }
    }
    getNodesToRemoveFromElement = (stringContent) => {

        var div = document.createElement("div");
        div.innerHTML = stringContent;
        var text = div.textContent || div.innerText || "";
        return text;
    };

    onChange = e => {
        if (e.target.id === 'reject') {
            this.setState({ reject: e.target.value });
        }
    };
    handleCommentChange = (event, editor) => {

        var data = editor.getData();
        if (data) {
            this.setState({ description: data });
        }
        this.setState({
            showResults: this.state.name === null ? false : true
        })
    };
    getData() {
        const { user } = this.props.auth;
        axios
            .post(baseUrl + "/api/rejectReason-By-Id-Data", { _id: this.props.match.params.id })
            .then(res => {
                this.setState({
                    d1: res.data.d1_rejectResion,
                    d2: res.data.d2_rejectResion,
                    d3: res.data.d3_rejectResion,
                    d4: res.data.d4_rejectResion
                }, () => {
                    this.setState({ isKycModalOpen: true })
                });
                console.log("res.datares.data", res.data)

            })
            .catch()
    }
    type = () => {
        alert("add");
        this.setState({ catType: "add" });
    };
    onUpdate = e => {
        e.preventDefault();
        const newUser = {
            _id: this.props.match.params.id,
            type: 'd3'
        };
        this.props.verifyStatus(newUser);
    };

    closeModel = () => {
        this.setState({ isKycModalOpen: false })
    }

    render() {
        const { errors } = this.state;
        const { user } = this.state;
        return (
            <>

                {this.state.image3_status !== undefined &&
                    <>
                        <form noValidate onSubmit={this.onUpdate} id="update-user">
                            <p><b>Pan Identity :</b> {this.state.image3_status == 1 ? "pending" : (this.state.image3_status == 2 ? "Verified" :
                                <button
                                    onClick={(e) => {
                                        e.preventDefault()
                                        this.getData()

                                    }}
                                    // to={
                                    //   "/reject-reason/" + this.props.match.params.id + "/" + 22
                                    // }
                                    type="button"
                                    className="btn btn_man"
                                >
                                    Rejected <i className="fa fa-info-circle"></i>
                                </button>)}</p>
                            <a href={baseUrl + "/static/upload/" + this.state.i_image3} target="_blank"><img src={baseUrl + "/static/upload/" + this.state.i_image3} className="img-thumbnail" alt="Cinque Terre"></img></a>
                            {this.props.match.params.type == 2 &&
                                <div style={{ display: (this.state.image3_status !== '2' && this.state.image3_status !== '3' ? 'block' : 'none') }}>
                                    <button type="submit" className='btn btn-primary'>Verify</button>
                                </div>
                            }
                        </form>
                        {/* {this.props.match.params.type == 2 && this.state.image3_status !== '2' ? */}

                        <div style={{ display: (this.state.image3_status !== '2' && this.state.image3_status !== '3' ? 'block' : 'none') }}>
                            <RejectButtonAdd record={this.state.records} docStatus={this.state.image3_status} rejectStatus={this.state.reject} />

                        </div>
                        <hr />
                        <Modal
                            show={this.state.isKycModalOpen}
                            onHide={this.closeKycModal}
                            backdrop="static"
                            keyboard={false}
                        >
                            <Modal.Body>
                                <div className="kyc_popop tc">
                                    <h2 ref={(subtitle) => (subtitle = subtitle)}>
                                        <h3 className="man_hadding">Reject Reason</h3>
                                        {this.state.type == 21 &&
                                            <p className="rejectReason">{this.state.d1}</p>
                                        }
                                        {this.state.type == 22 &&
                                            <p className="rejectReason">{this.state.d2}</p>
                                        }
                                        {this.state.type == 23 &&
                                            <p className="rejectReason">{this.state.d3}</p>
                                        }
                                        {this.state.type == 24 &&
                                            <p className="rejectReason">{this.state.d4}</p>
                                        }
                                    </h2>
                                    <div>
                                        <button className="w100px" onClick={this.closeModel}>
                                            Okay!
                                        </button>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </>
                }
            </>
        )
    }
}

VerifyDocument3.propTypes = {
    verifyStatus: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { verifyStatus }
)(withRouter(VerifyDocument3));
