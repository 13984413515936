import React, { useState, useEffect } from 'react'
import { useLocation, useParams } from "react-router-dom";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import ReactDatatable from "@mkikets/react-datatable";
import Moment from "moment";
import axios from "axios"
import { Modal } from "react-bootstrap";
import AddBlogModal from '../partials/AddBlogModal';
import AddMarketTokenModal from '../partials/AddMarketTokenModal';
import { toast, ToastContainer } from "react-toastify";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { baseUrl } from '../../actions/baseUrl';
import ConfirmModal from '../partials/ConfirmModal';

function TokenDetail() {
    const { tokenId } = useParams();
    // const search = useLocation().search;
    console.log(tokenId);
    // const id = new URLSearchParams(search).get("tokenId");
    //  console.log(id);

    const [refresh, setRefresh] = useState(false)
    const [currentRecord, setCurrentRecord] = useState({})
    const [record, setRecord] = useState([])
    const [tokenDetail, setTokenDetail] = useState({})
    const [image, setImage] = useState("")
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [countryList, setCountryList] = useState([])
    const [reason, setReason] = useState('');
    const [errors, setErrors] = useState({ reason: '' });
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        getData(tokenId)
        ///getCountry()
    }, [refresh])

    const config = {
        page_size: 10,
        length_menu: [10, 20, 50],
        filename: "Buy Order List",
        no_data_text: "No user found!",
        button: {
            print: true,
            csv: true,
        },
        language: {
            length_menu: "Show _MENU_ result per page",
            filter: "Filter in records...",
            info: "Showing _START_ to _END_ of _TOTAL_ records",
            pagination: {
                first: "First",
                previous: "Previous",
                next: "Next",
                last: "Last",
            },
        },
        show_length_menu: true,
        show_filter: true,
        show_pagination: true,
        show_info: true,
    };

    const columns = [
        {
            // key: "id",
            text: "#",
            className: "id",
            align: "left",
            sortable: true,
            cell: (row, index) => index + 1,
        },

        {
            key: "user_email",
            text: "Email",
            className: "title",
            align: "left",
            sortable: true,
        },
        {
            key: "token_name",
            text: "Token",
            className: "title",
            align: "left",
            sortable: true,
        },
        // {
        //     key: "token_address",
        //     text: "Token Address",
        //     className: "title",
        //     align: "left",
        //     sortable: true,
        // },
        {
            key: "quantity",
            text: "Quantity",
            className: "title",
            align: "left",
            sortable: true,
        },
        {
            key: "status",
            text: "Status",
            className: "title",
            align: "left",
            sortable: true,
        },
        // {
        //     key: "rejection_reasion",
        //     text: "Reason",
        //     className: "title",
        //     align: "left",
        //     sortable: true,
        // },
        {
            key: "date",
            text: "Date of Live",
            className: "title",
            align: "left",
            sortable: true,
            cell: record => {
                return (
                    <>
                        {Moment.utc(record.date).local().format("lll")}
                    </>
                );
            }
        },

        {
            key: "action",
            text: "Action",
            className: "action",
            width: 100,
            align: "left",
            sortable: false,
            cell: record => {
                return (
                    <>
                        {(record.status == "pending") ?
                            <>
                                <button
                                    className="btn btn-primary btn-sm"
                                    onClick={() => editRecord(record, true)}
                                    style={{ marginRight: '5px' }}>
                                    <i className="fa fa-check"></i>
                                </button>
                                <button
                                    data-toggle="modal"
                                    data-target="#add-user-modal"
                                    className="btn btn-danger btn-sm"
                                    onClick={() => editRecord(record, false)}>
                                    <i className="fa fa-times"></i>
                                </button>
                            </>

                            : <></>
                        }
                    </>
                );
            }
        }

    ];

    const viewData = (record) => {

    }
    const editRecord = (record, status) => {
        record.status_action = status;
        if (!status) {
            setCurrentRecord(record)
        } else {
            confirmAlert({
                title: 'Action Confirm',
                message: 'Really want to Accept ?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => { deleteRecord(record) }
                    },
                    {
                        label: 'No'

                    }
                ]
            });

            // deleteRecord(record)
        }

    }

    const deleteRecordMgs = (record) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>Action Confirm ?</h1>
                        <p>Please provide a reason for this action:</p>
                        <input
                            type="text"
                            value={reason}
                            onChange={(e) => setReason(e.target.value)}
                            placeholder="Enter your reason"
                        />
                        <span>{errors.reason}</span>
                        <button
                            className='btn btn-primary btn-sm'
                            onClick={() => {
                                if (reason.trim() === '') {
                                    alert('Reason is required');
                                    return;
                                    //  setErrors({...errors,reason:'Reason is required'});
                                    // return;
                                }
                                // Handle submission logic
                                console.log('Reason:', reason);
                                onClose();
                            }}
                        >
                            Confirm
                        </button>
                        <button className='btn btn-secondary btn-sm' onClick={onClose}>Cancel</button>
                    </div>
                );
            }
        })
    }

    const deleteRecord = (record) => {

        axios
            .post(baseUrl + "/api/token_transaction_action", record)
            .then(res => {
                if (res.status === 200) {
                    getData(tokenId)
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })

                }
            })
            .catch();

    }



    const getData = (id) => {
        axios.get(baseUrl + "/api/get_token_detail/" + id).then((res) => {
            if (res.data.status) {
                setTokenDetail(res.data.data.token_detail)
                setRecord(res.data.data.listing)
            }else{
                toast("Invalid Token Request", {
                    position: toast.POSITION.TOP_CENTER,
                })
            }

        }).catch((err) => {

        })
    }

    const getCountry = () => {
        console.log("getCountry");
        axios.get(baseUrl + "/api/get_country").then((res) => {
            if (res.data.status) {
                setCountryList(res.data.data)
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div>
            <Navbar />
            <div className="" id="wrapper">
                <Sidebar />
                <ConfirmModal setRefresh={setRefresh} type={reason} currentRecord={currentRecord} countryList={countryList} />
                <div id="page-content-wrapper">
                
                {(Object.keys(tokenDetail).length)?
                    <div className="container-fluid">
                        <button className="btn btn-link mt-3" id="menu-toggle">
                            <FontAwesomeIcon icon={faList} />
                        </button>

                        {/* <h4 className="mt-2 man_hadding mb-3">Token Detail</h4> */}
 
                        <div className='white_box'>
                            <div style={{"marginBottom":"20px"}}>
                                <div className="row">
                                    <div className="col-md-2 col-lg-2">
                                        <img
                                            src={baseUrl + "/static/market_token/" + tokenDetail.symbol}
                                            className=""
                                            style={{ width: '150px' }} />
                                    </div>
                                    <div className="col-md-8 col-lg-8">
                                        <h5> Name: {tokenDetail.name}</h5>
                                        <h5>Price: {tokenDetail.price}</h5>
                                    </div>
                                </div>
                            </div>
                            {/* <button className="btn btn-primary float-right" data-toggle="modal" data-target="#add-user-modal"><FontAwesomeIcon icon={faPlus} /> Add </button> */}
                            <ReactDatatable
                                config={config}
                                records={record}
                                columns={columns}

                            />
                        </div>
                       
                    </div>
                :<div>Token Not found</div>}
                    <ToastContainer />

                </div>
            </div>
        </div>
    )

}

export default TokenDetail