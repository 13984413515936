import { fas } from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import { baseUrl } from "../pages/baseUrl";
import axios from "axios";
import $ from "jquery";

function AddFrontTextModal(props) {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [titleErr, setTitleErr] = useState("");
  const [descriptionErr, setDescriptionErr] = useState("");
  const [language, setLanguage] = useState("en");
  const [imageErr, setImageErr] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    //console.log(name)
    if (name === "title") {
      setTitle(value);
      if (value == "") {
        setTitleErr("This field is required");
        return false;
      }
      setTitleErr("");
    }
    if (name === "description") {
      setDescription(value);
      if (value === "") {
        setDescriptionErr("This field is required");
        return false;
      }
      setDescriptionErr("");
    }

    if (name === "image") {
      setImage(value);
      if (value === "") {
        setImageErr("This field is required");
        return false;
      }
      setImageErr("");
    }
  };

  const handleFileChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (title === "") {
      setTitleErr("This field is required");
      return false;
    }
    if (description === "") {
      setDescriptionErr("This field is required");
      return false;
    }
    if (image === "") {
      setImageErr("This field is required");
      return false;
    }
    //console.log('image--->',image);
    const formData = new FormData();
    formData.append("title", title);
    formData.append("lang", language);
    formData.append("description", description);
    formData.append("image", image);
    //console.log('formData--->',formData);
    axios
      .post(baseUrl + "/api/add_front_text", formData)
      .then((res) => {
        if (res.data) {
          props.setRefresh(!props.refresh);
          $("#add-user-modal").modal("hide");
          setTitle("");
          setDescription("");
          setImage("");
          setLanguage("en");
        }
      })
      .catch((err) => {});
  };
  const onChange = (e) => {
    setLanguage(e.target.value);
  };

  const closeModal = () => {
    setImage("");
    setTitle("");
    setDescription("");
    setTitleErr("");
    setDescriptionErr("");
    setImageErr("");
  };

  return (
    <div>
      <div className="modal fade" id="add-user-modal">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Add Front Text</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={closeModal}
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              <form id="update-user" onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label className="form-label">Language:</label>
                  <select
                    onChange={onChange}
                    value={language}
                    name="lang"
                    className="form-select w-100 form-control"
                  >
                    <option value="en">English</option>
                    <option value="ko">Korean</option>
                    <option value="rus">Russian</option>
                    <option value="per">Persian</option>
                    <option value="ar">Arabic</option>
                  </select>
                </div>
                <div className="mb-3">
                  <label className="form-label">Title:</label>
                  <textarea
                    name="title"
                    value={title}
                    onChange={handleChange}
                    className="form-control"
                  />
                  <span style={{ color: "red" }}>{titleErr}</span>
                </div>

                <div className="mb-3">
                  <label className="form-label">Description:</label>
                  <textarea
                    name="description"
                    value={description}
                    onChange={handleChange}
                    className="form-control"
                  />
                  <span style={{ color: "red" }}>{descriptionErr}</span>
                </div>

                <div className="mb-3">
                  <label className="form-label">Select Image:</label>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    className="form-control"
                  />
                  <span style={{ color: "red" }}>{imageErr}</span>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={closeModal}
              >
                Close
              </button>
              <button
                form="update-user"
                type="submit"
                className="btn btn-primary"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddFrontTextModal;
