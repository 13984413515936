import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import EditContestRecord from "../partials/EditContestRecord";
import { toast, ToastContainer} from "react-toastify";
import * as myConstList from "../../actions/baseUrl";

const baseUrl = myConstList.baseUrl
class EditContest extends Component {

    constructor(props) {
        super(props);
        this.state = {
            getalls: [],
            contestId:this.props.match.params.id
        };
       this.getData = this.getData.bind(this);
    }

   getData() {
      
        axios
            .post(baseUrl + "/api/contestOne-data", { _id:this.state.contestId})
            .then(res => {
                this.setState({getalls: res.data});
              
            })
            .catch()
    }
    componentDidMount() {
        this.getData()
     
    };

    componentWillReceiveProps(nextProps) {
        this.getData();
    }
    pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }

    render() {
        return (
            <>
               <div>
                <Navbar/>
                <div className="d-flex" id="wrapper">
                    <Sidebar/>
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <button className="btn btn-link mt-3" id="menu-toggle"><FontAwesomeIcon icon={faList}/></button>
                            <h4 className="mt-2 text-primary">Edit Contest</h4>
                            <EditContestRecord getall={this.state.getalls}/>
                        </div>
                    </div>
                 <ToastContainer/>
                </div>
            </div>
            </>

        );
    }

}

EditContest.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    getalls: state.getalls
});

export default connect(
    mapStateToProps
)(EditContest);
