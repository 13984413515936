const isEmpty = require("is-empty");
const initialState = {
    isAuthenticated: false,
    commission: {},
    loading: false
};
export default function(state = initialState, action) {
    switch (action.type) {

        case 'COM_ADD':
        return {
                isAuthenticated: !isEmpty(action.payload),
                commission: action.payload
        };

        case 'COM_UPDATE':
            return {
                isAuthenticated: !isEmpty(action.payload),
                commission: action.payload,
            };
      
        default:
            return state;
    }
}