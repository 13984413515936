import axios from "axios";
import {
    GET_ERRORS,
    USER_ADD,
    USER_UPDATE
} from "./types";
import {baseUrl} from "./baseUrl";

export const addBOT = (botData, history) => dispatch => {
    axios
        .post(baseUrl +"/api/bot-add", botData)
        .then(res =>
            dispatch({
                type: "BOT_ADD",
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    );
};

export const updateBOT = (botData) => dispatch => {
    axios
        .post(baseUrl +"/api/bot-update", botData)
        .then(res =>
            dispatch({
                type: "BOT_UPDATE",
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    );
};
