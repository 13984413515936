import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from "@mkikets/react-datatable";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import StackingAdd from "../partials/StackingAdd";
import { toast, ToastContainer } from "react-toastify";
import * as myConstList from "../../actions/baseUrl";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import $, { data } from "jquery";
import UsersStakeDataModal from "../partials/UsersStakeDataModal";
const baseUrl = myConstList.baseUrl;
class Stacking extends Component {
  constructor(props) {
    super(props);
    this.state={
      disable:false
    }
    this.columns = [
      {
        key: "#",
        text: "Id",
        className: "id",
        align: "left",
        sortable: true,
        cell: (row, index) => index + 1,
      },
      {
        key: "id",
        text: "Stake Id",
        className: "id",
        align: "left",
        sortable: true,
        // cell: (row, index) => index + 1,
      },
      {
        key: "short_name",
        text: "Currency Pairs",
        className: "name",
        align: "left",
        sortable: true,
        cell: (record) => {
          return (
            <>
              <span>{record.short_name} </span>
            </>
          );
        },
      },
      {
        key: "estimate",
        text: "Est. APR (%)",
        className: "name",
        align: "left",
        sortable: true,
        cell: (record) => {
          return (
            <>
              <span>{record.estimate} %</span>
            </>
          );
        },
      },

      {
        key: "type",
        text: "Type",
        className: "name",
        align: "left",
        sortable: true,
        cell: (record) => {
          return (
            <>
              <span>{record.type=="Days"?"Fixed":record.type}</span>
            </>
          );
        },
      },
      {
        key: "duration",
        text: "Duration (Days)",
        className: "name",
        align: "left",
        sortable: true,
        cell: (record) => {
          return (
            <>
              <span>
                {record.duration + "(Days)"}
                {/* {record.type === "Flexible" ? "-" : record.duration + "(Days)"} */}
              </span>
            </>
          );
        },
      },
      {
        key: "lock_amount",
        text: "Min Locked Amount",
        className: "name",
        align: "left",
        sortable: true,
        cell: (record) => {
          return (
            <Fragment>
              {record.lock_amount} {record.short_name}
            </Fragment>
          );
        },
      },
      {
        key: "max_lock_amount",
        text: "Max Locked Amount",
        className: "name",
        align: "left",
        sortable: true,
        cell: (record) => {
          return (
            <Fragment>
              {record.max_lock_amount} {record.short_name}
            </Fragment>
          );
        },
      },
      // {
      //   key: "view",
      //   text: "View",
      //   className: "view",
      //   width: 100,
      //   align: "left",
      //   sortable: false,
      //   cell: (record) => {
      //     return (
      //       <Fragment>
      //         <button
      //           // className="btn btn-primary"
      //           // onClick={() => this.deleteRecordMgs(record)}
      //           data-toggle="modal"
      //           data-target="#update-stake-modal"
      //           className="btn btn-primary btn-sm mr-2"
      //           onClick={() => this.userStakeApi(record)}
      //           title="Stake Info"
      //         >
      //           View
      //         </button>
      //       </Fragment>
      //     );
      //   },
      // },
      {
        key: "action",
        text: "Action",
        className: "action",
        width: 100,
        align: "left",
        sortable: false,
        cell: (record) => {
          return (
            <Fragment>
              <button
                data-toggle="modal"
                data-target="#update-stake-modal"
                className="btn btn-primary btn-sm mr-2"
                onClick={() => this.userStakeApi(record)}
                title="Stake Info"
              >
                View
              </button>

              {/* {record.is_active == 1 ? (
                "Blocked"
              ) : (
                <button
                  className="btn btn-danger btn-sm"
                  title={"Block Stake"}
                  onClick={() => this.deleteRecordMgs(record)}
                  disabled={this.state.disable}
                >
                  {/* <i class="fa fa-lock"></i> *
                  Block
                </button>
              )} */}
{" "}
              {record.is_active == 0 || record.is_show == 0? (
                <>
                  {record.is_show == 1 ? (
                    <button
                      className="btn btn-danger btn-sm"
                      title={"Un Hide"}
                      onClick={() => this.hideStake(record)}
                    >
                      {/* <i class="fa fa-lock"></i> */}
                      Un Hide
                    </button>
                  ) : (
                    <button
                      className="btn btn-success btn-sm"
                      title={"Hide"}
                      onClick={() => this.hideStake(record)}
                    >
                      {/* <i class="fa fa-lock"></i> */}
                      Hide
                    </button>
                  )}
                </>
              ) : (
                "Hidden"
              )}
            </Fragment>
          );
        },
      },
    ];

    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      filename: "country",
      no_data_text: "No user found!",
      button: {
        excel: true,
        print: true,
        csv: true,
      },
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: true,
      show_filter: true,
      show_pagination: true,
      show_info: true,
    };

    this.state = {
      records: [],
      cryptoList: [],
      stakeInfo: [],
    };

    this.state = {
      currentRecord: {
        id: "",
        name: "",
      },
    };

    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  componentWillReceiveProps(nextProps) {
    console.log("Stacking_componentWillReceiveProps", nextProps);
    if (
      nextProps.stacking !== undefined &&
      nextProps.stacking.stacking !== undefined &&
      nextProps.stacking.stacking.data !== undefined &&
      nextProps.stacking.stacking.data.message !== undefined &&
      nextProps.stacking.stacking.data.success
    ) {
      $("#add-user-modal").modal("hide");
      $("#update-user-modal").modal("hide");

      toast.dismiss();
      toast(nextProps.stacking.stacking.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });

      setTimeout(() => {
        this.getData();
      }, 2000);

      return;
    }

    this.getData();
  }

  userStakeApi(record) {
    this.setState({ stakeInfo: [] });

    let data = {
      stake_id: record.id,
    };

    axios
      .post(baseUrl + "/api/getAllUsersStakeDataByID", data)
      .then((res) => {
        console.log("whats the rep stake", res);

        // {}
        // $("#update-user-modal").modal("hide");

        this.setState({ stakeInfo: res });
      })
      .catch();
  }

  getData() {
    axios
      .get(baseUrl + "/api/getAllStacking")
      .then((res) => {
        console.log("res.data", res.data);
        this.setState({ records: res.data });
      })
      .catch();
    axios
      .get(baseUrl + "/api/crypto-data")
      .then((res) => {
        this.setState({ cryptoList: res.data });
      })
      .catch();
  }

  // editRecord(record) {
  //   this.setState({ currentRecord: record });
  // }

  hideStake(record) {
    record.is_show == 0
      ? confirmAlert({
          title: "Confirm to submit",
          message: "Really want to hide this stake?",
          buttons: [
            {
              label: "Yes",
              onClick: () => this.hideStakeApi(record, 1),
            },
            {
              label: "No",
            },
          ],
        })
      : confirmAlert({
          title: "Confirm to submit",
          message: "Do you really want to un hide this stake?",
          buttons: [
            {
              label: "Yes",
              onClick: () => this.hideStakeApi(record, 0),
            },
            {
              label: "No",
            },
          ],
        });
  }
  hideStakeApi = (record, is_show) => {
    const data = {
      status: is_show,
      id: record.id,
    };
    axios
      .post(baseUrl + "/api/stacking-hide", data)
      .then((res) => {
        var resp = res.data;

        toast(resp.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        this.getData();
      })
      .catch();

  };

  deleteRecordMgs(record) {
    this.setState({disable:true})
    // record.is_active == 0
    //   ? confirmAlert({
    //       title: "Confirm to submit",
    //       message: "Really want to unactivate this stake?",
    //       buttons: [
    //         {
    //           label: "Yes",
    //           onClick: () => this.deleteRecord(record),
    //         },
    //         {
    //           label: "No",
    //         },
    //       ],
    //     })
    //   :
    confirmAlert({
      title: "Confirm to submit",
      message: "Do you really want to block this stake?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.deleteRecord(record),
        },
        {
          label: "No",
        },
      ],
    });
  }
  deleteRecord = (record) => {
    const data = {
      // status: record.is_active,
      id: record.id,
    };
    axios
      .post(baseUrl + "/api/stacking-delete", data)
      .then((res) => {
        var resp = res.data;

        toast(resp.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        this.getData();
        setTimeout(() => {
          
          this.setState({disable:false})
        }, 3000);
      })
      .catch();

  };

  pageChange(pageData) {
    console.log("OnPageChange", pageData);
  }

  render() {
    return (
      <div>
        <Navbar />
        <div className="d-flex" id="wrapper">
          <Sidebar />
          <StackingAdd cryptoList={this.state.cryptoList} />
          <UsersStakeDataModal stakeInfo={this.state.stakeInfo} />
          <div id="page-content-wrapper">
            <div className="container-fluid">
              <button className="btn btn-link mt-3" id="menu-toggle">
                <FontAwesomeIcon icon={faList} />
              </button>
            
              <h4 className="mt-2 man_hadding mb-3">Staking List</h4>
              <div className="white_box">
              <button
                className="btn btn-primary float-right"
                data-toggle="modal"
                data-target="#add-user-modal"
              >
                <FontAwesomeIcon icon={faPlus} /> Add{" "}
              </button>
              <ReactDatatable
                config={this.config}
                records={this.state.records}
                columns={this.columns}
                onPageChange={this.pageChange.bind(this)}
              />
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </div>
    );
  }
}

Stacking.propTypes = {
  auth: PropTypes.object.isRequired,
  comdata: PropTypes.object.isRequired,
  stacking: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  comdata: state.comdata,
  records: state.records,
  stacking: state.stacking,
});

export default connect(mapStateToProps)(Stacking);
