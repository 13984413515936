import axios from "axios";
import * as myConstList from "./baseUrl"

const baseUrl = myConstList.baseUrl;


export const addUser = (userData, history) => dispatch => {
    axios
        .post(baseUrl + "/api/user-add", userData)
        .then(res =>
            dispatch({
                type: 'U_ADD',
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: 'GET_ERRORS',
            payload: err.response.data
        })
    );
};

export const updateUser = (userData) => dispatch => {
    axios
        .post(baseUrl + "/api/profileuser-update", userData)
        .then(res =>
            dispatch({
                type: 'U_UPDATE',
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: 'GET_ERRORS',
            payload: err.response.data
        })
    );
};
export const verifyStatus = (userData) => dispatch => {
    axios
        .post(baseUrl + "/api/verifyStatus-update", userData)
        .then(res =>
            dispatch({
                type: 'U_UPDATE',
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: 'GET_ERRORS',
            payload: err.response.data
        })
    );
};
export const rejectStatus = (userData) => dispatch => {
    axios
        .post(baseUrl + "/api/rejectStatus-update", userData)
        .then(res =>
            dispatch({
                type: 'U_UPDATE',
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: 'GET_ERRORS',
            payload: err.response.data
        })
    );
};
