import axios from "axios";
import { GET_ERRORS, USER_ADD, USER_UPDATE } from "./types";
import * as myConstList from "./baseUrl";

const baseUrl = myConstList.baseUrl;
export const newCommissions = (newCommission, history) => (dispatch) =>
  axios
    .post(baseUrl + "/api/withdrawal_commission-add", newCommission)
    .then((res) => {
      dispatch({
        type: "COM_ADD",
        payload: res,
      });
      return res;
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );

export const updateCommissions = (updateCommission) => (dispatch) => (
  axios
    .post(baseUrl + "/api/withdrawal_commission-update", updateCommission)
    .then((res) =>{
      dispatch({
        type: "COM_UPDATE",
        payload: res,
      })
      return res
    }
    )
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    )
)
