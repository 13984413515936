import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@mkikets/react-datatable';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import axios from "axios";
import { toast, ToastContainer} from "react-toastify";
import $ from 'jquery';
import { baseUrl } from "../../actions/baseUrl";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
class WithdrawalFeesCollection extends Component {

    constructor(props) {
        super(props);

        this.columns = [
          
            {
                key: "id",
                text: "#",
                className: "id",    
                align: "left",
                sortable: true,
                cell: (row, index) => index + 1
            },
            {
                key: "name",
                text: "Currency",
                className: "name",
                align: "left",
                sortable: true,
            },
            {
                key: "withdrawal_fees",
                text: "Withdrawal Fees",
                className: "short_name",
                align: "left",
                sortable: true,
            },
        ];

        this.config = {
            page_size: 10,
            length_menu: [ 10, 20, 50 ],
            filename: "Crypto Currency List",
            no_data_text: 'No record found!',
            button: {
            
                print: true,
                csv: true
            },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            records: [],
            cryptoCheckedBoxes: [],
            currentRecord: {
                id:'',
                name: '',
                short_name: '',
                order_sequence: '',
                available_for_loan: '',
                available_for_p2p: '',
                icon:""
            }
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        if (this.props.auth.user.cryptoCurrency !==undefined && this.props.auth.user.cryptoCurrency !==1 ) {
            this.props.history.push("/dashboard");
        }
        this.getData()
    };

    componentWillReceiveProps(nextProps) {
        if (this.props.auth.user.cryptoCurrency !==undefined && this.props.auth.user.cryptoCurrency !==1 ) {
            this.props.history.push("/dashboard");
        }
        if (nextProps.cryptodata !== undefined
            && nextProps.cryptodata.crypto !== undefined
            && nextProps.cryptodata.crypto.data !== undefined
            && nextProps.cryptodata.crypto.data.message !== undefined) {
           
            $('#update-user-modal').modal('hide');
            $('#add-user-modal').modal('hide');
            toast(nextProps.cryptodata.crypto.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            this.setState({ errors: {currencyImage:''}});
        }
        this.getData();
    }

    getData = () => {
       
        axios
            .get(baseUrl+"/api/userWithdrawalFeeCollection")
            .then(res => {
                if (res.status === 200) {
                    console.log("res",res.data)
                this.setState({ records: res.data})
                }
            })
            .catch()
    }
    editRecord = (record) => {

        this.setState({ currentRecord:{
            id:record.id,
            name:record.name,
            short_name:record.short_name,
            chain:record.chain_type,
            url:record.coin_tron,
            order_sequence:record.order_sequence,
            icon:record.icon,
            is_deposit:record.is_deposit,
            is_withdrawal:record.is_withdrawal,
            is_trade:record.is_trade
        } });
    }

    toggleCheckbox = (e, item) => {		
        if(e.target.checked) {
            let arr = this.state.cryptoCheckedBoxes;
            arr.push(item.id);
            this.setState = { cryptoCheckedBoxes: arr};
        } else {			
            let items = this.state.cryptoCheckedBoxes.splice(this.state.cryptoCheckedBoxes.indexOf(item.id), 1);
            this.setState = {
                cryptoCheckedBoxes: items
            }
        }	
        //console.log(this.state.cryptoCheckedBoxes);
    }

    deleteRecordMgs(record) {
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Really want to delete this crypto currency?',
            buttons: [
              {
                label: 'Yes',
                onClick: () => this.deleteRecord(record)
              },
              {
                label: 'No'
              
              }
            ]
          });
 

    }
    deleteRecord(record) {
        
        axios
            .post(baseUrl+"/api/crypto-delete", {id: record.id})
            .then(res => {
                if (res.status === 200) {
                   toast(res.data.message, {
                       position: toast.POSITION.TOP_CENTER,
                   })
                }
            })
            .catch();
        this.getData();
    }



   

    pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }

   

    render() {
      
        return (
            <div>
                <Navbar/>
                <div className="d-flex" id="wrapper">
                    <Sidebar/>
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <button className="btn btn-link mb-3" id="menu-toggle"><FontAwesomeIcon icon={faList}/></button>
                            {/* <button className="btn btn-primary float-right mb-3 mr-2" data-toggle="modal" data-target="#add-user-modal"><FontAwesomeIcon icon={faPlus}/>Add</button> */}

                            <h4 className="mt-2 man_hadding mb-3">Withdrawal Fees Collection</h4>
                            <div className="white_box">

                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                onPageChange={this.pageChange.bind(this)}
                            />
                            </div>
                        </div>
                    </div>
                    <ToastContainer/>
                </div>
            </div>
        );
    }

}

WithdrawalFeesCollection.propTypes = {
    auth: PropTypes.object.isRequired,
    cryptodata: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
    auth: state.auth,
    cryptodata: state.cryptodata,
    records: state.records
});
export default connect(
    mapStateToProps
)(WithdrawalFeesCollection);
