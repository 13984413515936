import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateBOT } from "../../../actions/botActions";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';

import 'react-toastify/dist/ReactToastify.css';

class BOTUpdateModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: this.props.record.id,
            user_id: "",
            pair_id: "",
            order_type: "",
            errors: {},
            pairList:[],
            userList:[],
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.record) {
            this.setState({
                id: nextProps.record.id,
                user_id: nextProps.record.user_id,
                pair_id: nextProps.record.pair_id,
                order_type: nextProps.record.order_type
            })
          
            if (nextProps.pairList) {
                this.setState({
                   pairList: nextProps.pairList
                })
            }
            if (nextProps.userList) {
                this.setState({
                   userList: nextProps.userList
                })
            }
            console.log('qqqqqqqqqqqqq',nextProps.userList);
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.botdata !== undefined
            && nextProps.botdata.bot !== undefined
            && nextProps.botdata.bot.data !== undefined
            && nextProps.botdata.bot.data.message !== undefined) {
            this.setState({errors:{}
            });
        }
      
    }

    onChange = e => {
        this.setState({errors:{}});
        if (e.target.name === 'user_id') {
            this.setState({ user_id: e.target.value });
        }
        if (e.target.name === 'pair_id') {
            this.setState({ pair_id: e.target.value });
        }
        if (e.target.name === 'order_type') {
            this.setState({ order_type: e.target.value });
        }
       
    };

    onBOTUpdate = e => {
        e.preventDefault();
        const newBOT = {
            _id: this.state.id,
            pair_id: this.state.pair_id,
            // user_id: this.state.user_id,
            order_type: this.state.order_type
     };
     this.props.updateBOT(newBOT);
    };

    showHtml = () => {
        const html = []
        this.state.pairList.map(function (value, i) {
            html.push(
                <>  
                <option value={value.id}>{value.name1} / {value.name2}</option>
                </>
            );
            
        })
        return html;
    }

    showUserHtml = () => {
        const userhtml = []
        this.state.userList.map(function (value, i) {
            userhtml.push(
                <>  
                    <option value={value.id}>{value.email}</option>
                </>
            );
        })
        return userhtml;
    }

    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="update-user-modal">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Update BOT</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onBOTUpdate} id="update-user">
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.id}
                                        id="user-update-id"
                                        type="text"
                                        className="d-none"/>
                                     <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="box1">Pair</label>
                                        </div>
                                        <div className="col-md-9">
                                        <select  className="form-control" id="pair_id" name="pair_id" value= {this.state.pair_id}  error={errors.pair_id} onChange={this.onChange}>
                                            <option>Please select pair</option>
                                            {this.showHtml()}
                                            </select>
                                          <span className="text-danger">{errors.pair_id}</span>
                                          <span className="text-danger">{errors.message}</span>
                                        </div>
                                    </div>
{/*                                
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="box2">User</label>
                                        </div>
                                        <div className="col-md-9">
                                        <select  className="form-control" id="user_id" name="user_id" value={this.state.user_id}  error={errors.user_id} onChange={this.onChange}>
                                            <option>Please select user</option>
                                            {this.showUserHtml()}
                                            </select>
                                            <span className="text-danger">{errors.user_id}</span>
                                        </div>
                                    </div> */}

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="box2">Order Type</label>
                                        </div>
                                        <div className="col-md-9">
                                            
                                            <input type="radio" checked={this.state.order_type == 'buy'} name="order_type" value="buy" onChange={this.onChange}/>Buy 
                                            <input type="radio" checked={this.state.order_type == 'sell'} name="order_type" value="sell" onChange={this.onChange}/>Sell 
                                            <span className="text-danger">{errors.order_type}</span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button
                                    form="update-user"
                                    type="submit"
                                    className="btn btn-primary">
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

BOTUpdateModal.propTypes = {
    updateBOT: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    botdata: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    botdata: state.botdata,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { updateBOT }
)(withRouter(BOTUpdateModal));
