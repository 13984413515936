import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import $ from "jquery";
import Moment from 'moment'
import "react-toastify/dist/ReactToastify.css";

class UserDepositeModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id:"this.props.user.id",
      userData: [],
      dataStatus: false,
      portfolioUsdt:0,
      portfolioInr:0
    };
    //console.log("this.props",this.props)
  }

  componentWillReceiveProps(nextProps) {
  //  console.log("nextProps.currentRecordData",nextProps.depositeHistory)
    if (nextProps.depositeHistory) {
      this.setState({
        userData: nextProps.depositeHistory.data,
        dataStatus: nextProps.depositeHistory.status,
      });
    }
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
  }

  tableDataHtml = () => {
    if (this.state.dataStatus == true) {
      const html = [];
      const classThis = this;
      this.state.userData.map((value, i) => {
        html.push(
          <tr>
          <td>{i + 1}</td>
          <td>
            {value.email}
          </td>
          <td>{value.short_name}</td>
          <td>
            {value.coin_amount}
          </td>
          <td>
            {value.status}
          </td>
          <td>{Moment.utc(value.created).local().format("lll")}</td>

        </tr>
        );
      });
      return html;
    }
  };

  render() {
    const { errors } = this.state;
    return (
      <div>
        <div className="modal fade" id="update-userdeposite-modal" data-reset="true" data-backdrop="static">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">User Deposit History</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <table className="table w-100">
                  <thead>
                    <tr>
                    <th>Sr No.</th>
                      <th>Email</th>
                      <th>Short Name</th>
                      <th>Amount</th>
                      <th>Status</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>{this.tableDataHtml()}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

UserDepositeModal.propTypes = {
  userFundHistory: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps)(withRouter(UserDepositeModal));
