import axios from "axios";
import {
    GET_ERRORS,
    INTRESTRATE_ADD,
    INTRESTRATE_UPDATE
} from "./types";
import * as myConstList from "./baseUrl"

const baseUrl = myConstList.baseUrl;

export const addIntrestrate = (IntrestrateData, history) => dispatch => {
    axios
        .post(baseUrl + "/api/rate-of-intrest-add", IntrestrateData)
        .then(res =>
            dispatch({
                type: INTRESTRATE_ADD,
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    );
};


export const updateIntrestrate = (IntrestrateData) => dispatch => {
    axios
        .post(baseUrl + "/api/rate-of-intrest-update", IntrestrateData)
        .then(res =>
            dispatch({
                type: INTRESTRATE_UPDATE,
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    );
};
