import { combineReducers } from "redux";
import authReducer from "./authReducers";
import cryptoReducers from "./cryptoReducers";
import loantovalueReducers from "./loantovalueReducers";
import intrestrateReducers from "./intrestrateReducers";
import margincallReducers from "./margincallReducers";
import liquidationvalueReducers from "./liquidationvalueReducers";
import userReducers from "./userReducers";
import errorReducer from "./errorReducers";
import roleReducers from "./roleReducers";
import transactionReducers from "./transactionReducers";
import pairReducers from "./pairReducers";
import botReducers from "./botReducers";
import credentialReducers from "./credentialReducers";
import helpReducers from "./helpReducers";
import commissionReducers from "./commissionReducers";
import banner from "./bannerReducer"
import news from "./NewsReducer"
import video from "./videoReducer"
import stackRecuders from "./stackRecuders";
import withdrawalReducer from "./withdrawalReducer";
export default combineReducers({
    auth: authReducer,
    cryptodata: cryptoReducers,
    loantovaluedata: loantovalueReducers,
    intrestratedata: intrestrateReducers,
    margincalldata: margincallReducers,
    liquidationvaluedata: liquidationvalueReducers,
    userdata: userReducers,
    transactiondata: transactionReducers,
    roledata: roleReducers,
    comdata: commissionReducers,
    helpdata: helpReducers,
    pairdata: pairReducers,
    botdata: botReducers,
    credentialdata: credentialReducers,
    banner:banner,
    news:news,
    withdrawal:withdrawalReducer,
    video:video,
    stacking:stackRecuders,
    errors: errorReducer
});