import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateCountry } from "../../actions/countryActions";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';
import { baseUrl } from '../../actions/baseUrl'
import Moment from 'moment';
import axios from "axios";

import 'react-toastify/dist/ReactToastify.css';
class WithdrawalINRReject extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: this.props.record.id,
            reject:this.props.record.reject,
            status:this.props.record.WithdrawlRequest,
            coin_amount: this.props.record.coin_amount,
            errors: {},
            updateDisables:false
        };
        // console.log("kajdhaskjdkj",this.props.record)
    }
    

    componentWillReceiveProps(nextProps) {
        console.log("nextProps.record",nextProps.record)
        
        if (nextProps.record) {
            this.setState({
                id: nextProps.record.id,
                user_id : nextProps.record.user_id,
                reject: nextProps.record.reject,
                status: nextProps.record.WithdrawlRequest,
                coin_amount: nextProps.record.coin_amount
            })
            console.log("newXCoin",this.state.coin_amount,this.state.reject)
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.auth !== undefined
            && nextProps.auth.user !== undefined
            && nextProps.auth.user.data !== undefined
            && nextProps.auth.user.data.messageU !== undefined
            && nextProps.auth.user.data.success) {

            toast(nextProps.auth.user.data.messageU, {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }

    onChange = e => {
        if (e.target.id === 'user-update-reject') {
            this.setState({ reject: e.target.value });
        }
       
    };

    onCountryUpdate = e => {
       
        e.preventDefault();
        this.onCountryInsert()
        this.setState({updateDisables: true})
        setTimeout(() => this.setState({ updateDisables: false }), 10000);

      
        axios
        .post(baseUrl+"/api/InrStatus-update", {id:this.state.id,reject: this.state.reject,type: "2"})
        .then(res => {
            if (res.status === 200) {
                $('#update-user-modal-reject').modal('hide');
               toast(res.data.message, {
                   position: toast.POSITION.TOP_CENTER,
               })
               this.setState({reject:""})

               setTimeout(function () {
                window.location.reload();
            }, 3000);
            }
        })
        .catch();
    };

    onCountryInsert = e => {
        axios
        .post(baseUrl+"/api/returnInrOnReject",{user_id:this.state.user_id,coin_amount:this.state.coin_amount})
        .then(res => {
                if (res.status === 200) {
                //     $('#update-user-modal-reject').modal('hide');
                //    toast(res.data.message, {
                //        position: toast.POSITION.TOP_CENTER,
                //    })
                //    setTimeout(function () {
                //     window.location.reload();
                // }, 3000);
            }
        })
        .catch();
    };

    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="update-user-modal-reject" data-backdrop="static">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Withdrawal Request</h4>
                                <button type="button" className="close" data-dismiss="modal" onClick={()=>{
                                    this.setState({reject:""})
                                }}>&times;</button>
                            </div>
                            {console.log("rejectreject",this.state.reject)}
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onCountryUpdate} id="update-user">
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.id}
                                        id="user-update-id"
                                        type="text"
                                        className="d-none"/>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <label htmlFor="name">Reject Reason</label>
                                            <textarea
                                                onChange={this.onChange}
                                                value={this.state.reject}
                                                id="user-update-reject"
                                                type="text"
                                                error={errors.reject}
                                                className={classnames("form-control", {
                                                    invalid: errors.reject
                                                })}/>
                                            <span className="text-danger">{errors.reject}</span>
                                        </div>
                                    </div>
                               
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={()=>{
                                    this.setState({reject:""})
                                }}>Close</button>
                                {this.state.status=="0" &&
                                <button
                                    form="update-user"
                                    type="submit"
                                    className="btn btn-primary"
                                    disabled={this.state.updateDisables}>
                                    Update
                                </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

WithdrawalINRReject.propTypes = {
  
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
   
)(withRouter(WithdrawalINRReject));
