import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateCountry } from "../../actions/countryActions";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import $ from "jquery";

import "react-toastify/dist/ReactToastify.css";
import * as myConstList from "../../actions/baseUrl";
import Moment from "moment";
import axios from "axios";
const baseUrl = myConstList.baseUrl;
class DepositINRAppove extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.record.id,
      approve: this.props.record.approve,
      status: this.props.record.status,
      errors: {},
    };
  }

  componentWillReceiveProps(nextProps) {
    console.log("nextProps.record", nextProps.record);
    if (nextProps.record) {
      this.setState({
        id: nextProps.record.id,
        user_id: nextProps.record.user_id,
        email: nextProps.record.email,
        mobile_no: nextProps.record.mobile_no,
        amount:nextProps.record.amount
      });
    }
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
    if (
      nextProps.auth !== undefined &&
      nextProps.auth.user !== undefined &&
      nextProps.auth.user.data !== undefined &&
      nextProps.auth.user.data.messageU !== undefined &&
      nextProps.auth.user.data.success
    ) {
      toast(nextProps.auth.user.data.messageU, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onUpdateApprove = (e) => {
    e.preventDefault();
    axios
      .post(baseUrl + "/api/InrStatus-deposit", {
        id: this.state.id,
        user_id: this.state.user_id,
        email: this.state.email,
        amount: this.state.amount,
        type: '1',
      })
      .then((res) => {
        if (res.status === 200) {
          $("#update-user-modal-approve").modal("hide");
          toast(res.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          setTimeout(function () {
            window.location.reload();
          }, 3000);
        }
      })
      .catch();
  };

  render() {
    const { errors } = this.state;
    return (
      <div>
        <div className="modal fade" id="update-user-modal-approve" data-backdrop="static">
          <div className="modal-dialog">
            <div className="modal-content">
              {/* <div className="modal-header">
                <h3 className="mt-2 man_hadding mb-3">Add/Deduct Balance</h3>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div> */}
              <div className="modal-body">
                <ul className="nav nav_left_tab justify-content-between">
                  <li className="nav-item">
                    <a
                      className="active show"
                      data-toggle="tab"
                      href="#details"
                    >
                      <i className="la la-mobile-phone l_i"></i>
                      Add/Deduct Balance
                    </a>
                  </li>
                  <li>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                    >
                      &times;
                    </button>
                  </li>
                </ul>
                <hr />
                <form
                  noValidate
                  onSubmit={this.onUpdateApprove}
                  id="update-user-approve"
                >
                  <div className="white_box">
                    <div className="tab-content ">
                      <div className="tab-pane active show" id="details">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="contestStatus">Type</label>
                              <select
                                className="browser-default custom-select"
                                name="role"
                                onChange={this.onChange}
                              >
                                <option value="add">Add</option>
                                {/* <option value="deduct">Deduct</option> */}
                              </select>
                              <span className="text-danger">{errors.role}</span>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="email">Email</label>
                              <input
                                onChange={this.onChange}
                                error={errors.email}
                                name="email"
                                value={this.state.email}
                                type="text"
                                className={classnames("form-control", {
                                  invalid: errors.email,
                                })}
                                disabled
                              />
                              <span className="text-danger">{errors.email}</span>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="mobile">Mobile number</label>
                              <input
                                onChange={this.onChange}
                                error={errors.email}
                                name="mobile_no"
                                value={this.state.mobile_no}
                                type="text"
                                disabled
                                className={classnames("form-control", {
                                  invalid: errors.email,
                                })}
                              />
                              <span className="text-danger">{errors.email}</span>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="amount">Amount</label>
                              <input
                                onChange={this.onChange}
                                error={errors.amount}
                                name="amount"
                                value={this.state.amount}
                                type="text"
                                disabled
                                className={classnames("form-control", {
                                  invalid: errors.amount,
                                })}
                              />
                              <span className="text-danger">{errors.amount}</span>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <button className="btn btn-primary " type="submit">
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

DepositINRAppove.propTypes = {
  updateCountry: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps)(withRouter(DepositINRAppove));
