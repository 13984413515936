import React, { Component, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { withRouter } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import $ from 'jquery';
import classnames from "classnames";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as myConstList from "../../../actions/baseUrl"

const baseUrl = myConstList.baseUrl
class ProfileUpdate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            firstName: '',
            lastName: '',
            dob: '',
            email: '',
            mobile: '',
            line1: '',
            line2: '',
            city: "",
            r_country: "",
            mobile_no: "",
            BankName: "",
            AccNum: "",
            Ifsc: "",
            BranchName: "",
            AccHolder: "",
            errors: {}
        };


    }

    getData() {
        axios
            .post(baseUrl + "/api/User-By-Id-Data", { _id: this.props.match.params.id })
            .then(res => {
                console.log("res.data", res.data);
                console.log(res.data);
                this.setState({
                    firstName: res.data.firstName,
                    lastName: res.data.lastName,
                    email: res.data.email,
                    dob: res.data.dob,
                    line1: res.data.line1,
                    line2: res.data.line2,
                    zipcode: res.data.zipcode,
                    city: res.data.city,
                    r_country: res.data.country,
                    mobile_no: res.data.mobile_no,
                    BankName: res.data.BankName,
                    AccNum: res.data.AccNum,
                    Ifsc: res.data.Ifsc,
                    BranchName: res.data.BranchName,
                    AccHolder: res.data.AccHolder,
                    upiId:res.data.upi

                })
            })
            .catch()
    }
    componentDidMount() {
        if (this.props.auth.user.manageUsers !== undefined && this.props.auth.user.manageUsers !== 1) {
            this.props.history.push("/dashboard");
        }
        this.getData();
    };

    componentWillReceiveProps(nextProps) {
        console.log("this.state.records", nextProps)
        if (this.props.auth.user.manageUsers !== undefined && this.props.auth.user.manageUsers !== 1) {
            this.props.history.push("/dashboard");
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.userdata !== undefined
            && nextProps.userdata.newuser !== undefined
            && nextProps.userdata.newuser.data !== undefined
            && nextProps.userdata.newuser.data.message !== undefined
            && nextProps.userdata.newuser.data.success) {
        }

        this.getData()
    }



    onChange = e => {
        this.setState({ errors: {} });
        this.setState({ [e.target.name]: e.target.value });
    };

    onRoleUpdate = e => {
        e.preventDefault();

        const newdata = {
            id: parseInt(this.props.match.params.id),
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            dob: this.state.dob,
            line1: this.state.line1,
            line2: this.state.line2,
            zipcode: this.state.zipcode,
            city: this.state.city,
            r_country: this.state.r_country,
            mobile_no: this.state.mobile_no,
            BankName: this.state.BankName,
            AccNum: this.state.AccNum,
            Ifsc: this.state.Ifsc,
            upiId:this.state.UpiId,
            BranchName: this.state.BranchName,
            AccHolder: this.state.AccHolder,
        }
        this.props.updateUser(newdata);
    }
    render() {

        const { errors } = this.state;
        return (


                <div className="in_page" >
                   

                        <div className="white_box">
                            <div className="bank-detail-outer">
                            <div className="row">
                            {/* <div className="col-md-6">
                                <div className="user-detail-inner-main d-flex align-items-center">
                                    <h5 htmlFor="BankName">Bank Name :</h5>
                                    <p>{this.state.BankName}</p>
                                </div>
                            </div> */}
                            <div className="col-md-6">
                                <div className="user-detail-inner-main d-flex align-items-center">
                                    <h5 htmlFor="AccHolder">Account Holder:</h5>
                                    <p>{this.state.AccHolder}</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="user-detail-inner-main d-flex align-items-center">
                                    <h5 htmlFor="AccNum">Account Number:</h5>
                                    <p>{this.state.AccNum}</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="user-detail-inner-main d-flex align-items-center">
                                    <h5 htmlFor="AccHolder">IFSC Code:</h5>
                                    <p>{this.state.Ifsc}</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="user-detail-inner-main d-flex align-items-center">
                                    <h5 htmlFor="UpiId">Upi Id:</h5>
                                    <p>{this.state.upiId}</p>
                                </div>
                            </div>
                            {/* <div className="col-md-6">
                                <div className="user-detail-inner-main d-flex align-items-center">
                                    <h5 htmlFor="BranchName">Branch Name :</h5>
                                    <p>{this.state.BranchName}</p>
                                </div>
                            </div> */}


                        </div>
                        </div>
                    </div>
                    </div>

        );
    }

}

ProfileUpdate.propTypes = {
    updateUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    userdata: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    userdata: state.userdata,
    errors: state.errors
});

export default connect(
    mapStateToProps,
)(withRouter(ProfileUpdate));
