import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import {
    GET_ERRORS,
    SET_CURRENT_USER,
    USER_LOADING
} from "./types";
import * as myConstList from "./baseUrl"

const baseUrl = myConstList.baseUrl;

export const registerUser = (userData, history) => dispatch => {
    axios
        .post(baseUrl + "/api/register", userData)
        .then(res => history.push("/"))
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};


export const loginUser = userData => dispatch => {

    axios
        .post(baseUrl + "/api/login", userData)
        .then(res => {
            const { token } = res.data;
            localStorage.setItem("jwtToken", token);
            setAuthToken(token);
            const decoded = jwt_decode(token);
            localStorage.setItem("dbiAdminDetail", JSON.stringify(decoded));
            dispatch(setCurrentUser(decoded));

        })
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};

export const forgetPassword = (userData, history) => dispatch => {
    axios
        .post(baseUrl + "/api/forget-Password", userData)
        .then(res =>
            dispatch({
                type: 'FORGET',
                payload: res,
            })
        )
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};
export const setCurrentUser = decoded => {
    return {
        type: SET_CURRENT_USER,
        payload: decoded
    };
};

export const setUserLoading = () => {
    return {
        type: USER_LOADING
    };
};

export const logoutUser = () => dispatch => {

    console.log("==logout=====");

    axios.post(baseUrl + "/api/admin_logout", {})
        .then(res => {
            console.log(res);
        })
        .catch(err => {
            console.log(err);
        });
    localStorage.removeItem("jwtToken");
    localStorage.removeItem('dbiAdminDetail')
    setAuthToken(false);
    dispatch(setCurrentUser(null));
};
