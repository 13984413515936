import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { newCommissions } from "../../actions/WithdrawalCommission";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import $ from "jquery";

import "react-toastify/dist/ReactToastify.css";
import { Alert } from "bootstrap";

class WithdrawalCommissionAdd extends React.Component {
  constructor() {
    super();
    this.state = {
      currency: "",
      buyCommission: "",
      sellCommission: "",
      type: "percentage",
      errors: {},
      cryptoList: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    if (typeof nextProps.error != 'undefined') {
      nextProps.error.currency = ''
    }
    console.log("nextProps 1234", nextProps.error)
    if (nextProps.cryptoList) {
      this.setState({
        cryptoList: nextProps.cryptoList,
      });
    }
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
    if (
      nextProps.comdata !== undefined &&
      nextProps.comdata.commission !== undefined &&
      nextProps.comdata.commission.data !== undefined &&
      nextProps.comdata.commission.data.message !== undefined &&
      nextProps.comdata.commission.data.success
    ) {
      this.setState({
        errors: {},
        currency: "",
        buyCommission: "",
        sellCommission: "",
      });
    }
  }

  onChange = (e) => {
    this.setState({
      errors: {
        currency: "",
        buyCommission: "",
        sellCommission: "",
      },
    });
    // this.setState({ [e.target.id]: e.target.value,[e.target.id+"Error"]:"" });
    this.setState({ [e.target.id]: e.target.value});
     if(e.target.id==="currency"){
      if(!e.target.value){
        this.setState({currencyError:"This field is required"})
        return false
      }
      this.setState({currencyError:""})
     }
     if(e.target.id==="buyCommission"){
      if(!e.target.value){
        this.setState({buyCommissionError:"This field is required"})
        return false
      }
      this.setState({buyCommissionError:""})
     }
     if(e.target.id==="minWithdrawal"){
      if(!e.target.value){
        this.setState({minWithdrawalError:"This field is required"})
        return false
      }
      this.setState({minWithdrawalError:""})
     }
     if(e.target.id==="maxWithdrawal"){
      if(!e.target.value){
        this.setState({maxWithdrawalError:"This field is required"})
        return false
      }
      this.setState({maxWithdrawalError:""})
     }
     if(e.target.id==="type"){
      if(!e.target.value){
        this.setState({feesTypeError:"This field is required"})
        return false
      }
      this.setState({feesTypeError:""})
     }
  };

  onCountryAdd = (e) => {
    e.preventDefault();
    if(!this.state.currency){
      this.setState({currencyError:"This field is required"})
      return false
    }
    if(!this.state.buyCommission){
      this.setState({buyCommissionError:"This field is required"})
      return false
    }
    if(!this.state.minWithdrawal){
      this.setState({minWithdrawalError:"This field is required"})
      return false
    }
    if(!this.state.maxWithdrawal){
      this.setState({maxWithdrawalError:"This field is required"})
      return false
    }
    if(!this.state.type){
      this.setState({feesTypeError:"This field is required"})
      return false
    }
    if(parseFloat(this.state.minWithdrawal)>parseFloat(this.state.maxWithdrawal)){
      toast.error("Min Withdrawal limit must be less than maximum withdrawal limit")
      return false
    }

    const newCountry = {
      currency: this.state.currency,
      buyCommission: this.state.buyCommission,
      maxWithdrawal: this.state.maxWithdrawal,
      minWithdrawal: this.state.minWithdrawal,
      type: this.state.type,
    };
 
    this.props.newCommissions(newCountry, this.props.history).then(res=>{
      console.log("res===",res)
      if(res.data.status){
        toast.success(res.data.message)
        setTimeout(() => {

          window.location.reload();
        }, 2000);
      }
      else{
        toast.error(res.data.message)
      }
    })
  };
  showHtml = () => {
    const html = [];
    this.state.cryptoList.map(function (value, i) {
      html.push(
        <>
          <option value={value.id}>{value.name}</option>
        </>
      );
    });
    return html;
  };
  render() {
    const { errors } = this.state;
    return (
      <div>
        <div className="modal fade" id="add-user-modal" data-reset="true" data-backdrop="static">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Add Commission</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <form noValidate onSubmit={this.onCountryAdd} id="add-user">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="name">Pair Name</label>
                        <select
                          className="form-control"
                          id="currency"
                          value={this.state.currency}
                          error={errors.currency}
                          onChange={this.onChange}
                        >
                          <option>Please select crypto currency</option>
                          {this.showHtml()}
                        </select>
                        <span className="text-danger">{this.state.currencyError}</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="buyCommission">Withdrawal Commission</label>
                        <input
                          onChange={this.onChange}
                          value={this.state.buyCommission}
                          id="buyCommission"
                          type="number"
                          onKeyPress={(event) => {
                            if (!/[0-9.]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          error={errors.buyCommission}
                          className={classnames("form-control", {
                            invalid: errors.buyCommission,
                          })}
                       
                        />
                        <span className="text-danger">
                          {this.state.buyCommissionError}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="minWithdrawal">Min Withdrawal</label>
                        <input
                          onChange={this.onChange}
                          value={this.state.minWithdrawal}
                          id="minWithdrawal"
                          type="number"
                          error={errors.minWithdrawal}
                          className={classnames("form-control", {
                            invalid: errors.minWithdrawal,
                          })}
                          onKeyPress={(event) => {
                            if (!/[0-9.]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                        <span className="text-danger">
                          {this.state.minWithdrawalError}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="maxWithdrawal">Max Withdrawal</label>
                        <input
                          onChange={this.onChange}
                          value={this.state.maxWithdrawal}
                          id="maxWithdrawal"
                          type="number"
                          onKeyPress={(event) => {
                            if (!/[0-9.]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          error={errors.maxWithdrawal}
                          className={classnames("form-control", {
                            invalid: errors.maxWithdrawal,
                          })}
                      
                        />
                        <span className="text-danger">
                          {this.state.maxWithdrawalError}
                        </span>
                      </div>
                    </div>
                   
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="type">Fees Type</label>
                        <select className="form-control" onChange={this.onChange} value={this.state.type} id='type'>
                          {/* <option>Select</option> */}
                          {/* <option value="flat">Flat</option> */}
                          <option value="percentage">Percentage</option>
                        </select>
                        <span className="text-danger">
                          {this.state.feesTypeError}
                        </span>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  form="add-user"
                  type="submit"
                  className="btn btn-primary"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

WithdrawalCommissionAdd.propTypes = {
  newCommissions: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  comdata: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  comdata: state.comdata,
  errors: state.errors,
});

export default connect(mapStateToProps, { newCommissions })(
  withRouter(WithdrawalCommissionAdd)
);
