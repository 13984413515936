
import React, { useState, useEffect } from 'react'
import { baseUrl } from '../../actions/baseUrl'
import axios from "axios"
import $ from 'jquery';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

function UpdateTokenExchangeModal(props) {
    console.log("props", props)
    const [id, setId] = useState("")
    const [date, setDate] = useState("")
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [image, setImage] = useState("")
    const [imageUrl, setImageUrl] = useState("")
    const [dateErr, setDateErr] = useState("")
    const [titleErr, setTitleErr] = useState("")
    const [descriptionErr, setDescriptionErr] = useState("")
    const [imageErr, setImageErr] = useState("")
    const [language, setLanguage] = useState("en")
    const [state, setState] = useState({})

    useEffect(() => {
        setState(props.currentRecord)
      //  setDescription(props.currentRecord?.description ? props.currentRecord?.description : "")
       // setTitle(props.currentRecord.title)
       // setDate(props.currentRecord.date)
       // setId(props.currentRecord.id)
       // setImageUrl(props.currentRecord.image)
        setLanguage(props.currentRecord.lang)
    }, [props])



    const handleChange = (e) => {
        const { name, value } = e.target

        setState({ ...state, [name]: value })
    }


    const handleSubmit = (e) => {
        e.preventDefault()



        let data = {
            id: state.id,
            qty_one: state.qty_one,
            qty_two: state.qty_two,
        }

        axios.post(baseUrl + "/api/update_token_exchange", data).then((res) => {
            if (res.data.status) {
                props.setRefresh(!props.refresh)
                $('#update-user-modal').modal('hide');
                setState({})
            }
        }).catch((err) => {

        })
    }
    return (
        <div>
            <div className="modal fade" id="update-user-modal">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Update Token Exchange </h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <form id="update-user1" onSubmit={handleSubmit}>
                                <div className='mb-3'>
                                    Token One: <label className='form-label' style={{ "fontWeight": "800" }}> {state.first_coin}</label>
                                    <input type="number" name="qty_one" value={state.qty_one} onChange={handleChange} className='form-control' />

                                </div>
                                <div className='mb-3'>
                                    Token Two:   <label className='form-label' style={{ "fontWeight": "800" }}>{state.second_coin}</label>
                                    <input type="number" name="qty_two" value={state.qty_two} onChange={handleChange} className='form-control' />
                                </div>







                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button
                                form="update-user1"
                                type="submit"
                                className="btn btn-primary">
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UpdateTokenExchangeModal
