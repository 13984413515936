import { fas } from '@fortawesome/free-solid-svg-icons'
import React, { useState } from 'react'
import { baseUrl } from '../../actions/baseUrl'
import axios from "axios"
import $ from 'jquery';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
function AddBlogModal(props) {
  const [date, setDate] = useState("")
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [image, setImage] = useState("")
  const [imageData, setImageData] = useState("")
  const [imageUrl, setImageUrl] = useState("")
  const [dateErr, setDateErr] = useState("")
  const [titleErr, setTitleErr] = useState("")
  const [descriptionErr, setDescriptionErr] = useState("")
  const [imageErr, setImageErr] = useState("")
  const [language, setLanguage] = useState("en")
  const [linkErr, setLinkErr] = useState("")  
  const [blogLink, setBlogLink] = useState("")

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name);
    if (name === "date") {
      setDate(value)
      if (value === "") {
        setDateErr("This field is required")
        return false
      }
      setDateErr("")
    }
    if (name === "title") {
      setTitle(value)
      if (value == "") {
        setTitleErr("This field is required")
        return false
      }
      setTitleErr("")
    }
    if (name === "lang") {
      setLanguage(value)
    }
    if (name === "link") {
      console.log(value,"  1");
      // let reg = new RegExp("^(http|https|ftp)\://([a-zA-Z0-9\.\-]+(\:[a-zA-Z0-9\.&amp;%\$\-]+)*@)*((25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9])\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[0-9])|localhost|([a-zA-Z0-9\-]+\.)*[a-zA-Z0-9\-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(\:[0-9]+)*(/($|[a-zA-Z0-9\.\,\?\'\\\+&amp;%\$#\=~_\-]+))*$");

      console.log("2");
      setBlogLink(value)
     // console.log(reg.test(value)," 3");
      if (value == "") {
        console.log("4");
        setLinkErr("This field is required")
        console.log("5");
        return false
      }else if(!validateUrl(value)){
        console.log("6");
        setLinkErr("Please Enter valid Url")
        console.log("7");
        return false
      }
      console.log("8");
      setLinkErr("")

    }

    function validateUrl(value) {
      return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value);
    }
    // if(name==="description"){
    //     setDescription(value)
    //     if(value===""){
    //         setDescriptionErr("This field is required")
    //         return false
    //     }
    //     setDescriptionErr("")
    // }
  }
  const closeModal = () => {
    console.log("close");
    setDate("")
    setImageData("")
    setImage("")
    setImageUrl("")
    setTitle("")
    setDescription("")
    setDateErr("")
    setTitleErr("")
    setDescriptionErr("")
    setLinkErr("")
    setBlogLink("")
    setImageErr("")
    setLanguage("en")
  }

  const handleImage = (e) => {
    let { value } = e.target;

    const icon = e.target.files[0];

    if (value !== "" || value !== undefined) {
      setImage(e.target.files[0]);
      setImageUrl(URL.createObjectURL(e.target.files[0]));
      setImageErr("");
    } else {
      setImage(e.target.files[0]);
      setImageUrl("");
      setImageErr("This field is required");
    }
    if (!icon.name.match(/\.(jpg|jpeg|png)$/)) {
      setImageErr("Select valid image format");
      return false;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault()
    setDescriptionErr("")
    if (date === "") {
      setDateErr("This field is required")
      return false
    }
    if (title === "") {
      setTitleErr("This field is required")
      return false
    }
    if (blogLink === "") {
      setLinkErr("This field is required")
      return false
    }
    if (description === "") {
      setDescriptionErr("This field is required")
      return false
    }
    if (image === "") {
      setImageErr("This field is required")
      return false
    }
    
    
    let formdata = new FormData
    formdata.append("date", date)
    formdata.append("title", title)
    formdata.append("description", description)
    formdata.append("image", image)
    formdata.append("lang", language)
    formdata.append("link", blogLink)
    axios.post(baseUrl + "/api/blog-add", formdata).then((res) => {
      if (res.data.status) {
        props.setRefresh(!props.refresh)
        $('#add-user-modal').modal('hide');
        closeModal()
      }
    }).catch((err) => {

    })
  }

  return (
    <div>
      <div className="modal fade" id="add-user-modal">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Add Blog</h4>
              <button type="button" className="close" data-dismiss="modal" onClick={closeModal}>&times;</button>
            </div>
            <div className="modal-body">
              <form id="update-user" onSubmit={handleSubmit}>
              <div className='mb-3'>
                  <label className='form-label'>Language:</label>
                  <select onChange={handleChange} value={language} name="lang" className="form-select w-100 form-control">
                    <option value="en">English</option>
                    <option value="ko">Korean</option>
                    <option value="rus">Russian</option>
                    <option value="per">Persian</option>
                    <option value="ar">Arabic</option>
                  </select>
                </div>
                <div className='mb-3'>
                  <label className='form-label'>Date:</label>
                  <input type="date" name="date" value={date} onChange={handleChange} className='form-control' min={new Date().toJSON().slice(0, 10)}/>
                  <span style={{ color: "red" }}>{dateErr}</span>
                </div>
                <div className='mb-3'>
                  <label className='form-label'>Title:</label>
                  <textarea name="title" value={title} onChange={handleChange} className='form-control' />
                  <span style={{ color: "red" }}>{titleErr}</span>
                </div>
                <div className='mb-3'>
                  <label className='form-label'>Link:</label>
                  <input type='text' name="link" value={blogLink} onChange={handleChange} className='form-control' />
                  <span style={{ color: "red" }}>{linkErr}</span>
                </div>
                
                <div className='mb-3'>
                  <label className='form-label'>Description:</label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={description}
                    config={{
                      toolbar: [
                        "heading",
                        "|",
                        "bold",
                        "italic",
                        "link",
                        "bulletedList",
                        "numberedList",
                        "blockQuote",
                      ],
                      heading: {
                        options: [
                          {
                            model: "paragraph",
                            title: "Paragraph",
                            class: "ck-heading_paragraph",
                          },
                          {
                            model: "heading1",
                            view: "h1",
                            title: "Heading 1",
                            class: "ck-heading_heading1",
                          },
                          {
                            model: "heading2",
                            view: "h2",
                            title: "Heading 2",
                            class: "ck-heading_heading2",
                          },
                          {
                            model: "heading3",
                            view: "h3",
                            title: "Heading 3",
                            class: "ck-heading_heading3",
                          },
                        ],
                      },
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setDescription(data);
                    }}
                  />
                  {/* <textarea name="description" value={description} onChange={handleChange}  className='form-control'/> */}
                  <span style={{ color: "red" }}>{descriptionErr}</span>
                </div>
                <div className='mb-3'>
                  <img
                    style={{ width: "100px" }}
                    src={imageUrl}
                    className="img-fluid"
                    alt=""
                  />
                  <label className='form-label'>Image:</label>
                  <input type="file" name="image" onChange={handleImage} className='form-control' accept="image/png, image/jpeg, image/jpg" value={imageData} />
                  <span style={{ color: "red" }}>{imageErr}</span>
                </div>




              </form>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={closeModal}>Close</button>
              <button
                form="update-user"
                type="submit"
                className="btn btn-primary">
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddBlogModal
