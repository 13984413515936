import axios from "axios";
import { CLEAR_ERRORS, GET_ERRORS, USER_ADD, USER_UPDATE } from "./types";
import * as myConstList from "./baseUrl";
import { toast } from "react-toastify";

const baseUrl = myConstList.baseUrl;

export const addCrypto = (CryptoData, history) => async (dispatch) => {
  try {
    let result = await axios.post(baseUrl + "/api/crypto-add", CryptoData);
    console.log(result);
    if (result.status) {
      dispatch({
        type: "CRYPTO_ADD",
        payload: result,
      });
    } else {
      dispatch({
        type: GET_ERRORS,
        payload: result.message,
      });
    }
  } catch (error) {
    console.log("errorrr", error);
    if (error?.response?.data) {
      dispatch({
        type: GET_ERRORS,
        payload: error.response.data,
      });
    } else {
      toast.error("Invalid contract");
    }
  }

  // .then(res =>
  //     dispatch({
  //         type: 'CRYPTO_ADD',
  //         payload: res,
  //     })
  // ).catch(err =>{
  // console.log("err",err)
  // // return err
  // dispatch({
  //     type: GET_ERRORS,
  //     payload: err.text()
  // })
  // }
  // );
};

export const updateCrypto = (CryptoData) => (dispatch) => {
  axios
    .post(baseUrl + "/api/crypto-update", CryptoData)
    .then((res) =>
      dispatch({
        type: "CRYPTO_UPDATE",
        payload: res,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

export const clearErrors = () => (dispatch) => {
  return dispatch({
    type: CLEAR_ERRORS,
    payload: {},
  });
};

export const clearCryptoData = () => (dispatch) => {
  return dispatch({
    type: "CLEAR_CRYPTO_DATA",
    payload: {},
  });
};
