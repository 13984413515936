import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addRefreal } from "../../actions/refrealAction";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';

import 'react-toastify/dist/ReactToastify.css';

class RefferalManagerPlan extends React.Component {

  constructor(props) {
    super(props);
    this.state = {

    };
  }

  // componentWillReceiveProps(nextProps) {
  //     if (nextProps.record) {
  //         this.setState({
  //             id: nextProps.record.id,
  //             name: nextProps.record.name,

  //         })
  //     }
  //     if (nextProps.errors) {
  //         this.setState({
  //             errors: nextProps.errors
  //         });
  //     }
  //     if (nextProps.auth !== undefined
  //         && nextProps.auth.user !== undefined
  //         && nextProps.auth.user.data !== undefined
  //         && nextProps.auth.user.data.messageU !== undefined
  //         && nextProps.auth.user.data.success) {
  //         $('#update-user-modal').modal('hide');
  //         toast(nextProps.auth.user.data.messageU, {
  //             position: toast.POSITION.TOP_CENTER
  //         });
  //     }
  // }

  onChange = e => {
      this.setState({ [e.target.name]: e.target.value });
  };

  onCountryUpdate = e => {
    e.preventDefault();
    const newCountry = {
      monthly:this.state.monthly,
      quarterly:this.state.quarterly,
      half_yearly:this.state.half_yearly,
      yearly:this.state.yearly
    };
    this.props.addRefreal(newCountry);
  };

  render() {
    const { errors } = this.state;
    return (
      <div>
        <div className="modal fade" id="refer-modal-plan" data-backdrop="static">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Prepaid Plan</h4>
                <button type="button" className="close" data-dismiss="modal">&times;</button>
              </div>
              <div className="modal-body">
                <form noValidate id="update-user" onSubmit={this.onCountryUpdate}>
                  <div className="row mt-2">
                    <div className="col-md-6">
                      <div className='form-group'>
                        <label htmlFor="name">Monthly</label>
                        <input
                          id="user-update-name"
                          type="text"
                          name='monthly'
                          onChange={this.onChange}
                          value={this.state.monthly}
                          className={classnames("form-control", {
                          })} />
                        </div>
                      {/* <span className="text-danger">{errors.name}</span> */}
                    </div>
                    <div className="col-md-6">
                      <div className='form-group'>
                        <label htmlFor="name">Quarterly</label>
                        <input
                          id="user-update-name"
                          type="text"
                          name='quarterly'
                          onChange={this.onChange}
                          value={this.state.quarterly}
                          className={classnames("form-control", {
                          })} />
                        </div>
                      {/* <span className="text-danger">{errors.name}</span> */}
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="name">Half Yearly</label>
                      <input
                        id="user-update-name"
                        type="text"
                        name='half_yearly'
                        onChange={this.onChange}
                          value={this.state.half_yearly}
                        className={classnames("form-control", {
                        })} />
                      {/* <span className="text-danger">{errors.name}</span> */}
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="name">Yearly</label>
                      <input
                        id="user-update-name"
                        type="text"
                        name='yearly'
                        onChange={this.onChange}
                          value={this.state.yearly}
                        className={classnames("form-control", {
                        })} />
                      {/* <span className="text-danger">{errors.name}</span> */}
                    </div>
                  </div>

                </form>
              </div>
              <div className="modal-footer">
                <button
                  form="update-user"
                  type="submit"
                  className="btn btn-primary">
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

RefferalManagerPlan.propTypes = {
  addRefreal: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { addRefreal }
)(withRouter(RefferalManagerPlan));
