export const GET_ERRORS = "GET_ERRORS";
export const USER_LOADING = "USER_LOADING";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const USER_ADD = "USER_ADD";
export const USER_UPDATE = "USER_UPDATE";
export const LOANTOVALUE_ADD = "LOANTOVALUE_ADD";
export const LOANTOVALUE_UPDATE = "LOANTOVALUE_UPDATE";
export const INTRESTRATE_ADD = "INTRESTRATE_ADD";
export const INTRESTRATE_UPDATE = "INTRESTRATE_UPDATE";
export const MARGINCALL_ADD = "MARGINCALL_ADD";
export const MARGINCALL_UPDATE = "MARGINCALL_UPDATE";
export const LIQUIDATIONVALUE_ADD = "LIQUIDATIONVALUE_ADD";
export const LIQUIDATIONVALUE_UPDATE = "LIQUIDATIONVALUE_UPDATE";
export const CLEAR_ERRORS = "CLEAR_ERRORS"

