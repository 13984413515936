import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateContest } from "../../actions/contestActions";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';

import 'react-toastify/dist/ReactToastify.css';

class ContestUpdateModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: this.props.record.id,
            contestLogo: this.props.record.contestLogo,
            contestName: this.props.record.contestName,
            startTime: this.props.record.startTime,
            endTime: this.props.record.endTime,
            entryFee: this.props.record.entryFee,
            virtualFund: this.props.record.virtualFund,
            errors: {},
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.record) {
            this.setState({
                id: nextProps.record.id,
                contestLogo: nextProps.record.contestLogo,
                contestName: nextProps.record.contestName,
                startTime: nextProps.record.startTime,
                endTime: nextProps.record.endTime,
                entryFee: nextProps.record.entryFee,
                virtualFund: nextProps.record.virtualFund,
                
            })
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.auth !== undefined
            && nextProps.auth.user !== undefined
            && nextProps.auth.user.data !== undefined
            && nextProps.auth.user.data.message !== undefined
            && nextProps.auth.user.data.success) {
            $('#update-user-modal').modal('hide');
            toast(nextProps.auth.user.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }

    onChange = e => {
        if (e.target.id === 'contestLogo') {
            this.setState({ name: e.target.value });
        }
       
    };

    onContestUpdate = e => {
        e.preventDefault();
        const newContest = {
            _id: this.state.id,
            contestLogo: this.state.contestLogo,
            contestName: this.state.contestName,
            startTime: this.state.startTime,
            endTime: this.state.endTime,
            entryFee: this.state.entryFee,
            virtualFund: this.state.virtualFund
   
        };
        this.props.updateContest(newContest);
    };

    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="update-user-modal">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Update Country</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onContestUpdate} id="update-user">
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.id}
                                        id="user-update-id"
                                        type="text"
                                        className="d-none"/>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="contestLogo">Logo</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.contestLogo}
                                                id="contestLogo"
                                                type="file"
                                                error={errors.contestLogo}
                                                className={classnames("form-control", {
                                                    invalid: errors.contestLogo
                                                })}/>
                                            <span className="text-danger">{errors.contestLogo}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="contestName">Contest Name</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.contestName}
                                                error={errors.contestName}
                                                id="contestName"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.contestName
                                                })}
                                            />
                                            <span className="text-danger">{errors.contestName}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="startTime">Start Time</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                autoComplete={''}
                                                onChange={this.onChange}
                                                value={this.state.startTime}
                                                error={errors.startTime}
                                                id="startTime"
                                                type="date"
                                                className={classnames("form-control", {
                                                    invalid: errors.startTime
                                                })}
                                            />
                                            <span className="text-danger">{errors.startTime}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="endTime">End Time</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                autoComplete={''}
                                                onChange={this.onChange}
                                                value={this.state.endTime}
                                                id="endTime"
                                                type="date"
                                                className={classnames("form-control", {
                                                    invalid: errors.endTime
                                                })}
                                            />
                                            <span className="text-danger">{errors.endTime}</span>
                                        </div>
                                    </div>
                                       <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="entryFee">Entry Fee</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.entryFee}
                                                id="entryFee"
                                                type="text"
                                                error={errors.entryFee}
                                                className={classnames("form-control", {
                                                    invalid: errors.entryFee
                                                })}/>
                                            <span className="text-danger">{errors.entryFee}</span>
                                        </div>
                                    </div>   <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="virtualFund">Virtual Fund</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.virtualFund}
                                                id="virtualFund"
                                                type="text"
                                                error={errors.virtualFund}
                                                className={classnames("form-control", {
                                                    invalid: errors.virtualFund
                                                })}/>
                                            <span className="text-danger">{errors.virtualFund}</span>
                                        </div>
                                    </div>   
                               
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button
                                    form="update-user"
                                    type="submit"
                                    className="btn btn-primary">
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

ContestUpdateModal.propTypes = {
    updateContest: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { updateContest }
)(withRouter(ContestUpdateModal));
