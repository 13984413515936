import axios from "axios";
import {
    GET_ERRORS,
   
} from "./types";
import * as myConstList from "./baseUrl"

const baseUrl = myConstList.baseUrl;


export const fundverifyStatus = (userData) => dispatch => {
    axios
        .post(baseUrl + "/api/fundverifyStatus-update", userData)
        .then(res =>
            dispatch({
                type: 'TRANSACTION_UPDATE',
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    );
};
export const rejectPaymentButton = (userData) => dispatch => {
    axios
        .post(baseUrl + "/api/rejectPayment-update", userData)
        .then(res =>
            dispatch({
                type: 'TRANSACTION_UPDATE',
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    );
};