import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addContest } from "../../actions/contestActions";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import $ from "jquery";

import "react-toastify/dist/ReactToastify.css";

class AddContest extends React.Component {
  constructor() {
    super();
    this.state = {
      contestLogo: "",
      contestName: "",
      startTime: "",
      endTime: "",
      entryFee: "",
      virtualFund: "",
      errors: {},
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
    if (
      nextProps.auth !== undefined &&
      nextProps.auth.user !== undefined &&
      nextProps.auth.user.data !== undefined &&
      nextProps.auth.user.data.message !== undefined
    ) {
      $("#add-user-modal").modal("hide");
      toast(nextProps.auth.user.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onContestAdd = (e) => {
    e.preventDefault();
    const newContest = {
      contestLogo: this.state.contestLogo,
      contestName: this.state.contestName,
      startTime: this.state.startTime,
      endTime: this.state.endTime,
      entryFee: this.state.entryFee,
      virtualFund: this.state.virtualFund,
    };
    this.props.addContest(newContest, this.props.history);
  };

  render() {
    const { errors } = this.state;
    return (
      <>
        <form noValidate onSubmit={this.onContestAdd} id="add-user">
          <div className="row mt-2">
            <div className="col-md-3">
              <label htmlFor="contestName">Contest Name</label>
            </div>
            <div className="col-md-9">
              <input
                onChange={this.onChange}
                value={this.state.contestName}
                error={errors.contestName}
                id="contestName"
                type="text"
                className={classnames("form-control", {
                  invalid: errors.contestName,
                })}
              />
              <span className="text-danger">{errors.contestName}</span>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-3">
              <label htmlFor="contestLogo">Logo</label>
            </div>
            <div className="col-md-9">
              <input
                onChange={this.onChange}
                value={this.state.contestLogo}
                id="contestLogo"
                type="file"
                error={errors.contestLogo}
                className={classnames("form-control", {
                  invalid: errors.contestLogo,
                })}
              />
              <span className="text-danger">{errors.contestLogo}</span>
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-md-3">
              <label htmlFor="entryFee">Entry Fee</label>
            </div>
            <div className="col-md-9">
              <input
                onChange={this.onChange}
                value={this.state.entryFee}
                id="entryFee"
                type="text"
                error={errors.entryFee}
                className={classnames("form-control", {
                  invalid: errors.entryFee,
                })}
              />
              <span className="text-danger">{errors.entryFee}</span>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-3">
              <label htmlFor="virtualFund">Virtual Fund</label>
            </div>
            <div className="col-md-9">
              <input
                onChange={this.onChange}
                value={this.state.virtualFund}
                id="virtualFund"
                type="text"
                error={errors.virtualFund}
                className={classnames("form-control", {
                  invalid: errors.virtualFund,
                })}
              />
              <span className="text-danger">{errors.virtualFund}</span>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-3">
              <label htmlFor="startTime">Start Time</label>
            </div>
            <div className="col-md-9">
              <input
                autoComplete={""}
                onChange={this.onChange}
                value={this.state.startTime}
                error={errors.startTime}
                id="startTime"
                type="date"
                className={classnames("form-control", {
                  invalid: errors.startTime,
                })}
              />
              <span className="text-danger">{errors.startTime}</span>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-3">
              <label htmlFor="endTime">End Time</label>
            </div>
            <div className="col-md-9">
              <input
                autoComplete={""}
                onChange={this.onChange}
                value={this.state.endTime}
                id="endTime"
                type="date"
                className={classnames("form-control", {
                  invalid: errors.endTime,
                })}
              />
              <span className="text-danger">{errors.endTime}</span>
            </div>
          </div>
          <div className="form-group mb-5 mt-5 float-right">
            <button type="submit" className="btn btn-dark">
              Submit
            </button>
          </div>
        </form>
      </>
    );
  }
}

AddContest.propTypes = {
  addContest: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { addContest })(withRouter(AddContest));
