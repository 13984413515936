import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import axios from "axios";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import LiquidationvalueAddModal from "../partials/LiquidationvalueAddModal";
import LiquidationvalueUpdateModal from "../partials/LiquidationvalueUpdateModal";
import { toast, ToastContainer} from "react-toastify";
import Moment from 'moment';
import $ from 'jquery';
import * as myConstList from "../../actions/baseUrl";

const baseUrl = myConstList.baseUrl

class Liquidationvalue extends Component {

    constructor(props) {
        super(props);

        this.columns = [
            {
                key: "id",
                text: "#",
                className: "id",    
                align: "left",
                sortable: true,
                cell: (row, index) => index + 1
            },
             {
                key: "currency_name",
                text: "Currency Name",
                className: "currency_name",
                align: "left",
                sortable: true
            },
            {
                key: "liquidation",
                text: "Liquidation",
                className: "liquidation",
                align: "left",
                sortable: true,
                cell: record => {
                    return (
                        <Fragment>
                          {record.liquidation}%
                        </Fragment>
                    );
                }
            },
          
            {
                key: "created_at",
                text: "Date",
                className: "created_at",
                align: "left",
                sortable: true,
                cell: record => {
                    return (
                        <Fragment>
                          {Moment(record.created_at).format('lll')}
                        </Fragment>
                    );
                }
            },
            {
                key: "action",
                text: "Action",
                className: "action",
                width: 200,
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                        <Fragment>
                            <button
                                className="btn btn-primary btn-sm"
                                onClick={() => this.historyData(record)}
                                style={{liquidationRight: '5px'}} disabled={this.state.isConfirmDisabled}>
                                History
                            </button>
                            <button
                                data-toggle="modal"
                                data-target="#update-user-modal"
                                className="btn btn-primary btn-sm"
                                onClick={() => this.editRecord(record)}
                                style={{liquidationRight: '5px'}}>
                                <i className="fa fa-edit"></i>
                            </button>
                            <button
                                className="btn btn-danger btn-sm"
                                onClick={() => this.deleteRecord(record)}>
                                <i className="fa fa-trash"></i>
                            </button>
                        </Fragment>
                    );
                }
            }
        ];

        this.config = {
            page_size: 10,
            length_menu: [ 10, 20, 50 ],
            filename: "Liquidation List",
            no_data_text: 'No record found!',
            button: {
              
                print: true,
                csv: true
            },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            records: [],
            currencyList: [],
            currentRecord: {
                id: '',
                liquidation: '',
                currency_id: '',
            },
            country_name: '',
            isConfirmDisabled:false ,
            pageTitle:'',
            loan_value:0,
            margin_call:0,
        };

        this.getData = this.getData.bind(this);
        
    }

    componentDidMount() {
        if (this.props.auth.user.liquidations !==undefined && this.props.auth.user.liquidations !==1 ) {
            this.props.history.push("/dashboard");
        }
        this.getData()
    };

    componentWillReceiveProps(nextProps) {
        if (this.props.auth.user.liquidations !==undefined && this.props.auth.user.liquidations !==1 ) {
            this.props.history.push("/dashboard");
        }
        if (nextProps.liquidationvaluedata !== undefined
            && nextProps.liquidationvaluedata.Liquidationvalue !== undefined
            && nextProps.liquidationvaluedata.Liquidationvalue.data !== undefined
            && nextProps.liquidationvaluedata.Liquidationvalue.data.message !== undefined) {
           
            $('#update-user-modal').modal('hide');
            $('#add-user-modal').modal('hide');
            toast(nextProps.liquidationvaluedata.Liquidationvalue.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            this.setState({ errors: {currencyImage:''}});
        }
        this.getData()
    }

    getData() {
        axios
            .post(baseUrl + "/api/liquidation-value-data")
            .then(res => {
                this.setState({ records: res.data})
            })
            .catch()
            
            axios
            .get(baseUrl + "/api/crypto-data")
            .then(res => {
                this.setState({ currencyList: res.data})
            })
            .catch()

            this.setState({ isConfirmDisabled:false});
            
    }

    editRecord(record) {
        if(record.currency_id!=''){
            //alert(record.currency_id);
            //this.getLiquidationLoanValue(record.currency_id);
            //this.getLiquidationMarginValue(record.currency_id);
        }
        this.setState({ currentRecord: record});
    }

    historyData(record) {
        axios
            .post(baseUrl + "/api/liquidation-value-data", {currency_id: record.currency_id})
            .then(res => {
                this.setState({ records: res.data})
                this.setState({ isConfirmDisabled:true});
                this.setState({ pageTitle:record.currency_name});
            })
            .catch()
    }

    deleteRecord(record) {
        axios
            .post(baseUrl + "/api/liquidation-value-delete", {id: record.id})
            .then(res => {
                if (res.status === 200) {
                   toast(res.data.message, {
                       position: toast.POSITION.TOP_CENTER,
                   })
                }
            })
            .catch();
        this.getData();
    }

    pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }

    render() {
        return (
            <div>
                <Navbar/>
                <div className="d-flex" id="wrapper">
                    <Sidebar />
                    <LiquidationvalueAddModal currencyList={this.state.currencyList}/>
                    <LiquidationvalueUpdateModal record={this.state.currentRecord}  currencyList={this.state.currencyList}/>
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <button className="btn btn-link mt-3" id="menu-toggle"><FontAwesomeIcon icon={faList}/></button>
                            <button className="btn btn-outline-primary float-right mt-3 mr-2" data-toggle="modal" data-target="#add-user-modal"><FontAwesomeIcon icon={faPlus}/> Add Liquidation</button>
                            <h4 className="mt-2 text-primary"><Link to="/liquidationvalue" className="list-group-item list-group-item-action">{this.state.pageTitle} Liquidation List</Link></h4>
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                onPageChange={this.pageChange.bind(this)}
                            />
                        </div>
                    </div>
                    <ToastContainer/>
                </div>
            </div>
        );
    }
}

Liquidationvalue.propTypes = {
    auth: PropTypes.object.isRequired,
    liquidationvaluedata: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    liquidationvaluedata: state.liquidationvaluedata,
    records: state.records
});

export default connect(
    mapStateToProps
)(Liquidationvalue);
