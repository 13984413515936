import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Moment from "moment";
import "react-toastify/dist/ReactToastify.css";

class UserTransferModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "this.props.user.id",
      transferHistory: [],
      dataStatus: false,
      portfolioUsdt: 0,
      portfolioInr: 0,
    };
    console.log("this.props", this.props);
  }

  componentWillReceiveProps(nextProps) {
    console.log("nextProps.currentRecordData", nextProps.buyOrderDataById);
    if (nextProps.transferHistory) {
      this.setState({
        transferHistory: nextProps.transferHistory.data,
        dataStatus: nextProps.transferHistory.status,
      });
    }
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
  }

  tableDataHtml = () => {
    // if (this.state.dataStatus == true) {
      const html = [];
      this.state.transferHistory.map((value, i) => {
        html.push(
          <tr>
            <td>{i + 1}</td>
            <td>{value.tx_type === "USER_TRANSFER_SEND" ? "Sent" : "Receive"}</td>
            <td>{value.payId}</td>
            <td>{value.coin_amount}</td>
            <td>{value.short_name}</td>

            <td>{Moment.utc(value.created).local().format("lll")}</td>
          </tr>
        );
      });
      return html;
    // }
  };

  render() {
    const { errors } = this.state;
    return (
      <div>
        <div
          className="modal fade"
          id="update-transfer-modal"
          data-reset="true"
          data-backdrop="static"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">User Transfer History</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <table className="table w-100">
                  <thead>
                    <tr>
                      <th>Sr No.</th>
                      <th>Type</th>
                      <th>AFX Pay ID</th>
                      <th>Amount</th>
                      <th>Currency</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>{this.tableDataHtml()}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

UserTransferModal.propTypes = {
  userFundHistory: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps)(withRouter(UserTransferModal));
