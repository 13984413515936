import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { verifyStatus } from "../../../actions/userActions";
import RejectButtonBi from "./RejectButtonBi";
import { withRouter } from "react-router-dom";
import axios from "axios";
import $ from 'jquery';
import { Link } from "react-router-dom";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import "react-toastify/dist/ReactToastify.css";
import { Modal, Button } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { baseUrl } from "../../../actions/baseUrl"
import RejectButtonFi from './RejectButtonFi';
class VerifyDocument2 extends React.Component {

    constructor(props) {
        super(props);
        const { user } = this.props.auth;
        this.state = {
            records: [],
            id: "",
            i_image2: '',
            identity_status2: '',
            reject: '',
            errors: {},
            type: 21,
            id: this.props.record._id,
            i_image2: this.props.record.i_image2,
            identity_status2: this.props.record.identity_status2,
            reject: this.props.record.d2_rejectResion,
            records: this.props.record
        };
        console.log("SDFLKJIOSDUFHSDU8YFGHSDFYDGFUYSDG",this.props);


    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.record) {
            console.log("nextProps.recor",nextProps.record)
            this.setState({
                id: nextProps.record._id,
                i_image2: nextProps.record.i_image2,
                identity_status2: nextProps.record.identity_status2,
                reject: nextProps.record.d2_rejectResion,
                records: nextProps.record,
            });
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.userrecords) {
            this.setState({
                tstatus: nextProps.userrecords.status
            });
        }

        // if (nextProps.auth !== undefined
        //     && nextProps.auth.user !== undefined
        //     && nextProps.auth.user.data !== undefined
        //     && nextProps.auth.user.data.message2 !== undefined
        //     && nextProps.auth.user.data.success) {
        //     toast(nextProps.auth.user.data.message2, {
        //         position: toast.POSITION.TOP_CENTER
        //     });

        // }
    }
    getNodesToRemoveFromElement = (stringContent) => {

        var div = document.createElement("div");
        div.innerHTML = stringContent;
        var text = div.textContent || div.innerText || "";
        return text;
    };
    getData() {
        const { user } = this.props.auth;
        axios
            .post(baseUrl + "/api/rejectReason-By-Id-Data", { _id: this.props.match.params.id })
            .then(res => {
                this.setState({
                    d1: res.data.d1_rejectResion,
                    d2: res.data.d2_rejectResion,
                    d3: res.data.d3_rejectResion,
                    d4: res.data.d4_rejectResion
                }, () => {
                    this.setState({ isKycModalOpen: true })
                });
                console.log("res.datares.data", res.data)

            })
            .catch()
    }

    onChange = e => {
        if (e.target.id === 'reject') {
            this.setState({ reject: e.target.value });
        }
    };
    handleCommentChange = (event, editor) => {

        var data = editor.getData();
        if (data) {
            this.setState({ description: data });
        }
        this.setState({
            showResults: this.state.name === null ? false : true
        })
    };

    onUpdate = e => {
        e.preventDefault();
        const newUser = {
            _id: this.props.match.params.id,
            type: "d1",
            type2: "d2"
        };
        setTimeout(() => {
            window.location.reload()
        }, 2000);
        this.props.verifyStatus(newUser);
    };
    closeModel = () => {
        this.setState({ isKycModalOpen: false })
    }



    render() {

        const { errors } = this.state;
        const { user } = this.state;
        return (
            <>
                {this.state.identity_status2 !== undefined &&
                    <>                    
                        <form noValidate onSubmit={this.onUpdate} id="update-user">
                            <p><b>Identity Document (Back Image): :</b> {this.state.identity_status2 == 1 ? "pending" : (this.state.identity_status2 == 2 ? "Verified" :
                                <button
                                    onClick={(e) => {
                                        e.preventDefault()
                                        this.getData()
                                    }}
                                    // to={
                                    //   "/reject-reason/" + this.props.match.params.id + "/" + 22
                                    // }
                                    type="button"
                                    className="btn btn_man"
                                >
                                    Rejected <i className="fa fa-info-circle"></i>
                                </button>
                            )}</p>
                            <a href={baseUrl + "/static/upload/" + this.state.i_image2} target="_blank"><img src={baseUrl + "/static/upload/" + this.state.i_image2} className="img-thumbnail" alt="Cinque Terre"></img></a>
                            {/* this.state.identity_status2 == 1  */}
                            {this.props.match.params.type==2 &&
                                <div style={{ display: (this.state.identity_status2 !== '2' && this.state.identity_status2 !== '3' ? 'block' : 'none') }}>
                                    <button type="submit" className='btn btn-primary'>Verify</button>
                                </div>
                            }
                        </form>
                        {console.log("console",this.state.identity_status2)}
                        {/* this.state.identity_status2 == 1  */}
                        { this.props.match.params.type==2 
                       && this.state.identity_status2 !== '2' && this.state.identity_status2 !== '3' &&
                            <RejectButtonFi record={this.state.records} docStatus={this.state.identity_status2} rejectStatus={this.state.reject} />
                        }
                        <Modal
                            show={this.state.isKycModalOpen}
                            onHide={this.closeKycModal}
                            backdrop="static"
                            keyboard={false}
                        >
                            <Modal.Body>
                                <div className="kyc_popop tc">
                                    <h2 ref={(subtitle) => (subtitle = subtitle)}>
                                        <h3 className="man_hadding">Reject Reason</h3>
                                        {this.state.type == 21 &&
                                            <p className="rejectReason">{this.state.d1}</p>
                                        }
                                        {this.state.type == 22 &&
                                            <p className="rejectReason">{this.state.d2}</p>
                                        }
                                        {this.state.type == 23 &&
                                            <p className="rejectReason">{this.state.d3}</p>
                                        }
                                        {this.state.type == 24 &&
                                            <p className="rejectReason">{this.state.d4}</p>
                                        }
                                    </h2>
                                    <div>
                                        <button className="w100px" onClick={this.closeModel}>
                                            Okay!
                                        </button>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>

                    </>
                }
            </>

        )
    }
}

VerifyDocument2.propTypes = {
    verifyStatus: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { verifyStatus }
)(withRouter(VerifyDocument2));
