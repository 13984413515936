import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";
import { baseUrl } from "../pages/baseUrl";

class StakeModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "this.props.user.id",
      stakeData: [],
      dataStatus: false,
    };
    console.log("this.props", this.props);
  }

  componentWillReceiveProps(nextProps) {
    console.log("nextProps.currentRecordsddaad Stake", nextProps.stakeInfo);
    if (nextProps.stakeInfo) {
      this.setState({
        stakeData: nextProps.stakeInfo.data,
        dataStatus: nextProps.stakeInfo.status,
      });
    }
    if (nextProps.user) {
      this.setState({
        id: nextProps.user.id,
      });
    }
  }

  tableDataHtml = () => {
    if (this.state.dataStatus == 200) {
      const html = [];
      this.state.stakeData.map((value, i) => {
        html.push(
          <tr>
            <td>{i + 1}</td>
            <td>
              <>
                {" "}
                {
                  <img
                    style={{ maxWidth: "40px" }}
                    className="mr-2"
                    src={baseUrl + "/static/currencyImage/" + value.icon}
                    alt=""
                  />
                }{" "}
                {value.short_name}
              </>
            </td>
            <td>
              {value.type === "Flexible"
                ? value.type
                : value.duration + " " + value.type}

              {/* {value.type} */}
            </td>
            <td>{value.estimate}%</td>
            <td>
              {value.lock_amount} {value.short_name}
            </td>
          </tr>
        );
      });
      return html;
    }
  };

  render() {
    const { errors } = this.state;
    return (
      <div>
        <div
          className="modal fade"
          id="update-fund-modal"
          data-reset="true"
          data-backdrop="static"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Stake Info</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <div className="portfolio-popup-outer d-flex flex-wrap justify-content-between align-items-center"></div>
                <table className="table w-100">
                  <thead>
                    <tr>
                      {/* Stack Type , Currency Name, Est. APR , MIn. Lock */}

                      <th>Sr No.</th>
                      <th>Currency Name</th>
                      <th>Stake Type</th>
                      <th>EST. APR</th>
                      <th>Min. Lock</th>
                    </tr>
                  </thead>
                  <tbody>{this.tableDataHtml()}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

StakeModal.propTypes = {
  // userFundHistory: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps)(withRouter(StakeModal));
