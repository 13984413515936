
import React, { useState, useEffect } from 'react'
import { baseUrl } from '../../actions/baseUrl'
import axios from "axios"
import { CKEditor } from "@ckeditor/ckeditor5-react";

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import $ from 'jquery';


function UpdateTradingStepModal(props) {
  // ClassicEditor.builtinPlugins = [
  //   ...ClassicEditor.builtinPlugins,
  //   Font, // Add the Font plugin
  // ];
  //console.log("props", props.currentRecord)
  const [id, setId] = useState("")
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("");
  const [language, setLanguage] = useState("")
  const [image, setImage] = useState("");
  const [imagePreview, setImagePreview] = useState("");

  const [descriptionErr, setDescriptionErr] = useState("");
  const [imageErr, setImageErr] = useState("")
  const [titleErr, setTitleErr] = useState("");
  const [position, setPosition] = useState("");


  const ckOption = {
    options: [
      {
        model: "paragraph",
        title: "Paragraph",
        class: "ck-heading_paragraph",
      },
      {
        model: "heading1",
        view: "h1",
        title: "Heading 1",
        class: "ck-heading_heading1",
      },
      {
        model: "heading2",
        view: "h2",
        title: "Heading 2",
        class: "ck-heading_heading2",
      },
      {
        model: "heading3",
        view: "h3",
        title: "Heading 3",
        class: "ck-heading_heading3",
      },
    ],
  };
  const ckConfig = {
    toolbar: {
      items: [
        "heading",
        "|",
        "bold",
        "italic",
        "link",
        "bulletedList",
        "numberedList",
        "blockQuote",
        "fontFamily",
        "fontSize",
      ]
    },
    heading: ckOption,
    fontFamily: {
      options: [
        "default",
        "Arial, Helvetica, sans-serif",
        "Courier New, Courier, monospace",
        "Georgia, serif",
        "Lucida Sans Unicode, Lucida Grande, sans-serif",
        "Tahoma, Geneva, sans-serif",
        "Times New Roman, Times, serif",
        "Verdana, Geneva, sans-serif",
      ],
      supportAllValues: true,
    },
    fontSize: {
      options: [
        "tiny",
        "small",
        "default",
        "big",
        "huge",
      ],
      supportAllValues: true, 
      // You can define custom sizes
    },

  };

  useEffect(() => {
    setDescription(props.currentRecord?.description ? props.currentRecord?.description : "");
    setLanguage(props.currentRecord?.lang ? props.currentRecord?.lang : "");
    setTitle(props.currentRecord.title);
    setImage(props.currentRecord.image);
    setId(props.currentRecord.id);
    setPosition(props.currentRecord.position);
    setImagePreview(baseUrl + "/static/front/" + props.currentRecord.image)
    editorhtml()

  }, [props])

  const handleFileChange = (e) => {
    setImage(e.target.files[0]); // Store the file in state
    setImagePreview(URL.createObjectURL(e.target.files[0]))
  };

  const handleRemoveImage = (e) => {
    setImage(null); // Store the file in state
  };

  const handleChange = (e) => {
    const { name, value } = e.target
    console.log(e.target);
    if (name === "title") {
      setTitle(value);
      if (value == "") {
        setTitleErr("This field is required");
        return false;
      }
      setTitleErr("");
    }

    if (name === "description") {
      setDescription(value);
      if (value == "") {
        //console.log('title--->',title);
        setDescriptionErr("This field is required");
        return false;
      }
      setDescriptionErr("");
    }

    if (name === "image") {
      setImage(value);
      setImagePreview(URL.createObjectURL(e.target.files[0]))
      if (value === "") {
        //console.log('title--->',title)
        setImageErr("This field is required");
        return false;
      }
      setImageErr("");
    }
  }

  const editorhtml = () =>{
    ClassicEditor
  .create(document.querySelector('#editor'), {
    extraPlugins: [
      function CustomDataProcessor(editor) {
        editor.data.processor = {
          toView: (data) => {
            // Convert data (HTML -> View)
            const div = document.createElement("div");
            div.innerHTML = data;
            return editor.data.htmlProcessor.toView(div.innerHTML);
          },
          toData: (viewFragment) => {
            // Convert view (Editor content -> HTML)
            const data = editor.data.htmlProcessor.toData(viewFragment);
            return `<custom-wrapper>${data}</custom-wrapper>`;
          },
        };
      },
    ],
  })
  .then((editor) => {
    console.log("Editor with custom data processor is ready.", editor);
  })
  .catch((error) => {
    console.error(error);
  });
  }


  const onChange = (e) => {
    setLanguage(e.target.value);
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (title === "") {
      setTitleErr("This field is required");
      return false;
    }
    if (description === "") {
      setDescriptionErr("This field is required");
      return false;
    }
    if (image === "") {
      setImageErr("This field is required");
      return false;
    }
    //console.log('description----->',description)
    const formData = new FormData();
    formData.append('id', id);
    formData.append('title', title);
    formData.append('lang', language);
    formData.append('description', description);
    formData.append('image', image);
    //console.log('formData--->',formData);
    axios.post(baseUrl + "/api/update_front_text", formData).then((res) => {
      //console.log('res------->', res.data)
      if (res.data) {
        //console.log('res----1--->', res)
        props.setRefresh(!props.refresh)
        $('#update-user-modal').modal('hide');
        //setDate("")
        setTitle("")
        setDescription("")
        setImage("")
        setLanguage("")

      }
    }).catch((err) => {

    })
  }
  return (
    <div>
      <div className="modal fade" id="update-user-modal">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Edit Front Text</h4>
              <button type="button" className="close" data-dismiss="modal">&times;</button>
            </div>
            <div className="modal-body">
              <form id="update-user1" onSubmit={handleSubmit}>
                <div className='mb-3'>
                  Position: <label className='form-label' style={{ textTransform: "capitalize", fontSize: "20px", fontWeight: "600" }}>{position}</label>
                </div>

                {/* <div className='mb-3'>
                  <label className='form-label'>Language:</label>
                  <select onChange={onChange} value={language} name="lang" className="form-select w-100 form-control">

                    <option value="en">English</option>
                    <option value="ko">Korean</option>
                    <option value="rus">Russian</option>
                    <option value="per">Persian</option>
                    <option value="ar">Arabic</option>


                  </select>
                </div> */}

                <div className='mb-3'>
                  <label className='form-label'>Title:</label>

                  <CKEditor
                    editor={ClassicEditor}
                    data={title}
                    config={ckConfig}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setTitle(data);
                      // setState({ ...state, body: data })
                    }} />

                  {/* <textarea name="title" value={title} onChange={handleChange} className='form-control' /> */}
                  <span style={{ color: "red" }}>{titleErr}</span>
                </div>

                <div ></div>

                <div className='mb-3'>
                  <label className='form-label'>Description:</label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={description}
                    config={ckConfig}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setDescription(data);
                      // setState({ ...state, body: data })
                    }} />

                  {/* <textarea name="description" value={description} onChange={handleChange} className='form-control' /> */}
                  <span style={{ color: "red" }}>{descriptionErr}</span>
                </div>


                {/* <div className='mb-3'>
                    <label className='form-label'>Select Image:</label>
                    <input 
                        type="file" 
                        accept="image/*" 
                        onChange={handleFileChange} 
                        className='form-control' 
                    />
                    <span style={{ color: "red" }}>{imageErr}</span>
                </div> */}

                {image ? (
                  <div>
                    <img
                      src={imagePreview}
                      alt="Uploaded Preview"

                      style={{ width: '200px', height: '200px', objectFit: 'cover', marginBottom: '10px' }}
                    />
                    <button
                      type="button"
                      onClick={handleRemoveImage} // Reset the image preview
                      className="btn btn-danger"
                    >
                      Remove Image
                    </button>
                  </div>
                ) : (
                  <div>
                    <label className="form-label">Select Image:</label>
                    <input
                      type="file"
                      accept="image/*"
                      name='image'
                      onChange={handleFileChange}
                      className="form-control"
                    />
                    {imageErr && <span style={{ color: 'red' }}>{imageErr}</span>}
                  </div>
                )}
              </form>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
              <button
                form="update-user1"
                type="submit"
                className="btn btn-primary">
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UpdateTradingStepModal
