import React, { useState, useEffect } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import ReactDatatable from "@mkikets/react-datatable";
import axios from "axios";
import AddFrontTextModal from "../partials/AddFrontTextModal";
import { toast, ToastContainer } from "react-toastify";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { baseUrl } from "../../actions/baseUrl";
import UpdateFrontTextModal from "../partials/UpdateFrontTextModal";
function Front_text() {
  const [refresh, setRefresh] = useState(false);
  const [record, setRecord] = useState([]);
  const [image, setImage] = useState("");
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [currentRecord, setCurrentRecord] = useState({});
  useEffect(() => {
    getData();
  }, [refresh]);

  const getData = () => {
    axios
      .get(baseUrl + "/api/get_front_text")
      .then((res) => {
        //console.log('et_front_text',res.data)
        if (res.data && res.data.length > 0) {
          setRecord(res.data);
        } else {
          setRecord([]);
        }
      })
      .catch((err) => {});
  };
  const columns = [
    {
      // key: "id",
      text: "#",
      className: "id",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "position",
      text: "Position",
      className: "title",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            <img src={baseUrl + "/static/front/" + record.image} style={{height:"70px"}}/>
          
          </>
        );
      },
    },
    {
      key: "position",
      text: "Position",
      className: "title",
      align: "left",
      sortable: true,
    },
    {
      key: "title",
      text: "Title",
      className: "title",
      align: "left",
      sortable: true,
    },
    {
      key: "description",
      text: "Description",
      className: "title",
      align: "left",
      sortable: true,
    },
    // {
    //   key: "lang",
    //   text: "Language",
    //   className: "title",
    //   align: "left",
    //   sortable: true,
    // },
    {
      key: "action",
      text: "Action",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <>
            <button
              data-toggle="modal"
              data-target="#update-user-modal"
              className="btn btn-primary btn-sm"
              onClick={() => editRecord(record)}
              style={{ marginRight: "5px" }}
            >
              <i className="fa fa-edit"></i>
            </button>
          
          </>
        );
      },
    },
  ];

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Buy Order List",
    no_data_text: "No user found!",
    button: {
      print: true,
      csv: true,
    },
    language: {
      length_menu: "Show _MENU_ result per page",
      filter: "Filter in records...",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };
  const deleteRecordMgs = (record) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Really want to delete this text?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            deleteRecord(record);
          },
        },
        {
          label: "No",
        },
      ],
    });
  };
  const deleteRecord = (record) => {
    axios
      .post(baseUrl + "/api/delete_front_text", { id: record.id })
      .then((res) => {
        //console.log('res---->', res)
        if (res.status === 200) {
          //console.log('res---->', res)
          setRefresh(!refresh);
          // toast(res.data, {
          //     position: toast.POSITION.TOP_CENTER,
          // })
        }
      })
      .catch();
  };
  const editRecord = (record) => {
    setCurrentRecord({
      id: record.id,
      title: record.title,
      lang: record.lang,
      description: record.description,
      image: record.image,
      position: record.position
    });
  };

  return (
    <div>
      <Navbar />
      <div className="" id="wrapper">
        <Sidebar />

        <div id="page-content-wrapper">
          <div className="container-fluid">
            <button className="btn btn-link mt-3" id="menu-toggle">
              <FontAwesomeIcon icon={faList} />
            </button>

            <AddFrontTextModal setRefresh={setRefresh} refresh={refresh} />
            <UpdateFrontTextModal
              setRefresh={setRefresh}
              refresh={refresh}
              currentRecord={currentRecord}
            />
            <h4 className="mt-2 man_hadding mb-3">Front Text</h4>
            <div className="white_box">
              {/* <button
                className="btn btn-primary float-right"
                data-toggle="modal"
                data-target="#add-user-modal"
              >
                <FontAwesomeIcon icon={faPlus} /> Add{" "}
              </button> */}
              <ReactDatatable
                config={config}
                records={record}
                columns={columns}
              />
            </div>
          </div>

          <ToastContainer />
        </div>
        <div className="modal fade" id="view-user-modal">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Blog</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <div>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <img
                        className="img-fluid"
                        src={baseUrl + "/static/blog/" + image}
                      />
                    </div>
                    <div className="col-md-12">
                      <h5>{title}</h5>
                      {/* <p>{description}</p> */}
                      <div
                        dangerouslySetInnerHTML={{ __html: description }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Front_text;
