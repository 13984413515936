import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { replyForAdmin } from "../../actions/updateHelp";
import { withRouter } from "react-router-dom";
import axios from "axios";
import $ from 'jquery';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer} from "react-toastify";
class EditContestRecord extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      records: [],
      getalls: this.props.getalls,
      id: '',
      contestLogo: '',
      contestName: '',
      startTime: '',
      endTime: '',
      entryFee: '',
      virtualFund: '',
      tstatus: '',
      winningAmount: '',
      description: '',
      invalidImage: '',
      pair_id:[],
      message: '',
      values: [],
      errors: {}
    };
   
    this.getData = this.getData.bind(this);
  }

  
  componentWillReceiveProps(nextProps) {
      
     
      console.log('nextProps.records'); console.log(nextProps.records);
      if (nextProps.records) {
        this.setState({
          contestName: this.props.record.contestName,
          description : this.props.record.description,
          endTime: this.props.recordendTime,
          startTime: this.props.record.startTime,
          entryFee: this.props.record.entryFee,
          virtualFund: this.props.record.virtualFund,
          winningAmount: this.props.record.winningAmount,
          winningLevel: this.props.record.winningLevel,
          tstatus: this.props.record.winningLevel,
          pair_id: this.props.record.pair_id,
          levels: this.props.record.levels,
          winningAmount: this.props.record.winningAmount
        });
      }
      if (nextProps.errors) {
        this.setState({
          errors: nextProps.errors
        });
      }
      if (nextProps.auth !== undefined
        && nextProps.auth.user !== undefined
        && nextProps.auth.user.data !== undefined
        && nextProps.auth.user.data.message !== undefined
        && nextProps.auth.user.data.success) {
        toast(nextProps.auth.user.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
        
    }
    }
    componentDidMount() {
   
  };
    onChange = e => {
      if (e.target.id === 'contestName') {
       this.setState({ contestName: e.target.value });
      }
      if (e.target.id === 'entryFee') {
        this.setState({ entryFee: e.target.value });
       }
       if (e.target.id === 'virtualFund') {
        this.setState({ virtualFund: e.target.value });
       }
       if (e.target.id === 'startTime') {
        this.setState({ startTime: e.target.value });
       }
       if (e.target.id === 'endTime') {
        this.setState({ endTime: e.target.value });
       }
       if (e.target.id === 'winningAmount') {
        this.setState({ winningAmount: e.target.value });
       }
      if (e.target.id === 'tstatus') {
        this.setState({ values: '' });
        for (var i = 0; i < e.target.value; i++) {
          this.addClick();
        }
        this.setState({ tstatus: e.target.value });
      }
      if (e.target.id === 'contestLogo') {
        var contestLogo = e.target.files[0];
        
      console.log('root');
      
        var fileSize = e.target.files[0].size;
        if (fileSize > 1000000) {
            this.setState({ invalidImage: "Maximum 1 MB Image allowed.", firstSubmitIsDisabled: true, userUploadImage: false });
            return false;
        }
        console.log(this.state.invalidImage);
        if (!contestLogo.name.match(/\.(jpg|jpeg|png|gif)$/)) {
            this.setState({ invalidImage: "Please select valid Front image jpeg,png,gif.", firstSubmitIsDisabled: true, userUploadImage: false });
            return false;
        }
        console.log(contestLogo);
        this.setState({
          contestLogo: contestLogo,
  
        });
    }
      this.setState({ [e.target.id]: e.target.value });
    };
  
    getData() {
      axios
        .post("/api/pair-data")
        .then(res => {
          this.setState({ records: res.data })
        })
        .catch()
    }
    componentDidMount() {
      this.getData()
    };
  
    componentWillReceiveProps(nextProps) {
      this.getData()
    }
    createUI() {
     
      return this.state.values.map((el, i) =>
        <div key={i} className="row mt-2">
  
          <div className="col-md-3">
            <label htmlFor="entryFee">Level {i + 1} </label>
          </div>
          <div className="col-md-9">
            <input type="text" id={"l" + (i + 1)} value={el || ''} onChange={this.handleChange.bind(this, i)} />
          </div>
  
        </div>
      )
    }
    addClick() {
      this.setState(prevState => ({ values: [...prevState.values, ''] }))
    }
  
    handleChange(i, event) {
      let values = [...this.state.values];
      values[i] = event.target.value;
      this.setState({ values });
    }
    handleChangePair(i, event) {
      let pair_id = [...this.state.pair_id];
      pair_id[i] = event.target.value;
      this.setState({ pair_id });
    }
    handleCommentChange = (event, editor) => {
  
      var data = editor.getData();
      if (data) {
          this.setState({ description: data });
      }
     
  };
  
    onContestAdd = e => {
      e.preventDefault();
      const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
      }
      const formData = new FormData();
      formData.append('contestLogo',this.state.contestLogo);
      formData.append('contestName',this.state.contestName);
      formData.append('startTime',this.state.startTime);
      formData.append('endTime',this.state.endTime);
      formData.append('entryFee',this.state.entryFee);
      formData.append('virtualFund',this.state.virtualFund);
      formData.append('winningLevel',this.state.tstatus);
      formData.append('winningAmount',this.state.winningAmount);
      formData.append('description',this.state.description);
      formData.append('pair_id',this.state.pair_id);
      formData.append('levels',this.state.values);
     
    
   
      this.props.addContest(formData,config);
      
    
    };
    createPairUI() {
  
      return this.state.records.map((el, i) =>
  
        <div key={i} className="row mt-2">
  
  
          <div className="col-md-3">
            <label htmlFor="entryFee"> {i + 1 + ") "}  {el.box1 + " <=> "}  {el.box2} </label>
          </div>
          <div className="col-md-9">
            <input type="checkbox" id={"p" + (i + 1)} value={el._id} onChange={this.handleChangePair.bind(this, i)}/>
          </div>
  
        </div>
      )
    }

   
    render() {
      console.log('suraj');
      console.log(this.state.getalls);
        const { errors } = this.state;
        const { user } = this.props.auth;
        return (
          <>
          <div className="in_page row" >
            <br />
            <div className="col-md-2" ></div>
            <div className="col-md-8" >
              <div className=" padding_d" >
                <ul className="nav nav_left_tab">
                  <li className="nav-item">
                    <a className="active show" data-toggle="tab" href="#details">
                      <i className="la la-mobile-phone l_i"></i>
                      Details
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="" data-toggle="tab" href="#prizeMoney">
                      <i className="la la-user l_i"></i>
                      Prize Money
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="" data-toggle="tab" href="#paid">
                      <i className="la la-map-marker l_i"></i>
                      Paid
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="" data-toggle="tab" href="#contestRule">
                      <i className="la la-map-marker l_i"></i>
                      Contest Rule
                    </a>
                  </li>
                </ul>
                <hr />
                <form noValidate onSubmit={this.onContestAdd} id="add-user">
                <div className="white_box">
                  <div className="tab-content ">
                   
                      <div className="tab-pane active show" id="details">

                        <div className="row mt-2">
                          <div className="col-md-3">
                            <label htmlFor="contestName">Contest Name</label>
                          </div>
                          <div className="col-md-9">
                            <input
                              onChange={this.onChange}
                              value={this.state.contestName}
                              error={errors.contestName}
                              id="contestName"
                              type="text"
                              className={classnames("form-control", {
                                invalid: errors.contestName
                              })}
                            />
                            <span className="text-danger">{errors.contestName}</span>
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-md-3">
                            <label htmlFor="contestLogo">Logo</label>
                          </div>
                          <div className="col-md-9">
                            <input
                              onChange={this.onChange}
                              value={this.state.contestLogo}
                              id="contestLogo"
                              type="file"
                              error={errors.contestLogo}
                              className={classnames("form-control", {
                                invalid: errors.contestLogo
                              })} />
                            <span className="text-danger">{errors.contestLogo}</span>
                          </div>
                        </div>

                        <div className="row mt-2">
                          <div className="col-md-3">
                            <label htmlFor="entryFee">Entry Fee</label>
                          </div>
                          <div className="col-md-9">
                            <input
                              onChange={this.onChange}
                              value={this.state.entryFee}
                              id="entryFee"
                              type="text"
                              error={errors.entryFee}
                              className={classnames("form-control", {
                                invalid: errors.entryFee
                              })} />
                            <span className="text-danger">{errors.entryFee}</span>
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-md-3">
                            <label htmlFor="virtualFund">Virtual Fund</label>
                          </div>
                          <div className="col-md-9">
                            <input
                              onChange={this.onChange}
                              value={this.state.virtualFund}
                              id="virtualFund"
                              type="text"
                              error={errors.virtualFund}
                              className={classnames("form-control", {
                                invalid: errors.virtualFund
                              })} />
                            <span className="text-danger">{errors.virtualFund}</span>
                          </div>

                        </div>
                        <div className="row mt-2">
                          <div className="col-md-3">
                            <label htmlFor="startTime">Start Time</label>
                          </div>
                          <div className="col-md-9">
                            <input
                              autoComplete={''}
                              onChange={this.onChange}
                              value={this.state.startTime}
                              error={errors.startTime}
                              id="startTime"
                              type="date"
                              className={classnames("form-control", {
                                invalid: errors.startTime
                              })}
                            />
                            <span className="text-danger">{errors.startTime}</span>
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-md-3">
                            <label htmlFor="endTime">End Time</label>
                          </div>
                          <div className="col-md-9">
                            <input
                              autoComplete={''}
                              onChange={this.onChange}
                              value={this.state.endTime}
                              id="endTime"
                              type="date"
                              className={classnames("form-control", {
                                invalid: errors.endTime
                              })}
                            />
                            <span className="text-danger">{errors.endTime}</span>
                          </div>
                        </div>
                      
                      </div>
                      <div className="tab-pane" id="prizeMoney">
                        <div className="row mt-2">
                          <div className="col-md-3">
                            <label htmlFor="winningAmount">Winning Amount</label>
                          </div>
                          <div className="col-md-9">
                            <input
                              onChange={this.onChange}
                              value={this.state.winningAmount}

                              id="winningAmount"
                              type="text"
                              className="form-control"
                            />
                            {/* <span className="text-danger">{errors.contestName}</span> */}
                          </div>
                        </div>

                        <div className="row mt-2">
                          <div className="col-md-3">
                            <label htmlFor="entryFee">Winning Level</label>
                          </div>
                          <div className="col-md-9">
                            <select class="browser-default custom-select" id="tstatus" onChange={this.onChange} value={this.state.tstatus}>

                              <option value="1">Level 1</option>
                              <option value="2">Level 2</option>
                              <option value="3">Level 3</option>
                              <option value="4">Level 4</option>
                              <option value="5">Level 5</option>
                              <option value="6">Level 6</option>
                              <option value="7">Level 7</option>
                              <option value="8">Level 8</option>
                              <option value="9">Level 9</option>
                              <option value="10">Level 10</option>
                              <option value="11">Level 11</option>
                              <option value="12">Level 12</option>
                              <option value="13">Level 13</option>
                              <option value="14">Level 14</option>
                              <option value="15">Level 15</option>
                              <option value="16">Level 16</option>
                              <option value="17">Level 17</option>
                              <option value="18">Level 18</option>
                              <option value="19">Level 19</option>
                              <option value="20">Level 20</option>
                            </select>

                            {/* <span className="text-danger">{errors.entryFee}</span> */}
                          </div>
                        </div>

                        {this.createUI()}

                       
                      </div>
                      <div className="tab-pane" id="paid">
                        {this.createPairUI()}
                      </div>
                      <div className="tab-pane " id="contestRule">
                        <div className="row mt-2">
                          <div className="col-md-12">
                            <label htmlFor="contestName">Write Contest Rule :-</label>
                          </div>
                          <div className="col-md-12">
                            <CKEditor editor={ClassicEditor}
                              value={this.state.description}
                              onChange={this.handleCommentChange}
                              placeholder="Enter Description"
                              className="form-control form_control_textarea"
                              data={this.state.description} />
                            <br />
                            <span className="text-danger">{errors.contestName}</span>
                          </div>
                        </div>
                        <div className="form-group mb-5 mt-2 float-right">
                          <button type="submit" className="btn btn-dark">Submit</button>
                        </div>
                      </div>
                  
                  </div>

                </div>
                </form>
              </div>
            </div>
          </div>
   
      </>


        )
    }
}

EditContestRecord.propTypes = {
    replyForAdmin: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { replyForAdmin }
)(withRouter(EditContestRecord));
