import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateCountry } from "../../actions/countryActions";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';

import 'react-toastify/dist/ReactToastify.css';

class RefferalManagerShare extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  // componentWillReceiveProps(nextProps) {
  //     if (nextProps.record) {
  //         this.setState({
  //             id: nextProps.record.id,
  //             name: nextProps.record.name,

  //         })
  //     }
  //     if (nextProps.errors) {
  //         this.setState({
  //             errors: nextProps.errors
  //         });
  //     }
  //     if (nextProps.auth !== undefined
  //         && nextProps.auth.user !== undefined
  //         && nextProps.auth.user.data !== undefined
  //         && nextProps.auth.user.data.messageU !== undefined
  //         && nextProps.auth.user.data.success) {
  //         $('#update-user-modal').modal('hide');
  //         toast(nextProps.auth.user.data.messageU, {
  //             position: toast.POSITION.TOP_CENTER
  //         });
  //     }
  // }

  onChange = e => {
    if (e.target.id === 'user-update-name') {
      this.setState({ name: e.target.value });
    }

  };

  onCountryUpdate = e => {
    e.preventDefault();
    const newCountry = {
      _id: this.state.id,
      name: this.state.name,

    };
    this.props.updateCountry(newCountry);
  };

  render() {
    const { errors } = this.state;
    return (
      <div>
        <div className="modal fade" id="refer-modal-share" data-backdrop="static">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Reffer Share</h4>
                <button type="button" className="close" data-dismiss="modal">&times;</button>
              </div>
              <div className="modal-body">
                <form noValidate id="update-user">
                  <div className="row mt-2">
                    <div className="col-md-6">
                      <div className='form-group'>
                        <label htmlFor="name">Upline</label>
                        <input
                          id="user-update-name"
                          type="text"
                          className={classnames("form-control", {})} />
                      </div>
                      {/* <span className="text-danger">{errors.name}</span> */}
                    </div>
                    <div className="col-md-6">
                      <div className='form-group'>
                        <label htmlFor="name">Sub Admin</label>
                        <input
                          id="user-update-name"
                          type="text"
                          className={classnames("form-control", {})} />
                      </div>
                      {/* <span className="text-danger">{errors.name}</span> */}
                    </div>
                    <div className="col-md-12">
                      <div className='form-group'>
                        <label htmlFor="name">Admin</label>
                        <input
                          id="user-update-name"
                          type="text"
                          className={classnames("form-control", {})} />
                      </div>
                      {/* <span className="text-danger">{errors.name}</span> */}
                    </div>
                  </div>

                </form>
              </div>
              <div className="modal-footer">
                <button
                  form="update-user"
                  type="submit"
                  className="btn btn-primary">
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

RefferalManagerShare.propTypes = {
  // updateCountry: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { updateCountry }
)(withRouter(RefferalManagerShare));
