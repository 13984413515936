import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addCredential } from "../../../actions/credentialActions";
import { withRouter } from "react-router-dom";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import { toast } from 'react-toastify';
import $ from 'jquery';

import 'react-toastify/dist/ReactToastify.css';

const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

class CredentialsAddModal extends React.Component {
    constructor() {
        super();
        this.state = {
            exchange: "",
            pair_ids: "",
            api_key: "",
            secrate_key: "",
            errors: {},
            pairList: []
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.pairList) {
            console.log('nextProps.pairList', nextProps.pairList)
            let pairLists = []
            nextProps.pairList.forEach(e => {
                pairLists.push({
                    label: e.name1+'/'+e.name2,
                    value: e.id
                })
            });
            this.setState({
                pairList: pairLists
            })
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        /*  if (nextProps.credentialdata !== undefined
             && nextProps.credentialdata.pair !== undefined
             && nextProps.credentialdata.pair.data !== undefined
             && nextProps.credentialdata.pair.data.message !== undefined) {
             
             this.setState({
                 errors:{
                
                 },
                 box1: "",
                 box2:"",
                 message:""
             });
         } */
    }

    onChange = e => {
        this.setState({ errors: {} });
        this.setState({ [e.target.name]: e.target.value });
    };

    handleChangeSelect = (e) => {
        this.setState({
            pair_ids: e
        });
    }
    
    onCredentialAdd = e => {
        e.preventDefault();
        const newCredential = {
            pair_ids: this.state.pair_ids,
            exchange: this.state.exchange,
            api_key: this.state.api_key,
            secrate_key: this.state.secrate_key,
        };
        console.log("newCredential",newCredential);
        this.props.addCredential(newCredential, this.props.history);
    };
    
    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="add-user-modal" data-reset="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Add Credential</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onCredentialAdd} id="add-user">
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="box1">Exchange</label>
                                        </div>
                                        <div className="col-md-9">
                                            <select className="form-control" id="exchange" name="exchange" value={this.state.exchange} error={errors.exchange} onChange={this.onChange}>
                                                <option>Please select exchange</option>
                                                <option value="binance">Binance</option>
                                            </select>
                                            <span className="text-danger">{errors.exchange}</span>
                                            <span className="text-danger">{errors.message}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="box1">Pair</label>
                                        </div>
                                        <div className="col-md-9">
                                            <ReactSelect
                                                options={this.state.pairList}
                                                isMulti
                                                closeMenuOnSelect={false}
                                                hideSelectedOptions={false}
                                                components={{
                                                    Option
                                                }}
                                                onChange={this.handleChangeSelect}
                                                allowSelectAll={true}
                                                id="pair_ids" 
                                                name="pair_ids"
                                                value={this.state.pair_ids}
                                                error={errors.pair_ids} 
                                            />
                                            <span className="text-danger">{errors.pair_ids}</span>
                                            <span className="text-danger">{errors.message}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="api_key">API Key</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.api_key}
                                                id="api_key"
                                                name="api_key"
                                                type="text"
                                                error={errors.api_key}
                                                className={classnames("form-control", {
                                                    invalid: errors.api_key
                                                })} />
                                            <span className="text-danger">{errors.api_key}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="secrate_key">Secrate Key</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.secrate_key}
                                                id="secrate_key"
                                                name="secrate_key"
                                                type="text"
                                                error={errors.secrate_key}
                                                className={classnames("form-control", {
                                                    invalid: errors.secrate_key
                                                })} />
                                            <span className="text-danger">{errors.secrate_key}</span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button
                                    form="add-user"
                                    type="submit"
                                    className="btn btn-primary">
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

CredentialsAddModal.propTypes = {
    addCredentials: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    credentialdata: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    credentialdata: state.credentialdata,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { addCredential }
)(withRouter(CredentialsAddModal));
