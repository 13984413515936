const isEmpty = require("is-empty");
const initialState = {
    isAuthenticated: false,
    Intrestrate: {},
    loading: false
};
export default function(state = initialState, action) {
    switch (action.type) {

        case 'INTRESTRATE_ADD':
        return {
                isAuthenticated: !isEmpty(action.payload),
                Intrestrate: action.payload
        };

        case 'INTRESTRATE_UPDATE':
            return {
                isAuthenticated: !isEmpty(action.payload),
                Intrestrate: action.payload,
            };
        case 'SET_CURRENT_INTRESTRATE':
            return {
                ...state,
                isAuthenticated: !isEmpty(action.payload),
                Intrestrate: action.payload
            };

        case 'INTRESTRATE_LOADING':
            return {
                ...state,
                loading: true
            };
        default:
            return state;
    }
}