import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateCountry } from "../../actions/countryActions";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';

import 'react-toastify/dist/ReactToastify.css';
import { baseUrl } from '../../actions/baseUrl';
import Moment from 'moment';
import axios from "axios";
class WithdrawalINRApprove extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      id: this.props.record.id,
      approve: this.props.record.approve,
      status: this.props.record.WithdrawlRequest,
      errors: {},
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.record) {
      this.setState({
        id: nextProps.record.id,
        email: nextProps.record.email,
        amount: nextProps.record.coin_amount,
        approve: nextProps.record.approve,
        status: nextProps.record.WithdrawlRequest,

      })
    }
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
    if (nextProps.auth !== undefined
      && nextProps.auth.user !== undefined
      && nextProps.auth.user.data !== undefined
      && nextProps.auth.user.data.messageU !== undefined
      && nextProps.auth.user.data.success) {

      toast(nextProps.auth.user.data.messageU, {
        position: toast.POSITION.TOP_CENTER
      });
    }
  }

  onChange = e => {
    if (e.target.id === 'user-update-approve') {
      this.setState({ approve: e.target.value });
    }

  };

  onUpdateApprove = e => {
    e.preventDefault();
    axios
      .post(baseUrl + "/api/InrStatus-update", { id: this.state.id, type: "1", approve: this.state.approve, email: this.state.email, amount: this.state.amount })
      .then(res => {
        if (res.status === 200) {
          $('#update-user-modal-approve').modal('hide');
          toast(res.data.message, {
            position: toast.POSITION.TOP_CENTER,
          })
          setTimeout(function () {
            window.location.reload();
          }, 3000);
        }
      })
      .catch();

  };

  render() {
    const { errors } = this.state;
    return (
      <div>
        <div className="modal fade" id="update-user-modal-approve" data-backdrop="static">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Withdrawal Request</h4>
                <button type="button" className="close" data-dismiss="modal">&times;</button>
              </div>
              <div className="modal-body">
                <form noValidate onSubmit={this.onUpdateApprove} id="update-user-approve">
                  <input
                    onChange={this.onChange}
                    value={this.state.id}
                    id="user-update-id"
                    type="text"
                    className="d-none" />
                  <div className="row">
                    <div className="col-md-12">
                      <label htmlFor="name">Approve Text</label>
                      <textarea
                        onChange={this.onChange}
                        value={this.state.approve}
                        id="user-update-approve"
                        type="text"
                        error={errors.approve}
                        className={classnames("form-control", {
                          invalid: errors.approve
                        })} />
                      <span className="text-danger">{errors.approve}</span>
                    </div>
                  </div>

                </form>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>

                {this.state.status == "0" &&
                  <button
                    form="update-user-approve"
                    type="submit"
                    className="btn btn-primary">
                    Update
                  </button>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

WithdrawalINRApprove.propTypes = {
  updateCountry: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,

)(withRouter(WithdrawalINRApprove));
