import axios from "axios";
import {
    GET_ERRORS,
    USER_ADD,
    USER_UPDATE
} from "./types";
import * as myConstList from "./baseUrl"

const baseUrl = myConstList.baseUrl;

export const addNews = (CryptoData, history) => dispatch => {
    console.log("CryptoData",CryptoData)
    axios
        .post(baseUrl + "/api/bankinfo-add", CryptoData)
        .then(res =>
            dispatch({
                type: 'NEWS_ADD',
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    );
};


export const updateNews = (CryptoData) => dispatch => {
    axios
        .post(baseUrl + "/api/bankinfo-update", CryptoData)
        .then(res =>
            dispatch({
                type: 'NEWS_UPDATE',
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    );
};
