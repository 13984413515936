import axios from "axios";
import {
    GET_ERRORS,
    USER_ADD,
    USER_UPDATE
} from "./types";
import * as myConstList from "./baseUrl"

const baseUrl = myConstList.baseUrl;

export const addRefreal = (countryData, history) => dispatch => {
    axios
        .post(baseUrl + "/api/refreal-add", countryData)
        .then(res =>
            dispatch({
                type: USER_ADD,
                payload: res,
            })
        ).catch(err =>
         dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    );
};


export const updateRefreal = (countryData) => dispatch => {
    axios
        .post(baseUrl + "/api/refreal-update", countryData)
        .then(res =>
            dispatch({
                type: USER_UPDATE,
                payload: res,
            })
        ).catch(err =>{
        dispatch({
            type: GET_ERRORS,
            payload: err.response
        })
        }
    )
};
