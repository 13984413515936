import { fas } from '@fortawesome/free-solid-svg-icons'
import React, { useState } from 'react'
import { baseUrl } from '../../actions/baseUrl'
import axios from "axios"
import $ from 'jquery';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

function AddPopupModal(props) {
  const [date, setDate] = useState("")
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [image, setImage] = useState("")
  const [imageData, setImageData] = useState("")
  const [imageUrl, setImageUrl] = useState("")
  const [dateErr, setDateErr] = useState("")
  const [type, setType] = useState("web")
  const [status, setStatus] = useState(false)
  const [submitDisable, setSubmitDisable] = useState(false)

  const [titleErr, setTitleErr] = useState("")
  const [descriptionErr, setDescriptionErr] = useState("")
  const [imageErr, setImageErr] = useState("")
  const [language, setLanguage] = useState("en")

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(e.target.checked);
    if (name === "date") {
      setDate(value)
      if (value === "") {
        setDateErr("This field is required")
        return false
      }
      setDateErr("")
    }
    if (name === "type") {
      setType(value)

    }
    if (name === "status") {
      setStatus(e.target.checked)

    }
    if (name === "description") {
      setDescription(value)
      if (value === "") {
        setDescriptionErr("This field is required")
        return false
      }
      setDescriptionErr("")
    }
    if (name === "lang") {
      setLanguage(value)
    }
  }

  const closeModal = () => {
    console.log("close");
    setImageData("")
    setImageUrl("")
    setType("web")
    setDescription("")
    setDateErr("")
    setTitleErr("")
    setDescriptionErr("")
    setImageErr("")
  }

  const handleImage = (e) => {
    let { value } = e.target;

    const icon = e.target.files[0];

    if (value !== "" || value !== undefined) {
      setImage(e.target.files[0]);
      setImageUrl(URL.createObjectURL(e.target.files[0]));
      setImageErr("");
    } else {
      setImage(e.target.files[0]);
      setImageUrl("");
      setImageErr("This field is required");
    }
    if (!icon.name.match(/\.(jpg|jpeg|png)$/)) {
      setImageErr("Select valid image format");
      return false;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault()
    if (description === "") {
      setDescriptionErr("This field is required")
      return false
    }
    if (image === "") {
      setImageErr("This field is required")
      return false
    }
    console.log(image);
    let formdata = new FormData;
    formdata.append("header", description)
    formdata.append("type", type)
    formdata.append("status", status)
    formdata.append("image", image)
    formdata.append("lang", language)
    setSubmitDisable(true)
    axios.post(baseUrl + "/api/add_pop_up", formdata).then((res) => {
      if (res.data.status) {
        props.setRefresh(!props.refresh)
        $('#add-user-modal').modal('hide');
        // setTitle("")
        setImageData("")
        setDescription("")
        setImage("")
        setImageUrl("")
        setSubmitDisable(false)
      }
    }).catch((err) => {
      setSubmitDisable(false)

    })
  }
  return (
    <div>
      <div className="modal fade" id="add-user-modal">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Add Pop Up</h4>
              <button type="button" className="close" data-dismiss="modal">&times;</button>
            </div>
            <div className="modal-body">

              <form id="update-user" onSubmit={handleSubmit}>
                <div className='mb-3'>
                  <label className='form-label'>Language:</label>
                  <select onChange={handleChange} value={language} name="lang" className="form-select w-100 form-control">
                    <option value="en">English</option>
                    <option value="ko">Korean</option>
                    <option value="rus">Russian</option>
                    <option value="per">Persian</option>
                    <option value="ar">Arabic</option>
                  </select>
                </div>
                <div className='mb-3'>
                  <label className='form-label'>Title:</label>
                  <textarea name="description" value={description} onChange={handleChange} className='form-control' />
                  <span style={{ color: "red" }}>{descriptionErr}</span>
                </div>
                <div className='mb-3'>
                  <label className='form-label'>Type:</label>
                  <select onChange={handleChange} value={type} name="type" className="form-select w-100 form-control">
                    <option value="web">Web</option>
                    <option value="mobile">Mobile</option>
                  </select>

                </div>
                {/* <label className='form-label'>Show:</label>
                      <input type="checkbox" name="status"  onChange={handleChange}  className='form-control'/> */}
                <div className='mb-3'>
                  <img style={{ width: "100px" }} src={imageUrl} className="img-fluid" alt="" />
                  <label className='form-label'>Image:</label>
                  <input type="file" name="image" onChange={handleImage} className='form-control' accept="image/png, image/jpeg, image/jpg" value={imageData} />
                  <span style={{ color: "red" }}>{imageErr}</span>
                </div>

              </form>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={closeModal}>Close</button>
              <button
                form="update-user"
                type="submit"
                disabled={submitDisable}
                className="btn btn-primary">
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddPopupModal
