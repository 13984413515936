import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addBOT } from "../../../actions/botActions";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';

import 'react-toastify/dist/ReactToastify.css';

class BOTAddModal extends React.Component {
    constructor() {
        super();
        this.state = {
            user_id: "",
            pair_id: "",
            order_type: "",
            errors: {},
            pairList:[],
            userList:[],
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.pairList) {
            this.setState({
               pairList: nextProps.pairList
            })
        }
        if (nextProps.userList) {
            this.setState({
               userList: nextProps.userList
            })
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
       /*  if (nextProps.botdata !== undefined
            && nextProps.botdata.pair !== undefined
            && nextProps.botdata.pair.data !== undefined
            && nextProps.botdata.pair.data.message !== undefined) {
            
            this.setState({
                errors:{
               
                },
                box1: "",
                box2:"",
                message:""
            });
        } */
    }

    onChange = e => {
        this.setState({errors:{}});
        this.setState({ [e.target.name]: e.target.value });
    };

    onBOTAdd = e => {
        e.preventDefault();
        const newBOT = {
            pair_id: this.state.pair_id,
            user_id: this.state.user_id,
            order_type: this.state.order_type
        };
        this.props.addBOT(newBOT, this.props.history);
    };
    showHtml = () => {
        const html = []
            this.state.pairList.map(function (value, i) {
                html.push(
                        <>  
                        <option value={value.id}>{value.name1} / {value.name2}</option>
                        </>
                    );
                
        })
        return html;
    }

    showUserHtml = () => {
        const userhtml = []
            this.state.userList.map(function (value, i) {
                userhtml.push(
                    <>  
                        <option value={value.id}>{value.email}</option>
                    </>
                );
                
            })
        return userhtml;
    }

    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="add-user-modal" data-reset="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Add BOT</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onBOTAdd} id="add-user">
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="box1">Pair</label>
                                        </div>
                                        <div className="col-md-9">
                                        <select  className="form-control" id="pair_id" name="pair_id" value= {this.state.pair_id}  error={errors.pair_id} onChange={this.onChange}>
                                            <option>Please select pair</option>
                                            {this.showHtml()}
                                            </select>
                                          <span className="text-danger">{errors.pair_id}</span>
                                          <span className="text-danger">{errors.message}</span>
                                        </div>
                                    </div>
                               
                                    {/* <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="box2">User</label>
                                        </div>
                                        <div className="col-md-9">
                                        <select  className="form-control" id="user_id" name="user_id" value={this.state.user_id}  error={errors.user_id} onChange={this.onChange}>
                                            <option>Please select user</option>
                                            {this.showUserHtml()}
                                            </select>
                                            <span className="text-danger">{errors.user_id}</span>
                                        </div>
                                    </div> */}

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="box2">Order Type</label>
                                        </div>
                                        <div className="col-md-9">
                                            
                                            <input type="radio" checked={this.state.order_type == 'buy'} name="order_type" value="buy" onChange={this.onChange}/>Buy 
                                            <input type="radio" checked={this.state.order_type == 'sell'} name="order_type" value="sell" onChange={this.onChange}/>Sell 
                                            <span className="text-danger">{errors.order_type}</span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button
                                    form="add-user"
                                    type="submit"
                                    className="btn btn-primary">
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

BOTAddModal.propTypes = {
    addBOT: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    botdata: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    botdata: state.botdata,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { addBOT }
)(withRouter(BOTAddModal));
