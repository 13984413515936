const isEmpty = require("is-empty");
const initialState = {
    isAuthenticated: false,
    withdrawal: {},
    loading: false
};
export default function(state = initialState, action) {
    switch (action.type) {
       
        case 'CURRENCY_WITHDRAWAL':
        return {
                isAuthenticated: !isEmpty(action.payload),
                withdrawal: action.payload
        };
        default:
            return state;
    }
}
