import axios from "axios";
import {
    GET_ERRORS,
    LIQUIDATIONVALUE_ADD,
    LIQUIDATIONVALUE_UPDATE
} from "./types";
import * as myConstList from "./baseUrl"

const baseUrl = myConstList.baseUrl;

export const addLiquidationvalue = (LiquidationvalueData, history) => dispatch => {
    axios
        .post(baseUrl + "/api/liquidation-value-add", LiquidationvalueData)
        .then(res =>
            dispatch({
                type: LIQUIDATIONVALUE_ADD,
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    );
};


export const updateLiquidationvalue = (LiquidationvalueData) => dispatch => {
    axios
        .post(baseUrl + "/api/liquidation-value-update", LiquidationvalueData)
        .then(res =>
            dispatch({
                type: LIQUIDATIONVALUE_UPDATE,
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    );
};
