import axios from "axios";
import {
    GET_ERRORS,
    CURRENCY_WITHDRAWAL
} from "./types";
import * as myConstList from "./baseUrl"

const baseUrl = myConstList.baseUrl;

export const currencyWithdrawalAction = (pairData) => dispatch => {
    axios
        .post(baseUrl + "/api/currency-withdrawal-admin", pairData)
        .then(res =>
            dispatch({
                type: "CURRENCY_WITHDRAWAL",
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    );
};
