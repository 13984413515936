import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateLiquidationvalue } from "../../actions/LiquidationvalueActions";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import axios from "axios";
import $ from 'jquery';

import 'react-toastify/dist/ReactToastify.css';

class LiquidationvalueUpdateModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: this.props.record.id,
            liquidation: this.props.record.liquidation,
            currency_id: this.props.record.currency_id,
            currencyList:[],
            errors: {},
            loan_value:0,
            margin_call:0,
        };

        
    }

    componentWillReceiveProps(nextProps) {
       
        if (nextProps.record) {
            this.setState({
                id: nextProps.record.id,
                liquidation: nextProps.record.liquidation,
                currency_id: nextProps.record.currency_id,
                
            })
        }
        if (nextProps.currencyList) {
            this.setState({
                currencyList: nextProps.currencyList
            })
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
    }

    
    onChange = e => {
        if (e.target.id === 'currency_id') {
            this.getLiquidationLoanValue(e.target.value);
            this.getLiquidationMarginValue(e.target.value);
        }
        if (e.target.id === 'liquidation-update-name') {
            if(e.target.value > this.state.loan_value && e.target.value < this.state.margin_call){
                this.setState({liquidation: e.target.value, errors:{liquidation: ""} });
            }else{
                this.setState({liquidation: e.target.value, errors:{liquidation: "Please enter currect value"} });
            }
        }
       
    };

    
    onLiquidationvalueUpdate = e => {
        e.preventDefault();
        const newLiquidationvalue = {
            id: this.state.id,
            liquidation: this.state.liquidation,
            currency_id: this.state.currency_id
   
        };
        this.props.updateLiquidationvalue(newLiquidationvalue);
    };
    showHtml = () => {
       const html = []
        this.state.currencyList.map(function (value, i) {
             html.push(
                    <>  
                        <option value={value.id}>{value.short_name}</option>
                    </>
                );
            
    })

    return html;
    }
    
    render() {
        
        
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="update-user-modal">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Update Liquidation Value</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onLiquidationvalueUpdate} id="update-user">
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.id}
                                        id="user-update-id"
                                        type="text"
                                        className="d-none"/>
                                               <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="currency_id">Currency Name</label>
                                        </div>
                                        <div className="col-md-9">
                                        <select className={classnames("form-control", {
                                                invalid: errors.currency_id
                                            })} id="currency_id" value={this.state.currency_id} error={errors.currency_id} onChange={this.onChange} aria-label="Default select example">
                                                <option selected>Select Currency</option>
                                                 {this.showHtml()}
                                            </select>
                                           
                                            <span className="text-danger">{errors.currency_id}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="value">Liquidation %</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.liquidation}
                                                id="liquidation-update-name"
                                                type="number"
                                                error={errors.liquidation}
                                                className={classnames("form-control", {
                                                    invalid: errors.liquidation
                                                })}/>
                                                <p>Please enter the value between <b>Loan value :</b> {this.state.loan_value} and <b>Margin call :</b> {this.state.margin_call}</p>
                                            <span className="text-danger">{errors.liquidation}</span>
                                        </div>
                                    </div>
                                
                               
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button
                                    form="update-user"
                                    type="submit"
                                    className="btn btn-primary">
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

LiquidationvalueUpdateModal.propTypes = {
    updateLiquidationvalue: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { updateLiquidationvalue }
)(withRouter(LiquidationvalueUpdateModal));
