import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import axios from "axios";
import {Link} from "react-router-dom";
import {faLuggageCart, faPlus} from "@fortawesome/free-solid-svg-icons";
import AddContest from "../partials/AddContest";
import ContestUpdateModal from "../partials/ContestUpdateModal";
import { toast, ToastContainer} from "react-toastify";
import * as myConstList from "../../actions/baseUrl";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
const baseUrl = myConstList.baseUrl

class Contest extends Component {

    constructor(props) {
        super(props);

        this.columns = [
            {
                key: "_id",
                text: "Id",
                className: "id",
                align: "left",
                sortable: true,
            },
             {
                key: "contestLogo",
                text: "Contest Logo",
                className: "contestLogo",
                align: "left",
                sortable: true
            },
            {
                key: "contestName",
                text: "Contest Name",
                className: "contestName",
                align: "left",
                sortable: true,
            }, 
            {
                key: "contestStatus",
                text: "Contest Status",
                className: "contestStatus",
                align: "left",
                sortable: true,
            },   {
                key: "startDate",
                text: "Start Date",
                className: "startDate",
                align: "left",
                sortable: true
            },
            {
                key: "startTime",
                text: "Start Time",
                className: "startTime",
                align: "left",
                sortable: true
            },
              {
                key: "endDate",
                text: "End Date",
                className: "endDate",
                align: "left",
                sortable: true
            },  
            {
                key: "endTime",
                text: "End Time",
                className: "endTime",
                align: "left",
                sortable: true
            },  
            {
                key: "entryFee",
                text: "Entry Fee",
                className: "entryFee",
                align: "left",
                sortable: true
            },


            {
                key: "virtualFund",
                text: "Virtual Fund",
                className: "virtualFund",
                align: "left",
                sortable: true
            },
            {
                key: "action",
                text: "Action",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                        <Fragment>
                            <Link to={"/edit-contest/"+ record.id}  className="btn btn-primary btn-sm"><i className="fa fa-edit"></i></Link>
                            <button
                                className="btn btn-danger btn-sm"
                                onClick={() => this.deleteRecordMgs(record)}>
                                <i className="fa fa-trash"></i>
                            </button>
                        </Fragment>
                    );
                }
            }
        ];

        this.config = {
            page_size: 10,
            length_menu: [ 10, 20, 50 ],
            filename: "Users",
            no_data_text: 'No user found!',
            button: {
                excel: true,
                print: true,
                csv: true
            },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };
        this.state = {
            records: []
        };
        this.state = {
            currentRecord: {
                id: '',
                contestLogo: '',
                contestName: '',
                startTime: '',
                endTime: '',
                entryFee: '',
                virtualFund: ''
            }
        };
    this.getData = this.getData.bind(this);
    }
    componentWillReceiveProps(nextProps) {   
    }   
    onChange = e => {};
    getData() {
        axios
            .post(baseUrl + "/api/contest-data")
            .then(res => {
                this.setState({ records: res.data})
            })
            .catch()
    }
    editRecord(record) {
        this.setState({ currentRecord: record});
    }
    deleteRecordMgs(record) {
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Really want to delete this crypto currency?',
            buttons: [
              {
                label: 'Yes',
                onClick: () => this.deleteRecord(record)
              },
              {
                label: 'No'
              
              }
            ]
          });
 

    }
    deleteRecord(record) {
        axios
            .post(baseUrl + "/api/contest-delete", {_id: record._id})
            .then(res => {
                if (res.status === 200) {
                   toast(res.data.message, {
                       position: toast.POSITION.TOP_CENTER,
                   })
                }
            })
            .catch();
        this.getData();
    }
    componentDidMount() {
       
        this.getData()
    };
    componentDidUpdate() {
     
        this.getData()
      };

   

   

    pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }

    render() {
        return (
            <div>
                <Navbar/>
                <div className="d-flex" id="wrapper">
                    <Sidebar/>
                    <ContestUpdateModal record={this.state.currentRecord}/>
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <button className="btn btn-link mt-3" id="menu-toggle"><FontAwesomeIcon icon={faList}/></button>
                            <Link to={"/add-contest"} className="btn btn-outline-primary float-right mt-3 mr-2">Add</Link>
                            <h4 className="mt-2 text-primary">Contest List</h4>
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                onPageChange={this.pageChange.bind(this)}
                            />
                        </div>
                    </div>
                    <ToastContainer/>
                </div>
            </div>
        );
    }

}

Contest.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});

export default connect(
    mapStateToProps
)(Contest);
