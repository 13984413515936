import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { verifyStatus } from "../../../actions/userActions";
import RejectButtonFi from "./RejectButtonFi";
import { withRouter } from "react-router-dom";
import axios from "axios";
import $ from "jquery";
import { Link } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import { Alert } from "bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { Modal, Button } from "react-bootstrap";
import { baseUrl } from "../../../actions/baseUrl"
import VerifyDocument2 from "./VerifyDocument2";
class VerifyDocument1 extends React.Component {
  constructor(props) {
    super(props);
    const { user } = this.props.auth;
    this.state = {
      records: [],
      id: "",
      i_image: "",
      identity_status: "",
      reject: "",
      errors: {},
      type: 21,
      id: this.props.record._id,
      i_image: this.props.record.i_image,
      identity_status: this.props.record.identity_status,
      reject: this.props.record.d1_rejectResion,
      records: this.props.record,
    };
  }

  componentWillReceiveProps(nextProps) {
    console.log("nextPropwssss===>>>", nextProps)
    if (nextProps.record) {
      console.log("props",nextProps.record)
      this.setState({
        id: nextProps.record._id,
        i_image: nextProps.record.i_image,
        identity_status: nextProps.record.identity_status,
        reject: nextProps.record.d1_rejectResion,

      });
    }
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
    if (nextProps.userrecords) {
      this.setState({
        tstatus: nextProps.userrecords.status,
      });
    }
  }
  getData() {
    const { user } = this.props.auth;
    axios
      .post(baseUrl + "/api/rejectReason-By-Id-Data", { _id: this.props.match.params.id })
      .then(res => {
        this.setState({
          d1: res.data.d1_rejectResion,
          d2: res.data.d2_rejectResion,
          d3: res.data.d3_rejectResion,
          d4: res.data.d4_rejectResion
        }, () => {
          this.setState({ isKycModalOpen: true })
        });
        console.log("res.datares.data", res.data)

      })
      .catch()
  }
  getNodesToRemoveFromElement = (stringContent) => {
    var div = document.createElement("div");
    div.innerHTML = stringContent;
    var text = div.textContent || div.innerText || "";
    return text;
  };

  handleCommentChange = (event, editor) => {
    var data = editor.getData();
    if (data) {
      this.setState({ description: data });
    }
    this.setState({
      showResults: this.state.name === null ? false : true,
    });
  };

  onUpdate = (e) => {
    e.preventDefault();

    const newUser = {
      _id: this.props.match.params.id,
      type: "d1",
      type2: "d2"
    };
    this.props.verifyStatus(newUser);
  };
  onChange = (e) => {
    if (e.target.id === "reject") {
      this.setState({ reject: e.target.value });
    }
  };
  openModel = () => {
    this.setState({ isKycModalOpen: true })
  }
  closeModel = () => {
    this.setState({ isKycModalOpen: false })
  }

  render() {
    const { errors } = this.state;
    const { user } = this.state;
    return (
      <>
        {console.log('statusatatsu', this.state)}

        {this.state.identity_status !== undefined && (
          <>
            <div className="col-md-6">
              <form noValidate onSubmit={(e) => this.onUpdate(e)} id="update-user">
                <p>
                  <b>Identity Document (Front Image):</b>{" "}
                  {this.state.identity_status == 1 ? (
                    "pending"
                  ) : this.state.identity_status == 2 ? (
                    "Verified"
                  ) : (
                    <button
                      onClick={(e) => {
                        e.preventDefault()
                        this.getData()

                      }}
                      // to={
                      //   "/reject-reason/" + this.props.match.params.id + "/" + 22
                      // }
                      type="button"
                      className="btn btn_man"
                    >
                      Rejected <i className="fa fa-info-circle"></i>
                    </button>
                  )}
                </p>
                <a href={baseUrl + "/static/upload/" + this.state.i_image} target="_blank">
                  <img
                    src={baseUrl + "/static/upload/" + this.state.i_image}
                    className="img-thumbnail"
                    alt="Cinque Terre"
                  ></img>
                </a>
                {this.props.match.params.type == 2 && (
                  <div
                    style={{
                      display:
                        this.state.identity_status !== "2" &&
                          this.state.identity_status !== "3"
                          ? "block"
                          : "none",
                    }}
                  >
                    {/* <button type="button"  >Verify</button> */}
                  </div>
                )}
              </form>
              <Modal
                show={this.state.isKycModalOpen}
                onHide={this.closeKycModal}
                backdrop="static"
                keyboard={false}
              >
                <Modal.Body>
                  <div className="kyc_popop tc">
                    <h2 ref={(subtitle) => (subtitle = subtitle)}>
                      <h3 className="man_hadding">Reject Reason</h3>
                      {this.state.type == 21 &&
                        <p className="rejectReason">{this.state.d1}</p>
                      }
                      {this.state.type == 22 &&
                        <p className="rejectReason">{this.state.d2}</p>
                      }
                      {this.state.type == 23 &&
                        <p className="rejectReason">{this.state.d3}</p>
                      }
                      {this.state.type == 24 &&
                        <p className="rejectReason">{this.state.d4}</p>
                      }
                    </h2>
                    <div>
                      <button className="w100px" onClick={this.closeModel}>
                        Okay!
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
            {/* {this.props.match.params.type == 2 &&
              this.state.identity_status !== "2" &&
              this.state.identity_status !== "3" && (
                <RejectButtonFi
                  record={this.state.records}
                  docStatus={this.state.identity_status}
                  rejectStatus={this.state.reject}
                />
              )} */}
            <hr />
            <div className="col-md-6">
              <VerifyDocument2 record={this.state.records} />
            </div>
          </>
        )}
      </>
    );
  }
}

VerifyDocument1.propTypes = {
  verifyStatus: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { verifyStatus })(
  withRouter(VerifyDocument1)
);
