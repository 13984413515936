import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import $ from "jquery";
import Moment from "moment"
import "react-toastify/dist/ReactToastify.css";

class UserSellFundModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "this.props.user.id",
      userData: [],
      dataStatus: false,
      portfolioUsdt: 0,
      portfolioInr: 0,
    };
    console.log("this.props", this.props);
  }

  componentWillReceiveProps(nextProps) {
    console.log("nextProps.currentRecord", nextProps.sellOrderDataById);
    if (nextProps.sellOrderDataById) {
      this.setState({
        userData: nextProps.sellOrderDataById.data,
        dataStatus: nextProps.sellOrderDataById.status,
      });
    }
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
  }

  tableDataHtml = () => {
    if (this.state.dataStatus == true) {
      const html = [];
      const classThis = this;
      this.state.userData.map((value, i) => {
        html.push(
          <tr>
            <td>{i + 1}</td>
            <td>
              {value.firstCoin} / {value.secondCoin}
            </td>
            <td>
              {value.sell_price}
            </td>
            <td>
              {value.total_sell_spend_amount}
            </td>
            <td>
              {value.sell_get_amount}
            </td>
            <td>
              {value.sell_fees}
            </td>
            <td>
              {value.status}
            </td>
            <td>
              {Moment(value.created).format('lll')}
            </td>
          </tr>
        );
      });
      return html;
    }
  };

  render() {
    const { errors } = this.state;
    return (
      <div>
        <div
          className="modal fade"
          id="update-usersell-modal"
          data-reset="true" data-backdrop="static"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">User Sell Order History</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <table className="table w-100">
                  <thead>
                    <tr>
                      <th>Sr No.</th>
                      <th>Pair</th>
                      <th>Buy Price</th>
                      <th>Amount(QTY)</th>
                      <th>Total(AMT)</th>
                      <th>Buy Fees</th>
                      <th>Status</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>{this.tableDataHtml()}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

UserSellFundModal.propTypes = {
  userFundHistory: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps)(withRouter(UserSellFundModal));
