import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { rejectStatus } from "../../../actions/userActions";
import { withRouter } from "react-router-dom";
import axios from "axios";
import $ from 'jquery';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from "react-toastify";
class RejectButtonBi extends React.Component {

    constructor(props) {
        super(props);
        const { user } = this.props.auth;
        this.state = {
            id: "",
            docStatus: '',
            reject: '',
            errors: {},
        };

    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.record) {
            this.setState({
                id: nextProps.record._id,
                docStatus: nextProps.docStatus,
                reject: nextProps.record.d1_rejectResion,
            });

        }

        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.userrecords) {
            this.setState({
                tstatus: nextProps.userrecords.status
            });
        }
        if (nextProps.userdata !== undefined
            && nextProps.userdata.newuser !== undefined
            && nextProps.userdata.newuser.data !== undefined
            && nextProps.userdata.newuser.data.message !== undefined
            && nextProps.userdata.newuser.data.success) {
                $('#exampleModalBi').modal('hide');
        }

      
    }

    onChange = e => {
        if (e.target.id === 'reject') {
            this.setState({ reject: e.target.value });
        }
    };

    onRejectUpdate = e => {
        e.preventDefault();
        const newUser = {
            _id: this.props.match.params.id,
            stype: "d2",
            reject: this.state.reject
        };
        this.props.rejectStatus(newUser);
    };
    render() {

        const { errors } = this.state;
        const { user } = this.state;

        return (
            <>
                <div style={{ display: (this.state.docStatus !=="2" ? 'block' : 'none') }}>
                    <button type="submit" className="btn btn-primary r_btn" data-toggle="modal" data-target="#exampleModalBi">Reject</button>
                </div>
                <form noValidate onSubmit={this.onRejectUpdate} id="update-user">
                    <div className="modal fade" id="exampleModalBi" data-reset="true" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Write Reason</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <input onChange={this.onChange}
                                            value={this.state.reject}
                                            id="reject"
                                            type="text"
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                    {this.state.reject !== '' && this.state.docStatus !== "3" &&
                                        <button type="submit" className="btn btn-primary">Save</button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </>
        )
    }
}

RejectButtonBi.propTypes = {
    rejectStatus: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    userdata: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    userdata: state.userdata,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { rejectStatus }
)(withRouter(RejectButtonBi));
