import axios from "axios";
import {
    GET_ERRORS,
    CREDENTIAL_ADD,
    CREDENTIAL_UPDATE
} from "./types";
import {baseUrl} from "./baseUrl"

export const addCredential = (credentialData, history) => dispatch => {
    axios
        .post(baseUrl+"/api/exchange-credential-add", credentialData)
        .then(res =>
            dispatch({
                type: "CREDENTIAL_ADD",
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    );
};

export const updateCredential = (credentialData) => dispatch => {
    axios
        .post(baseUrl+"/api/exchange-credential-update", credentialData)
        .then(res =>
            dispatch({
                type: "CREDENTIAL_UPDATE",
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    );
};
