import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { currencyWithdrawalAction } from "../../actions/currencyWithdrawalAction";
import { withRouter } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import $ from 'jquery';

import 'react-toastify/dist/ReactToastify.css';

class AdminWithdrawalModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            total_balance:"",
            from_wallet_address:"",
            isBtnDisabled:false
        };
    }

    componentWillReceiveProps(nextProps) {

       
        if (nextProps.record) {
            this.setState({
                id: nextProps.record.id,
                private_key:nextProps.record.private_key,
                // total_balance:nextProps.record.eth_total_balance,
                from_wallet_address:nextProps.record.eth_wallet_address
            })
        }
        if (nextProps.liveBalance) {
            console.log('next',nextProps.liveBalance)
            this.setState({
                // id: nextProps.record.id,
                total_balance:nextProps.liveBalance
            })
        }
        if (nextProps.currency) {
            this.setState({
                // id: nextProps.record.id,
                currency:nextProps.currency
            })
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.pairdata !== undefined
            && nextProps.pairdata.pair !== undefined
            && nextProps.pairdata.pair.data !== undefined
            && nextProps.pairdata.pair.data.message !== undefined) {
            this.setState({errors:{}
            });
        }
    }

    onChange = e => {
        this.setState({errors:{}});
        if (e.target.id === 'user-update-box1') {
            this.setState({ box1: e.target.value });
        }
        if (e.target.id === 'user-update-box2') {
            this.setState({ box2: e.target.value });
        }
        if (e.target.id === 'withdrawal_amount') {
            const inputValue = e.target.value;
            
            // Regular expression for 8 digits and 8 decimal places
            const regex = /^\d{0,8}(\.\d{0,8})?$/;
        
            // Check if the input matches the regex pattern
            if (regex.test(inputValue)) {
                this.setState({ withdrawal_amount: inputValue,amount:inputValue });
            } else {
                // Display an error message or handle the invalid input in some way
                return
            }
        }
        
        
    };

    onCurrencyWithdrawal = e => {

        e.preventDefault();
        if(this.state.withdrawal_amount > 0){

            const newPair = {
                _id: this.state.id,
                total_balance: this.state.total_balance,
                withdrawal_amount:this.state.withdrawal_amount,
                token_name:this.state.currency,
                private_key:this.state.private_key
            };
            this.setState({isBtnDisabled:true})
            setTimeout(() => {
                this.setState({isBtnDisabled:false})
            }, 15000);
            this.props.currencyWithdrawalAction(newPair);
        }else{
            toast.error("Please enter valid amount")
        }
    };
   
    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="update-user-modal">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Withdrawal</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onCurrencyWithdrawal} id="update-user">
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.id}
                                        id="user-update-id"
                                        type="text"
                                        className="d-none"/>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="box2">Total Balance</label>
                                        </div>
                                        <div className="col-md-9"> {this.state.total_balance}
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="box2">From Wallet Address</label>
                                        </div>
                                        <div className="col-md-9"> {this.state.from_wallet_address}
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="box2">Amount</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.amount}
                                                id="withdrawal_amount"
                                                name="withdrawal_amount"
                                                type="number"
                                                required
                                                error={errors.amount}
                                                className={classnames("form-control", {
                                                    invalid: errors.amount
                                                })}/>
                                            <span className="text-danger">{errors.amount}</span>
                                        </div>
                                    </div>
                               
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button
                                    form="update-user"
                                    type="submit"
                                    className="btn btn-primary" disabled={this.state.isBtnDisabled}>
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                    <ToastContainer />
                </div>
            </div>
        )
    }
}

AdminWithdrawalModal.propTypes = {
    currencyWithdrawalAction: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    pairdata: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    pairdata: state.pairdata,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { currencyWithdrawalAction }
)(withRouter(AdminWithdrawalModal));
