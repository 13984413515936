import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import { updateUser } from "../../actions/userActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Link, withRouter } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import $ from 'jquery';
import classnames from "classnames";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as myConstList from "../../actions/baseUrl";

const baseUrl = myConstList.baseUrl
class ProfileUpdate extends Component {

    constructor(props) {
        super(props);
        this.state = {

            firstName: '',
            lastName: '',
            dob: '',
            email: '',
            mobile: '',
            line1: '',
            line2: '',
            city: "",
            r_country: "",
            mobile_no: "",
            BankName: "",
            AccNum: "",
            Ifsc: "",
            BranchName: "",
            AccHolder: "",
            errors: {}
        };


    }

    getData() {
        axios
            .post(baseUrl + "/api/User-By-Id-Data", { _id: this.props.match.params.id })
            .then(res => {
                console.log("res.data");
                console.log(res.data);
                this.setState({

                    firstName: res.data.firstName,
                    lastName: res.data.lastName,
                    email: res.data.email,
                    dob: res.data.dob,
                    line1: res.data.line1,
                    line2: res.data.line2,
                    state:res.data.state,
                    zipcode: res.data.zipcode,
                    city: res.data.city,
                    r_country: res.data.country,
                    mobile_no: res.data.mobile_no,
                    BankName: res.data.BankName,
                    AccNum: res.data.AccNum,
                    Ifsc: res.data.Ifsc,
                    BranchName: res.data.BranchName,
                    AccHolder: res.data.AccHolder,

                })
            })
            .catch()
    }
    componentDidMount() {
        if (this.props.auth.user.manageUsers !== undefined && this.props.auth.user.manageUsers !== 1) {
            this.props.history.push("/dashboard");
        }
        this.getData();
    };

    componentWillReceiveProps(nextProps) {
        if (this.props.auth.user.manageUsers !== undefined && this.props.auth.user.manageUsers !== 1) {
            this.props.history.push("/dashboard");
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.userdata !== undefined
            && nextProps.userdata.newuser !== undefined
            && nextProps.userdata.newuser.data !== undefined
            && nextProps.userdata.newuser.data.message !== undefined
            && nextProps.userdata.newuser.data.success) {
            this.props.history.push("/users");
        }

        this.getData()
    }



    onChange = e => {
        this.setState({ errors: {} });
        this.setState({ [e.target.name]: e.target.value });
    };

    onRoleUpdate = e => {
        e.preventDefault();

        const newdata = {
            id: parseInt(this.props.match.params.id),
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            dob: this.state.dob,
            line1: this.state.line1,
            line2: this.state.line2,
            zipcode: this.state.zipcode,
            city: this.state.city,
            r_country: this.state.r_country,
            mobile_no: this.state.mobile_no,
            BankName: this.state.BankName,
            AccNum: this.state.AccNum,
            Ifsc: this.state.Ifsc,
            BranchName: this.state.BranchName,
            AccHolder: this.state.AccHolder,
        }
        this.props.updateUser(newdata);
    }
    render() {

        const { errors } = this.state;
        return (
            <div>
                <Navbar />
                <div className="d-flex" id="wrapper">
                    <Sidebar />
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <button className="btn btn-link mt-3" id="menu-toggle"><FontAwesomeIcon icon={faList} /></button>

                            <h3 className="mt-2 man_hadding mb-3">Update User Details</h3>
                            <div className="in_page row" >
                                <br />

                                <div className="col-md-12">
                                    <div className=" padding_d pu_box">
                                        <div className="d-flex justify-content-between">
                                            <h4>KYC User Details</h4>
                                            <button> <Link to="/users">Back</Link></button>
                                        </div>
                                        <hr />
                                        <form noValidate onSubmit={this.onRoleUpdate} id="add-user">
                                            <div className="white_box">
                                                <div className="tab-content ">
                                                    <div className="tab-pane active show" id="details">
                                                        <div className="row mt-2">
                                                            {/* <div className="col-md-1">
                                                                <label htmlFor="firstName">First name</label>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <input
                                                                    onChange={this.onChange}
                                                                    value={this.state.firstName}

                                                                    error={errors.firstName}
                                                                    name="firstName"
                                                                    type="text"
                                                                    className={classnames("form-control", {
                                                                        invalid: errors.firstName
                                                                    })}
                                                                />
                                                            </div>


                                                            <div className="col-md-1">
                                                                <label htmlFor="lastName">Last name</label>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <input
                                                                    onChange={this.onChange}
                                                                    value={this.state.lastName}

                                                                    error={errors.lastName}
                                                                    name="lastName"
                                                                    type="text"
                                                                    className={classnames("form-control", {
                                                                        invalid: errors.lastName
                                                                    })}
                                                                />
                                                                <span className="text-danger">{errors.lastName}</span>
                                                            </div> */}

                                                            <div className="col-md-1">
                                                                <label htmlFor="email">Email</label>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <input
                                                                    onChange={this.onChange}
                                                                    value={this.state.email}
                                                                    error={errors.email}
                                                                    name="email"
                                                                    type="text"
                                                                    readOnly={true}
                                                                    className={classnames("form-control", {
                                                                        invalid: errors.email
                                                                    })}
                                                                />
                                                                <span className="text-danger">{errors.email}</span>
                                                            </div>
                                                            <div className="col-md-2">
                                                                <label htmlFor="mobile_no">Mobile Number</label>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <input
                                                                    onChange={this.onChange}
                                                                    value={this.state.mobile_no}
                                                                    error={errors.mobile_no}
                                                                    name="mobile_no"
                                                                    type="text"
                                                                    className={classnames("form-control", {
                                                                        invalid: errors.mobile_no
                                                                    })}
                                                                />
                                                                <span className="text-danger">{errors.mobile_no}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-2">
                                                           

                                                            {/* <div className="col-md-1">
                                                                <label htmlFor="dob">Date of Birth</label>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <input
                                                                    onChange={this.onChange}
                                                                    value={this.state.dob}
                                                                    error={errors.dob}
                                                                    name="dob"
                                                                    type="date"
                                                                    className={classnames("form-control", {
                                                                        invalid: errors.dob
                                                                    })}
                                                                />
                                                                <span className="text-danger">{errors.dob}</span>

                                                            </div> */}

                                                        </div>

                                                        {/* <h4 className="mt-3">Residential Address</h4>
                                                        <hr />
                                                        <div className="row mt-2">
                                                            
                                                            <div className="col-md-6">
                                                            <label htmlFor="line1">Line 1</label>
                                                                <input
                                                                    onChange={this.onChange}
                                                                    value={this.state.line1}
                                                                    error={errors.line1}
                                                                    name="line1"
                                                                    type="text"
                                                                    className={classnames("form-control", {
                                                                        invalid: errors.line1
                                                                    })}
                                                                />
                                                                <span className="text-danger">{errors.line1}</span>

                                                            </div>
                                                      
                                                            
                                                             <div className="col-md-6">
                                                            <label htmlFor="line2">Line 2</label>
                                                                <input
                                                                    onChange={this.onChange}
                                                                    value={this.state.line2}

                                                                    error={errors.line2}
                                                                    name="line2"
                                                                    type="text"
                                                                    className={classnames("form-control", {
                                                                        invalid: errors.line2
                                                                    })}
                                                                />
                                                                <span className="text-danger">{errors.line2}</span>
                                                            </div> 
                                                        </div> */}
                                                        {/* <div className="row mt-2">
                                                           
                                                            <div className="col-md-3">
                                                            <label htmlFor="line2">State</label>
                                                                <input
                                                                    onChange={this.onChange}
                                                                    value={this.state.state}

                                                                    error={errors.state}
                                                                    name="state"
                                                                    type="text"
                                                                    className={classnames("form-control", {
                                                                        invalid: errors.line2
                                                                    })}
                                                                />
                                                                <span className="text-danger">{errors.state}</span>
                                                            </div>
                                                            <div className="col-md-3">
                                                            <label htmlFor="city">City</label>
                                                                <input
                                                                    onChange={this.onChange}
                                                                    value={this.state.city}
                                                                    error={errors.city}
                                                                    name="city"
                                                                    type="text"
                                                                    className={classnames("form-control", {
                                                                        invalid: errors.city
                                                                    })}
                                                                />
                                                                <span className="text-danger">{errors.city}</span>
                                                            </div>
                                                            <div className="col-md-3">
                                                            <label htmlFor="zipcode">Zip code</label>
                                                                <input
                                                                    onChange={this.onChange}
                                                                    value={this.state.zipcode}
                                                                    error={errors.zipcode}
                                                                    minLength="6"
                                                                    maxLength="6"
                                                                    name="zipcode"
                                                                    type="number"
                                                                    className={classnames("form-control", {
                                                                        invalid: errors.zipcode
                                                                    })}
                                                                />
                                                                <span className="text-danger">{errors.zipcode}</span>
                                                            </div>
                                                            <div className="col-md-3">
                                                                 <label htmlFor="r_country">Country</label>
                                                                <input
                                                                    onChange={this.onChange}
                                                                    value={this.state.r_country}
                                                                    error={errors.r_country}
                                                                    name="r_country"
                                                                    type="text"
                                                                    className={classnames("form-control", {
                                                                        invalid: errors.r_country
                                                                    })}
                                                                />
                                                                <span className="text-danger">{errors.r_country}</span>
                                                            </div>



                                                        </div> */}
                                                        {/* <h4 className="mt-3">Bank Details</h4>
                                                        <hr /> */}
                                                        <div className="row mt-2">
                                                            {/* <div className="col-md-1">
                                                                <label htmlFor="BankName">Bank Name</label>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <input
                                                                    onChange={this.onChange}
                                                                    value={this.state.BankName}
                                                                    error={errors.BankName}
                                                                    name="BankName"
                                                                    type="text"
                                                                    className={classnames("form-control", {
                                                                        invalid: errors.BankName
                                                                    })}
                                                                />
                                                                <span className="text-danger">{errors.BankName}</span>
                                                            </div> */}
                                                            {/* <div className="col-md-1">
                                                                <label htmlFor="BranchName">Branch Name</label>

                                                            </div>
                                                            <div className="col-md-3">
                                                                <input
                                                                    onChange={this.onChange}
                                                                    value={this.state.BranchName}
                                                                    error={errors.BranchName}
                                                                    name="BranchName"
                                                                    type="text"
                                                                    className={classnames("form-control", {
                                                                        invalid: errors.BranchName
                                                                    })}
                                                                />
                                                                <span className="text-danger">{errors.BranchName}</span>
                                                            </div> */}



                                                            {/* <div className="col-md-1">
                                                                <label htmlFor="AccNum">Account Number</label>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <input
                                                                    onChange={this.onChange}
                                                                    value={this.state.AccNum}
                                                                    error={errors.AccNum}
                                                                    name="AccNum"
                                                                    type="text"
                                                                    className={classnames("form-control", {
                                                                        invalid: errors.AccNum
                                                                    })}
                                                                />
                                                                <span className="text-danger">{errors.AccNum}</span>
                                                            </div>
                                                            <div className="col-md-2">
                                                                <label htmlFor="AccHolder">Account Holder Name</label>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <input
                                                                    onChange={this.onChange}
                                                                    value={this.state.AccHolder}
                                                                    error={errors.mobile}
                                                                    name="AccHolder"
                                                                    type="text"
                                                                    className={classnames("form-control", {
                                                                        invalid: errors.AccHolder
                                                                    })}
                                                                />
                                                                <span className="text-danger">{errors.AccHolder}</span>
                                                            </div> */}
                                                        </div>
                                                        {/* <div className="row mt-2">
                                                            

                                                            <div className="col-md-2">
                                                                <label htmlFor="AccHolder">IFSC Code</label>

                                                            </div>
                                                            <div className="col-md-4">
                                                                <input
                                                                    onChange={this.onChange}
                                                                    value={this.state.Ifsc}
                                                                    error={errors.Ifsc}
                                                                    name="Ifsc"
                                                                    type="text"
                                                                    className={classnames("form-control", {
                                                                        invalid: errors.Ifsc
                                                                    })}
                                                                />
                                                                <span className="text-danger">{errors.Ifsc}</span>
                                                            </div>

                                                        </div> */}
                                                        {/* <hr />
                                                        <button type="submit" className="btn btn-primary">Save</button>
                                                        <hr /> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ToastContainer />
                </div>
            </div>
        );
    }

}

ProfileUpdate.propTypes = {
    updateUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    userdata: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    userdata: state.userdata,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { updateUser }
)(withRouter(ProfileUpdate));
