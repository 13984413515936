import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addLiquidationvalue } from "../../actions/LiquidationvalueActions";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';

class LiquidationvalueAddModal extends React.Component {

    constructor() {
        super();
        this.state = {
            liquidation: "",
            currency_id: "",
            currencyList:[],
            loan_value:0,
            margin_call:0,
            errors: {},
        };
       // this.getLiquidationLoanValue = this.getLiquidationLoanValue.bind(this);
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.currencyList) {
            this.setState({
                currencyList: nextProps.currencyList
            })
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
    }
    onChange = e => {
        if (e.target.id === 'currency_id') {
            this.setState({ currency_id: e.target.value });
            this.getLiquidationLoanValue(e.target.value);
            this.getLiquidationMarginValue(e.target.value);
        }
        if (e.target.id === 'liquidationName') {
            if(e.target.value > this.state.loan_value && e.target.value < this.state.margin_call){
                this.setState({liquidation: e.target.value, errors:{liquidation: ""} });
            }else{
                this.setState({liquidation: e.target.value, errors:{liquidation: "Please enter currect value"} });
            }
            
        }
       
    };

    getLiquidationLoanValue(c_id) {
        axios
        .post("/api/liquidation-loan-value-data", {c_id: c_id})
        .then(res => {
            if(res.data[0]){
                this.setState({ loan_value: res.data[0].value})
            }
            
        })
        .catch()
    }

    getLiquidationMarginValue(c_id) {
        axios
        .post("/api/liquidation-margin-value-data", {c_id: c_id})
        .then(res => {
            if(res.data[0]){
                this.setState({ margin_call: res.data[0].margin})
            }
        })
        .catch()
    }

    onLiquidationvalueAdd = e => {
        e.preventDefault();
        const newLiquidationvalue = {
            liquidation: this.state.liquidation,
            currency_id: this.state.currency_id
        };
        this.props.addLiquidationvalue(newLiquidationvalue, this.props.history);
    };
    showHtml = () => {
        const html = []
         this.state.currencyList.map(function (value, i) {
              html.push(
                     <>  
                         <option value={value.id}>{value.short_name}</option>
                     </>
                 );
             
     })
     return html;
     }
    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="add-user-modal" data-reset="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Add Liquidation Value</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onLiquidationvalueAdd} id="add-user">
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="currency_id">Currency Name</label>
                                        </div>
                                        <div className="col-md-9">
                                        <select className={classnames("form-control", {
                                                invalid: errors.currency_id
                                                })} id="currency_id" error={errors.currency_id} onChange={this.onChange} aria-label="Default select example">
                                                <option selected>Select Currency</option>
                                                 {this.showHtml()}
                                            </select>
                                           <span className="text-danger">{errors.currency_id}</span>
                                        </div>
                                    </div>
                                         <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Liquidation %</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.liquidation}
                                                id="liquidationName"
                                                type="number"
                                                error={errors.liquidation}
                                                className={classnames("form-control", {
                                                    invalid: errors.liquidation
                                                })}/>
                                                <p>Please enter the value between <b>Loan value :</b> {this.state.loan_value} and <b>Margin call :</b> {this.state.margin_call}</p>
                                            <span className="text-danger">{errors.liquidation}</span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button
                                    form="add-user"
                                    type="submit"
                                    className="btn btn-primary">
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

LiquidationvalueAddModal.propTypes = {
    addLiquidationvalue: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { addLiquidationvalue }
)(withRouter(LiquidationvalueAddModal));
