import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from "@mkikets/react-datatable";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import RefferalManagerPlan from "../partials/RefferalManagerPlan";
import RefferalManagerShare from "../partials/RefferalManagerShare";
import { toast, ToastContainer } from "react-toastify";
import Moment from "moment";
import $ from "jquery";
import { baseUrl } from "../../actions/baseUrl";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import ReferralUpdateModal from "../partials/ReferralUpdateModal";
class RefralManager extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        key: "id",
        text: "#",
        className: "id",
        align: "left",
        sortable: true,
        cell: (row, index) => index + 1,
      },
      {
        key: "short_name",
        text: "Short Name",
        className: "short_name",
        align: "left",
        sortable: true,
      },
      {
        key: "join_bonus",
        text: "Join Bonus",
        className: "join_bonus",
        align: "left",
        sortable: true,
      },
      {
        key: "refer_bonus",
        text: "Referral Bonus",
        className: "refer_bonus",
        align: "left",
        sortable: true,
       
      },
     
      {
        key: "modified",
        text: "Date",
        className: "date",
        align: "left",
        sortable: true,
        cell: (record) => {
          return <Fragment>{Moment(record.modified).format("lll")}</Fragment>;
        },
      },
      {
        key: "action",
        text: "Action",
        className: "action",
        width: 100,
        align: "left",
        sortable: false,
        cell: (record) => {
          return (
            <Fragment>
              <button
                data-toggle="modal"
                data-target="#update-user-modal"
                className="btn btn-primary btn-sm"
                onClick={() => this.editRecord(record)}
                style={{ marginRight: "5px" }}
              >
                <i className="fa fa-edit"></i>
              </button>
              {/* <button
                                className="btn btn-danger btn-sm"
                                onClick={() => this.deleteRecordMgs(record)}>
                                <i className="fa fa-trash"></i>
                            </button> */}
            </Fragment>
          );
        },
      },
    ];

    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      filename: "Crypto Currency List",
      no_data_text: "No record found!",
      button: {
        print: false,
        csv: false,
      },
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: false,
      show_filter: false,
      show_pagination: false,
      show_info: false,
    };

    this.state = {
      records: [],
      cryptoCheckedBoxes: [],
      cryptoCurrency:[],
      currentRecord: {
        id: "",
        currency: "",
        join_bonus: "",
        refer_bonus: "",
      },
    };

    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    if (
      this.props.auth.user.cryptoCurrency !== undefined &&
      this.props.auth.user.cryptoCurrency !== 1
    ) {
      this.props.history.push("/dashboard");
    }
    this.getData();
    this.getCryptoData()
  }

  componentWillReceiveProps(nextProps) {
    console.log("nextprops====",nextProps)
    if (
      this.props.auth.user.cryptoCurrency !== undefined &&
      this.props.auth.user.cryptoCurrency !== 1
    ) {
      this.props.history.push("/dashboard");
    }
    if (
      nextProps.auth !== undefined &&
      nextProps.auth.user !== undefined &&
      nextProps.auth.user.data !== undefined &&
      nextProps.auth.user.data.message !== undefined
    ) {
      $("#update-user-modal").modal("hide");
      // $("#add-user-modal").modal("hide");
      toast(nextProps.auth.user.data.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      this.setState({ errors: { currencyImage: "" } });
    }
    this.getData();
    setTimeout(() => {
      window.reload()
      this.getCryptoData()
    }, 2000);
  }

  getData = () => {
    axios
      .get(baseUrl + "/api/get-referral")
      .then((res) => {
        if (res.data.status) {
          console.log("res", res.data);
          console.log("res.data.data",res.data.data)
          this.setState({records:res.data.data});
        }
      })
      .catch();
  };
  getCryptoData = () => {
       
    axios
        .get(baseUrl+"/api/crypto-data")
        .then(res => {
            if (res.status === 200) {
                console.log("res",res.data)
            this.setState({ cryptoCurrency: res.data})
            }
        })
        .catch()
}
  editRecord = (record) => {
    this.setState({
      currentRecord: {
        id: record.id,
        currency: record.currency,
        join_bonus: record.join_bonus,
        refer_bonus: record.refer_bonus,
    

      },
    });
  };

  toggleCheckbox = (e, item) => {
    if (e.target.checked) {
      let arr = this.state.cryptoCheckedBoxes;
      arr.push(item.id);
      this.setState = { cryptoCheckedBoxes: arr };
    } else {
      let items = this.state.cryptoCheckedBoxes.splice(
        this.state.cryptoCheckedBoxes.indexOf(item.id),
        1
      );
      this.setState = {
        cryptoCheckedBoxes: items,
      };
    }
    //console.log(this.state.cryptoCheckedBoxes);
  };

  // deleteRecordMgs(record) {
  //   confirmAlert({
  //     title: "Confirm to submit",
  //     message: "Really want to delete this crypto currency?",
  //     buttons: [
  //       {
  //         label: "Yes",
  //         onClick: () => this.deleteRecord(record),
  //       },
  //       {
  //         label: "No",
  //       },
  //     ],
  //   });
  // }
  // deleteRecord(record) {
  //   axios
  //     .post(baseUrl + "/api/crypto-delete", { id: record.id })
  //     .then((res) => {
  //       if (res.status === 200) {
  //         toast(res.data.message, {
  //           position: toast.POSITION.TOP_CENTER,
  //         });
  //       }
  //     })
  //     .catch();
  //   this.getData();
  // }

  pageChange(pageData) {
    console.log("OnPageChange", pageData);
  }

  render() {
    return (
      <div>
        <Navbar />
        <div className="d-flex" id="wrapper">
          <Sidebar />
         <ReferralUpdateModal currentRecord={this.state.currentRecord} cryptoCurrency={this.state.cryptoCurrency} />
            {/*<RefferalManagerShare /> */}
          <div id="page-content-wrapper">
            <div className="container-fluid">
              <button className="btn btn-link mb-3" id="menu-toggle">
                <FontAwesomeIcon icon={faList} />
              </button>
              {/* <button className="btn btn-outline-primary float-right mb-3 mr-2" data-toggle="modal" data-target="#add-user-modal"><FontAwesomeIcon icon={faPlus}/>Add</button> */}

              <div className="section-heading">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-md-4 col-12">
                      <div className="heading-outer">
                        <h4 className="mt-0 man_hadding mb-0">Reffer</h4>
                      </div>
                    </div>
                 
                    {/* <div className="col-md-8 col-12">
                      <div className="plan-fee-btn d-flex flex-wrap justify-content-between text-right">
                        <a
                          href="javascript:void(0)"
                          className="btn btn-primary"
                          data-toggle="modal"
                          data-target="#refer-modal-plan"
                        >
                          Plan
                        </a>
                        <a
                          className="btn btn-primary"
                          data-toggle="modal"
                          data-target="#refer-modal-share"
                        >
                          Sharefee
                        </a>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="reffer-main-outer">
                <div className="collection-outer">
                  <div className="container">
                    {/* <div className="row">
                      <div className="col-md-6 col-12">
                        <div className="collection-inner">
                          <h5>5000 INR</h5>
                          <h6>Total Buy Collection</h6>
                        </div>
                      </div>
                      <div className="col-md-6 col-12">
                        <div className="collection-inner">
                          <h5>5000 INR</h5>
                          <h6>Total Sell Collection</h6>
                        </div>
                      </div>
                      <div className="col-md-4 col-12">
                        <div className="collection-inner">
                          <h5>5000</h5>
                          <h6>Admin Collection</h6>
                        </div>
                      </div>
                      <div className="col-md-4 col-12">
                        <div className="collection-inner">
                          <h5>2500</h5>
                          <h6>Sub Admin Collection</h6>
                        </div>
                      </div>
                      <div className="col-md-4 col-12">
                        <div className="collection-inner">
                          <h5>2500</h5>
                          <h6>Upline</h6>
                        </div>
                      </div>
                    </div> */}
                    <div className="white_box">

                       <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                onPageChange={this.pageChange.bind(this)}
                            />
                    </div>
                  </div>
                </div>
                {/* <div className="plans-outer">
                  <div className="container">
                    <div className="heading-outer">
                      <h5>Plans</h5>
                    </div>
                    <div className="plan-main-inner">
                      <div className="row">
                        <div className="col-md-3 col-sm-6 col-12">
                          <div className="plan-inner">
                            <h5>
                              {this.state.monthly == null
                                ? 0
                                : this.state.monthly}
                            </h5>
                            <h6>Monthly</h6>
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-6 col-12">
                          <div className="plan-inner">
                            <h5>
                              {this.state.quartly == null
                                ? 0
                                : this.state.quartly}
                            </h5>
                            <h6>Quaterly</h6>
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-6 col-12">
                          <div className="plan-inner">
                            <h5>
                              {this.state.half_yearly == null
                                ? 0
                                : this.state.half_yearly}
                            </h5>
                            <h6>Half Yearly</h6>
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-6 col-12">
                          <div className="plan-inner">
                            <h5>
                              {this.state.yearly == null
                                ? 0
                                : this.state.yearly}
                            </h5>
                            <h6>Yearly</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </div>
    );
  }
}

RefralManager.propTypes = {
  auth: PropTypes.object.isRequired,
  cryptodata: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  cryptodata: state.cryptodata,
  records: state.records,
});
export default connect(mapStateToProps)(RefralManager);
