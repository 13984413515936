import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { newCommission } from '../../actions/DepositFeeAction';
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';

import 'react-toastify/dist/ReactToastify.css';
import { Alert } from 'bootstrap';

class DepositFeeAddModal extends React.Component {

    constructor() {
        super();
        this.state = {
            currency: "",
            buyCommission:"",
            sellCommission:'',
            errors: {},
            cryptoList:[]
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.cryptoList) {
            this.setState({
               cryptoList: nextProps.cryptoList
            })
        }
        if (nextProps.errors) {
        
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.comdata !== undefined
            && nextProps.comdata.commission !== undefined
            && nextProps.comdata.commission.data !== undefined
            && nextProps.comdata.commission.data.message !== undefined
            && nextProps.comdata.commission.data.success) {
                this.setState({  errors: {
                  
                }, currency: "",
                buyCommission:"",
                sellCommission:'', })
        }
      
    }

    onChange = e => {
        this.setState({  errors: {
            currency: "",
            buyCommission:"",
            maxDeposit:'',
        } })
        this.setState({ [e.target.id]: e.target.value });
    };

    onCountryAdd = e => {
        e.preventDefault();
       
        const newCountry = {
            currency: this.state.currency,
            buyCommission: this.state.buyCommission,
            maxDeposit: this.state.maxDeposit,
            minDeposit: this.state.minDeposit
          
        };
        this.props.newCommission(newCountry, this.props.history);
    };
    showHtml = () => {
        const html = []
         this.state.cryptoList.map(function (value, i) {
              html.push(
                     <>  
                         <option value={value.id}>{value.name}</option>
                     </>
                 );
             
     })
     return html;
     }
    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="add-user-modal" data-reset="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Add Deposit Fee</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onCountryAdd} id="add-user">
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Coin Name</label>
                                        </div>
                                        <div className="col-md-9">
                                        <select  className="form-control" id="currency" value= {this.state.currency}  error={errors.currency} onChange={this.onChange}>
                                            <option>Please select crypto currency</option>
                                            {this.showHtml()}
                                            </select>
                                            <span className="text-danger">{errors.currency}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="buyCommission">Deposit Fees</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.buyCommission}
                                                id="buyCommission"
                                                type="text"
                                                error={errors.buyCommission}
                                                className={classnames("form-control", {
                                                    invalid: errors.buyCommission
                                                })}/>
                                            <span className="text-danger">{errors.buyCommission}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="maxDeposit">Max Deposit</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.maxDeposit}
                                                id="maxDeposit"
                                                type="text"
                                                error={errors.maxDeposit}
                                                className={classnames("form-control", {
                                                    invalid: errors.maxDeposit
                                                })}/>
                                            <span className="text-danger">{errors.maxDeposit}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="minDeposit">Min Deposit</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.minDeposit}
                                                id="minDeposit"
                                                type="text"
                                                error={errors.minDeposit}
                                                className={classnames("form-control", {
                                                    invalid: errors.minDeposit
                                                })}/>
                                            <span className="text-danger">{errors.minDeposit}</span>
                                        </div>
                                    </div>
                                
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button
                                    form="add-user"
                                    type="submit"
                                    className="btn btn-primary">
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

DepositFeeAddModal.propTypes = {
    newCommissions: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    comdata: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    comdata: state.comdata,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { newCommission }
)(withRouter(DepositFeeAddModal));
