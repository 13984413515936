import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { replyForAdmin } from "../../actions/updateHelp";
import { withRouter } from "react-router-dom";
import axios from "axios";
import $ from "jquery";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import Moment from "moment";
import { Link } from "react-router-dom";
import { baseUrl } from "../pages/baseUrl";
class HelpQueryModal extends React.Component {
  constructor(props) {
    super(props);
    const { user } = this.props.auth;
    this.state = {
      id: this.props.id,
      description: "",
      message: "",
      tstatus: "",
      issueImage: "",
      userUploadImage:"",
      username: user.name,
      replyBy: this.props.userrecords.replyTo,
      showResults: false,
      showTeaxtArea: true,
      errors: {},
    };
  }

  componentDidMount() {
    console.log("propspropsorposp", this.props);
    if (
      this.props.match.params.status == "2" ||
      this.props.match.params.status == 2
    ) {
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.userrecords) {
      this.setState({
        tstatus: nextProps.userrecords.status,
      });
    }
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
    if (
      nextProps.helpdata !== undefined &&
      nextProps.helpdata.help !== undefined &&
      nextProps.helpdata.help.data !== undefined &&
      nextProps.helpdata.help.data.message !== undefined
    ) {
      this.setState({
        description: "",
        issueImage: "",
        userUploadImage:"",
        errors: {},
      });
    }
  }

  getNodesToRemoveFromElement = (stringContent) => {
    var div = document.createElement("div");
    div.innerHTML = stringContent;
    var text = div.textContent || div.innerText || "";
    return text;
  };
  showChatHtml = () => {
    const html = [];
    let dateArray = [];
    const classThis = this;
    var date = "";
    var time = "";
    this.props.record.map((value, i) => {
      let data = Moment(value.createdAt)
        .format("DD-MM-YYYY HH:mm:ss")
        .split(" ")[0];
      date = Moment(date).format("DD-MM-YYYY HH:mm:ss").split(" ");
      console.log(
        "thispropsuserrecordsdate",
        Moment(this.props.userrecords.date)
          .format("DD-MM-YYYY HH:mm:ss")
          .split(" ")[0] == data
      );
      if (value.replyBy == 2) {
        html.push(
          <>
            <div class="incoming">
              <div class="bubble">
                <div style={{ textAlign: "center" }}>
                  {Moment(this.props.userrecords.date)
                    .format("DD-MM-YYYY HH:mm:ss")
                    .split(" ")[0] == data ? (
                    <></>
                  ) : (
                    !dateArray.includes(data) && data
                  )}
                </div>
                <div className="msg-outer user-msg">
                  <div style={{ color: "black" }}>
                    {classThis.getNodesToRemoveFromElement(value.query)}
                  </div>
                  <div className="time-right msg-time">
                    {
                      Moment(value.createdAt)
                        .format("DD-MM-YYYY HH:mm:ss")
                        .split(" ")[1]
                    }
                  </div>
                </div>
                {value.queryImage !== undefined &&
                  value.queryImage !== null && (
                    <div>
                      <a
                        href={baseUrl + "/static/helpIssue/" + value.queryImage}
                        target="_blank"
                      >
                        <img
                          src={
                            baseUrl + "/static/helpIssue/" + value.queryImage
                          }
                        />
                      </a>
                      {/* <div className="msg-time">
                    {Moment(value.createdAt).format("DD-MM-YYYY HH:mm:ss").split(" ")[1]}
                  </div> */}
                    </div>
                  )}
                <div></div>
              </div>
            </div>
          </>
        );
      }
      if (value.replyBy == 1) {
        html.push(
          <>
            <div class="outgoing">
              <div class="bubble lower">
                <div style={{ textAlign: "center" }}>
                  {Moment(this.props.userrecords.date)
                    .format("DD-MM-YYYY HH:mm:ss")
                    .split(" ")[0] == data ? (
                    <></>
                  ) : (
                    !dateArray.includes(data) && data
                  )}
                </div>
                <div className="msg-outer">
                  <div style={{ color: "black" }}>
                    {classThis.getNodesToRemoveFromElement(value.query)}
                  </div>
                  {!value.queryImage && (
                    <div className="time-right msg-time">
                      {
                        Moment(value.createdAt)
                          .format("DD-MM-YYYY HH:mm:ss")
                          .split(" ")[1]
                      }
                    </div>
                  )}
                </div>
                {value.queryImage !== undefined && value.queryImage !== "" && (
                  <div className="img-msg-main">
                    <div className="msg-outer">
                      <a
                        href={baseUrl + "/static/helpIssue/" + value.queryImage}
                        target="_blank"
                      >
                        <img
                          src={
                            baseUrl + "/static/helpIssue/" + value.queryImage
                          }
                          alt=""
                        />
                      </a>
                      <div className="msg-time">
                        {
                          Moment(value.createdAt)
                            .format("DD-MM-YYYY HH:mm:ss")
                            .split(" ")[1]
                        }
                      </div>
                    </div>
                  </div>
                )}
                <div>
                  {/* <span className="time-right">
                    {Moment(value.createdAt).format("DD-MM-YYYY HH:mm:ss").split(" ")[1]}
                  </span> */}
                </div>
              </div>
            </div>
          </>
        );
      }
      !dateArray.includes(data) && dateArray.push(data);
    });
    return html;
  };
  onChange = (e) => {
    this.setState({
      message: "",
      errors: {},
    });
    if (e.target.id === "tstatus") {
      this.setState({ tstatus: e.target.value });
    }
    if (e.target.name === "issueImage") {
      var issueImage = e.target.files[0];
      var fileSize = e.target.files[0].size/1024;
      this.setState({ userUploadImage: URL.createObjectURL(issueImage) });
      console.log("fileSize",fileSize>1024)
      if (fileSize > 1024) {
        this.setState({
          invalidImage: "Maximum 1 MB Image allowed.",
          firstSubmitIsDisabled: true,
          userUploadImage: false,
        });
        return false;
      }

      if (!issueImage.name.match(/\.(jpg|jpeg|png)$/)) {
        this.setState({
          invalidImage: "Please select valid Front image jpg,jpeg,png,",
          firstSubmitIsDisabled: true,
          userUploadImage: false,
        });
        return false;
      }

      this.setState({
        issueImage: issueImage,
        invalidImage:""
      });
    }
  };
  handleCommentChange = (event, editor) => {
    var data = event.target.value;
    if (data) {
      this.setState({ description: data });
    }
    this.setState({
      showResults: this.state.name === null ? false : true,
    });
  };
  onUserUpdate = (e) => {
    e.preventDefault();

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    console.log("this.props.auth",this.props.auth)
    const { user } = this.props.auth;
    const formData = new FormData();
    let id = atob(this.props.match.params.id);
    formData.append("_id", id);
    formData.append("user_id", user.loginUserId);
    formData.append("description", this.state.description);
    formData.append("issueImage", this.state.issueImage);
    formData.append("tstatus", this.state.tstatus);
    this.props.replyForAdmin(formData, config);
  };
  onStatusUpdate = (e) => {
    e.preventDefault();
    let id = atob(this.props.match.params.id);
    axios
      .post(baseUrl + "/api/suportStatus-update", {
        _id: id,
        tstatus: this.state.tstatus,
      })
      .then((res) => {
        this.props.reCall();
        if (res.status === 200) {
          this.setState({ message: res.data.message });

        }
      });
  };

  render() {
    const { errors } = this.state;
    const { user } = this.props.auth;
    return (
      <div class="">
        <div class="">
          <div>
            <form noValidate onSubmit={this.onStatusUpdate} id="update-user">
              <input type="reset" defaultValue="Reset" className="d-none" />
              <div class="row">
                <div class="col-md-6">
                  <div class="pt-2 ">
                    <b>Ticket Id:</b> {this.props.userrecords.id}
                  </div>
                  <span class="text-success">{this.state.message}</span>
                </div>
                {this.props.userrecords.status == "0" && (
                  <div class="col-md-6 ">
                    <div class="status_box">
                      <div class="pt-2 ">
                        <b>Status:</b>
                      </div>
                      <div class="ml-md-3 mr-md-3">
                        {" "}
                        <select
                          class="browser-default custom-select"
                          id="tstatus"
                          onChange={this.onChange}
                          value={this.state.tstatus}
                        >
                          <option value="0">Pending</option>
                          <option value="1">Resolve</option>
                        </select>
                      </div>
                      <div class="">
                        <button
                          class="btn btn-primary send-status-btn"
                          type="submit"
                        >
                          Send
                        </button>
                      </div>
                    </div>
                  </div>
                )}{" "}
              </div>
            </form>
            <hr />
            <h5 className="op05 mb-3 ">
              Subject: {this.props.userrecords.subject}
            </h5>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="w_chating_box w_chating_box_left">
                <div class="scrool_chating">
                  <div class="incoming">
                    <div class="bubble">
                      <div style={{ textAlign: "center" }}>
                        {
                          Moment(this.props.userrecords.date)
                            .format("DD-MM-YYYY HH:mm:ss")
                            .split(" ")[0]
                        }
                      </div>
                      <div>
                        {this.props.userrecords.issueImage != undefined &&
                          this.props.userrecords.issueImage != "" &&
                          this.props.userrecords.issueImage != null && (
                            <>
                              <a
                                href={
                                  baseUrl +
                                  "/static/helpIssue/" +
                                  this.props.userrecords.issueImage
                                }
                                target="_blank"
                              >
                                <img
                                  src={
                                    baseUrl +
                                    "/static/helpIssue/" +
                                    this.props.userrecords.issueImage
                                  }
                                  alt=""
                                />
                              </a>
                            </>
                          )}
                        <br />
                        <div className="msg-outer user-msg">
                          <div style={{ color: "black" }}>
                            {this.getNodesToRemoveFromElement(
                              this.props.userrecords.description
                            )}
                          </div>
                          <div className="time-right msg-time">
                            {
                              Moment(this.props.userrecords.date)
                                .format("DD-MM-YYYY HH:mm:ss")
                                .split(" ")[1]
                            }
                          </div>
                        </div>
                      </div>
                      <div></div>
                    </div>
                  </div>
                  {this.showChatHtml()}
                </div>
              </div>
            </div>
            {this.props.userrecords.status == "0" && (
              <div class="col-md-6">
                <div class="w_chating_box">
                  <h5 class="mb-3">Send Your Message Form Here:</h5>
                  <form
                    noValidate
                    onSubmit={this.onUserUpdate}
                    id="update-user"
                  >
                    <div class="">
                      <textarea
                        // editor={ClassicEditor}
                        value={this.state.description}
                        onChange={this.handleCommentChange}
                        placeholder="Enter Description"
                        className="form-control form_control_textarea"
                        data={this.state.description}
                      ></textarea>
                    </div>

                    <div className="form-group">
                      <div className="add_fileinput form-control">
                        Add File
                        <input
                          className="profile_input form-control"
                          type="file"
                          name="issueImage"
                          onChange={this.onChange}
                          accept=".jpg, .jpeg, .png"
                        />
                       
                      </div>
                     
                      <span className="text-danger">
                        {this.state.invalidImage}
                      </span>
                      <img src={this.state.userUploadImage} alt="" className="chat-img"/>
                    </div>
                    <div className="tr">
                      <button
                        class="btn btn-primary"
                        type="submit"
                        disabled={this.state.description ? false : true}
                      >
                        Send
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

HelpQueryModal.propTypes = {
  replyForAdmin: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  helpdata: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  helpdata: state.helpdata,
  errors: state.errors,
});

export default connect(mapStateToProps, { replyForAdmin })(
  withRouter(HelpQueryModal)
);
