import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@mkikets/react-datatable';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import WithdrawalINRReject from "../partials/WithdrawalINRReject";
import WithdrawalINRApprove from "../partials/WithdrawalINRApprove";
import { toast, ToastContainer } from "react-toastify";
import { baseUrl } from "../../actions/baseUrl";
import Moment from "moment";
class Withdrawal extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        // key: "_id",
        text: "Sr.no",
        className: "transaction_id",
        align: "left",
        sortable: true,
        cell: (row, index) => index + 1,
      },
      {
        key: "tx_id",
        text: "Transaction_id",
        className: "transaction_id",
        align: "left",
        sortable: true,
      },

      {
        key: "email",
        text: "Email",
        className: "email",
        align: "left",
        sortable: true,
      },
      {
        key: "mobile",
        text: "Mobile Number",
        className: "mobile_no",
        align: "left",
        sortable: true,
      },
      {
        key: "coin_amount",
        text: "Amount",
        className: "amount",
        align: "left",
        sortable: true,
      },
      {
        key: "withdrawalFees",
        text: "Withdrawal Fees",
        className: "amount",
        align: "left",
        sortable: true,
      },

      {
        key: "created",
        text: "Date",
        className: "date",
        align: "left",
        sortable: true,
      },
      {
        key: "firstName",
        text: "User Name",
        className: "firstName",
        align: "left",
        sortable: true,
      },
      {
        key: "BankName",
        text: "BankName",
        className: "BankName",
        align: "left",
        sortable: true,
      },
      {
        key: "BranchName",
        text: "BranchName",
        className: "BranchName",
        align: "left",
        sortable: true,
      },
      {
        key: "AccNum",
        text: "AccNum",
        className: "AccNum",
        align: "left",
        sortable: true,
      },
      {
        key: "AccHolder",
        text: "AccHolder",
        className: "AccHolder",
        align: "left",
        sortable: true,
      },
      {
        key: "Ifsc",
        text: "Ifsc",
        className: "Ifsc",
        align: "left",
        sortable: true,
      },
      {
        key: "action",
        text: "Action",
        className: "action",
        width: 100,
        align: "left",
        sortable: false,
        cell: (record) => {
          return (
            <Fragment>
              <p className="mb-0">
                {console.log(record.WithdrawlRequest)}

                {record.WithdrawlRequest == "0" ? (
                  <>
                    <button
                      data-toggle="modal"
                      data-target="#update-user-modal-approve"
                      className="btn btn-success btn-sm"
                      onClick={() => this.editRecord(record)}
                      style={{ marginRight: "5px" }}
                    >
                      <i className="fa fa-check"></i>
                    </button>
                    <button
                      data-toggle="modal"
                      data-target="#update-user-modal-reject"
                      className="btn btn-danger btn-sm"
                      onClick={() => this.editRecord(record)}
                      style={{ marginRight: "5px" }}
                    >
                      <i className="fa fa-close"></i>
                    </button>
                  </>
                ) : record.WithdrawlRequest == "1" ? (
                  <button
                    // data-toggle="modal"
                    // data-target="#update-user-modal-approve"
                    className="btn btn-success btn-sm action-btn"
                    onClick={() => this.editRecord(record)}
                    style={{ marginRight: "5px" }}
                  >
                    <span>Approved</span>
                  </button>
                ) : (
                  <>
                    <button
                      data-toggle="modal"
                      data-target="#update-user-modal-reject"
                      className="btn btn-danger btn-sm reject-btn action-btn"
                      onClick={() => this.editRecord(record)}
                      style={{ marginRight: "5px" }}
                    >
                      Rejected
                    </button>{" "}
                  </>
                )}
              </p>
            </Fragment>
          );
        },
      },
    ];

    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      filename: "Withdrawal INR",
      no_data_text: "No user found!",
      button: {
        print: true,
        csv: true,
      },
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: true,
      show_filter: true,
      show_pagination: true,
      show_info: true,
    };

    this.state = {
      records: [],
    };

    this.state = {
      currentRecord: {
        id: "",
        coin_amount: "",
        user_id: "",
        approve: "",
        reject: "",
      },
    };

    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  componentWillReceiveProps(nextProps) {
    this.getData();
  }

  getData() {
    axios
      .get(baseUrl + "/api/userwithdrawalHistory")
      .then((res) => {
        this.setState({ records: res.data });
      })
      .catch();
  }

//   onSelectChange(event) {
//     axios
//       .post(baseUrl + "/api/userFilterCoinwithdrawalHistory", {
//         WithdrawlRequest: event.target.value,
//       })
//       .then((res) => {
//         console.log("res.data :", res.data);
//         this.setState({ records: res.data });
//       })
//       .catch();
//   }

  editRecord(record) {
    this.setState({ currentRecord: record });
  }

  pageChange(pageData) {
    console.log("OnPageChange", pageData);
  }

  supportClick = (e) => {
    var html =[]

      this.state.records.map((value)=>{
          console.log("valuevaluevaluevalue",value)
          if(e.target.value == "Pending"){
              if(value.WithdrawlRequest == "4" ){
                  html.push(value)

              }
          }
          if(e.target.value == "Approved"){
              if(value.WithdrawlRequest == '1'){
                  html.push(value)

              }
          }
          if(e.target.value == "Reject"){
            if(value.WithdrawlRequest != '0' && value.WithdrawlRequest != '1' && value.WithdrawlRequest != '4'){
                html.push(value)

            }
        }
          if(e.target.value == "select"){
             
                  html.push(value)

            
          }

      })
      this.setState({FilterRecords:html})

  }


  showFilterList =() => {
    console.log("showqfilterlist")

   
    const html = []
   
        html.push(
<select className="form-control" id="exchange" name="supportFilter" onClick={this.supportClick}>
            <option value="select" id="">select</option>
            <option value="Pending">Pending</option>
            <option value="Reject">Reject</option>
            <option value="Approved">Approved</option>
            
            </select>
          );
    
    
        return <div className="support-select">{html}</div> 
  
    
}

  render() {
    return (
      <div>
        <Navbar />
        <div className="d-flex" id="wrapper">
          <Sidebar />
          <WithdrawalINRReject record={this.state.currentRecord} />
          <WithdrawalINRApprove record={this.state.currentRecord} />
          <div id="page-content-wrapper">
            <div className="container-fluid">
              <button className="btn btn-link mt-3" id="menu-toggle">
                <FontAwesomeIcon icon={faList} />
              </button>

              <h4 className="mt-2 man_hadding">Withdrawal INR Request List</h4>
              {this.showFilterList()}
              <ReactDatatable
                  config={this.config}
                  records={this.state.FilterRecords?this.state.FilterRecords:this.state.records}
                  columns={this.columns}
                  onPageChange={this.pageChange.bind(this)}
                  className="scrolling-table table table-bordered table-striped"
                />
              
            </div>
          </div>
          <ToastContainer />
        </div>
      </div>
    );
  }
}

Withdrawal.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  records: state.records,
});

export default connect(mapStateToProps)(Withdrawal);
